import React, { useEffect, useState } from "react";
import AdminSidebar from "./AdminSidebar";
import AdminTopBar from "../pages/SideMenu/AdminTopBar";
import axios from "axios";
import { uri } from "../../https";
import Pagination from "react-js-pagination";

const Purchases = () => {
  const [purchase_list, setPurchaseList] = useState([]);
  const [pagination, setPagination] = useState([]);
  const FetchPurchaseList = async (pageNumber) => {
    const url = `${uri}api/get_stripeorders_list?page=${pageNumber}`;
    // const url = `${uri}api/get_scheduled_posts`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        if (response.data.status == 200) {
          setPurchaseList(response.data.stripe_orders);
          // setScheduledPostList(response.data.scheduled_posts);
          // setPagination(response?.data?.stripe_orders);
        }
        if (response.data.status == 400) {
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    FetchPurchaseList();
  }, []);
  return (
    <>
      <AdminTopBar />
      <div className=" flex flex-row">
        <div className="  hidden md:block w-[0px]  md:w-[340px] h-screen">
          <AdminSidebar />
        </div>
        <div className=" w-full h-screen">
          <div class="relative overflow-x-auto mt-[50px] px-[20px]">
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-white uppercase bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] ">
                <tr>
                  <th scope="col" class="px-6 py-4">
                    Username
                  </th>
                  <th scope="col" class="px-6 py-4">
                    Email
                  </th>
                  <th scope="col" class="px-6 py-4">
                    Phone
                  </th>
                  <th scope="col" class="px-6 py-4">
                    Invoice Id
                  </th>
                  <th scope="col" class="px-6 py-4">
                    Order Id
                  </th>
                  <th scope="col" class="px-6 py-4">
                    Payment Type
                  </th>
                </tr>
              </thead>
              <tbody>
                {purchase_list.map((data, index) => {
                  return (
                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <th
                        scope="row"
                        class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {data?.name}
                      </th>
                      <td class="px-6 py-4 text-gray-900 font-medium">
                        {data?.email}
                      </td>
                      <td class="px-6 py-4 text-gray-900 font-medium">
                        {data?.mobileno}
                      </td>
                      <td class="px-6 py-4 text-gray-900 font-medium truncate">
                        {data?.invoiceid}
                      </td>
                      <td class="px-6 py-4 text-gray-900 font-medium truncate">
                        {data?.orderid}
                      </td>
                      <td class="px-6 py-4 text-gray-900 font-medium truncate">
                        {data?.payment_mode}
                      </td>
                    </tr>
                  );
                })}
                {/* <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    User One
                  </th>
                  <td class="px-6 py-4 text-gray-900 font-medium">
                    test@gmail.com
                  </td>
                  <td class="px-6 py-4 text-gray-900 font-medium">
                    +918888888888
                  </td>
                  <td class="px-6 py-4 text-gray-900 font-medium truncate">
                    Sp321
                  </td>
                  <td class="px-6 py-4 text-gray-900 font-medium truncate">
                    Sp321
                  </td>
                  <td class="px-6 py-4 text-gray-900 font-medium truncate">
                    xxx xxxxxxxxxxx xxxx xxxxx xxxxxxxxxx xxxx xxxxxx
                  </td>
                  <td class="px-6 py-4 text-gray-900 font-medium truncate">
                    560037
                  </td>
                </tr>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    User Two
                  </th>
                  <td class="px-6 py-4 text-gray-900 font-medium">
                    test@gmail.com
                  </td>
                  <td class="px-6 py-4 text-gray-900 font-medium">
                    +918888888888
                  </td>
                  <td class="px-6 py-4 text-gray-900 font-medium truncate">
                    Sp321
                  </td>
                  <td class="px-6 py-4 text-gray-900 font-medium truncate">
                    Sp321
                  </td>
                  <td class="px-6 py-4 text-gray-900 font-medium truncate">
                    xxx xxxxxxxxxxx xxxx xxxxx xxxxxxxxxx xxxx xxxxxx
                  </td>
                  <td class="px-6 py-4 text-gray-900 font-medium truncate">
                    560037
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </div>
        <div className=" flex flex-col justify-center  items-center mt-6 ">
          {/* <Pagination
            className="w-[400px]"
            activePage={pagination.current_page}
            totalItemsCount={pagination.total}
            itemsCountPerPage={pagination.per_page}
            onChange={(pageNumber) => FetchPurchaseList(pageNumber)}
          /> */}
        </div>
      </div>
    </>
  );
};

export default Purchases;

import React from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useDropzone } from "react-dropzone";

const EventsVideoModal = ({ setEventVideo }) => {
  const [categoryfile, setCategoryFile] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    // console.log(acceptedFiles)
    setCategoryFile(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );

    setEventVideo(acceptedFiles[0]);

    // Do something with the files
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "video/mp4": [],
    },
    onDrop,
  });

  const removeFile = (name) => {
    setCategoryFile((files) => files.filter((file) => file.name !== name));
  };

  const selected_images = categoryfile?.map((file) => (
    <div className=" flex">
      <div className=" h-[20px] w-[20px] flex justify-center items-center rounded-full bg-red-500 text-white ">
        <i
          onClick={() => removeFile(file.name)}
          class="fa-solid fa-xmark cursor-pointer"
        ></i>
      </div>
      <video src={file.preview} className=" w-[50px]  rounded-md " />
    </div>
  ));
  return (
    <>
      <div className=" m-3  relative flex justify-center  z-[10000] items-center  -mt-[120px] cursor-pointer h-[100px] w-[130px] border-2 border-dashed border-gray-300 rounded-md hover:border-[#3EC4E8] transition-all hover:transition-all">
        <div {...getRootProps()} className=" flex justify-center items-center">
          <input {...getInputProps()} />

          <div className=" flex flex-col justify-center items-center">
            <i className="fa-solid fa-image text-[24px] text-gray-600"></i>
            <p>MP4</p>
          </div>
        </div>
      </div>
      <div className=" w-full    rounded-md mt-[30px]">
        <div className="  m-3  flex flex-col">{selected_images}</div>
      </div>
    </>
  );
};

export default EventsVideoModal;

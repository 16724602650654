import React from "react";
import { Fragment } from "react";
import Day from "./Day";

const Month = ({ month }) => {
  return (
    <>
      <div className=" md:flex-1 grid  grid-cols-3  md:grid-cols-7 md:grid-rows-5 w-full md:pr-6   ">
        {month.map((row, index) => {
          return (
            <Fragment key={index}>
              {row.map((day, index) => {
                return <Day day={day} key={index} rowIdx={index} />;
              })}
            </Fragment>
          );
        })}
      </div>
    </>
  );
};

export default Month;

import React, { useContext, useState } from "react";
import GlobalContext from "../../../context/GlobalContext";

const EventSelectedModal = () => {
  const {
    setShowEventModal,
    setShowSelectedEventModal,
    daysSelected,
    dispatchCalEvent,
    selectedEvent,
  } = useContext(GlobalContext);
  const labelsClasses = ["blue"];
  const [title, setTitle] = useState(selectedEvent ? selectedEvent.title : "");
  // const [title, setTitle] = useState("");
  const [description, setDescription] = useState(
    selectedEvent ? selectedEvent.description : ""
  );
  // const [description, setDescription] = useState("");
  const [selectedLabel, setSelectedLabel] = useState(
    selectedEvent
      ? labelsClasses.find((lbl) => lbl === selectedEvent.label)
      : labelsClasses[0]
  );
  // const [selectedLabel, setSelectedLabel] = useState(labelsClasses[0]);

  console.log("event selected", selectedEvent);

  console.log("days", daysSelected);

  const handleSubmit = (e) => {
    e.preventDefault();
    const calendarEvent = {
      title,
      description,
      label: selectedLabel,
      day: daysSelected.valueOf(),
      id: selectedEvent ? selectedEvent.id : Date.now(),
    };
    if (selectedEvent) {
      dispatchCalEvent({ type: "update", payload: calendarEvent });
    } else {
      console.log("Calender event", calendarEvent);
      dispatchCalEvent({ type: "push", payload: calendarEvent });
    }

    setShowSelectedEventModal(false);
  };
  return (
    <div className=" h-screen w-full  absolute left-0 top-0  flex justify-center items-center">
      <form className=" bg-white rounded-md shadow-md  w-[400px]">
        <header className="bg-gray-100 px-4 py-2 flex justify-between items-center">
          <span className="material-icons-outlined text-gray-400">
            drag_handle
          </span>
          <div className=" gap-3">
            <button onClick={() => setShowSelectedEventModal(false)}>
              <span className="material-icons-outlined text-gray-400">
                close
              </span>
            </button>
            {selectedEvent && (
              <button
                onClick={() => {
                  dispatchCalEvent({ type: "delete", payload: selectedEvent });
                  setShowSelectedEventModal(false);
                }}
              >
                <span className="material-icons-outlined text-gray-400 cursor-pointer">
                  delete
                </span>
              </button>
            )}
          </div>
        </header>
        <div className=" m-5 p-5">
          <input
            className=" pt-3 border-0 text-gray-600 font-semibold pb-2 w-full border-b-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-blue-500"
            type="text"
            name="title"
            placeholder="Add title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className=" flex justify-end items-end m-2">
          <button
            onClick={handleSubmit}
            type="submit"
            className=" bg-blue-500 hover:bg-blue-600 px-6 py-2 rounded text-white"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default EventSelectedModal;

import React from "react";
import { useContext } from "react";
import GlobalContext from "../../../context/GlobalContext";
import dayjs from "dayjs";
import Sidebar from "./Sidebar";
import { Link } from "react-router-dom";
import { app_version } from "../../../https";

function CalenderHeader({ stored_location }) {
  const { monthIndex, setmonthIndex } = useContext(GlobalContext);

  const handleChangePrevMonth = () => {
    setmonthIndex(monthIndex - 1);
  };

  const handleChangeNextMonth = () => {
    setmonthIndex(monthIndex + 1);
  };
  const handleResetMonth = () => {
    setmonthIndex(
      monthIndex === dayjs().month()
        ? monthIndex + Math.random()
        : dayjs().month()
    );
  };

  return (
    <>
      <header className=" md:px-4 py-2 flex items-center  justify-center md:justify-between mb-3">
        <div className=" flex flex-row justify-center items-center md:justify-between gap-[50px] md:gap-[0px] md:items-center">
          <button
            onClick={handleResetMonth}
            className=" hidden md:block w-full md:w-[130px] py-3  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all hover:scale-90"
          >
            Today
          </button>
          <div className=" flex flex-row gap-[20px] md:gap-[0px]">
            <button onClick={handleChangePrevMonth}>
              <span className="">
                <i class="fa-solid fa-chevron-left  cursor-pointer text-gray-600 mx-2"></i>
              </span>
            </button>

            <h1 className=" text-[16px] md:text-[17px] ml-4  text-gray-500 font-semibold border-2 md:border-none border-gray-700 p-2 rounded-md">
              {dayjs(new Date(dayjs().year(), monthIndex)).format("MMMM YYYY")}
            </h1>
            <button onClick={handleChangeNextMonth}>
              <span className="">
                <i class="fa-solid fa-chevron-right  cursor-pointer text-gray-600 mx-2"></i>
              </span>
            </button>
          </div>
        </div>
        <div className=" hidden  md:block md:flex flex-row gap-[20px]">
          <div>
            <div
              id="tooltip-default"
              role="tooltip"
              class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
            >
              Tooltip content
              <div class="tooltip-arrow" data-popper-arrow></div>
            </div>
            <i
              data-tooltip-target="tooltip-default"
              type="button"
              className="fa-solid fa-location-dot text-gray-700 cursor-pointer"
            ></i>{" "}
            {stored_location?.country ? (
              <>{stored_location?.country}</>
            ) : (
              "allow geo location"
            )}
          </div>
          {/* <div>
            <h1 className=" hidden md:block">
              SP Analyst <span className="text-[#015e75]">{app_version}</span>
            </h1>
          </div> */}
        </div>
      </header>
    </>
  );
}

export default CalenderHeader;

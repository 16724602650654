import React from "react";
import {
  Chart as ChartJs,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
} from "chart.js";
import { Line, Bar, Chart } from "react-chartjs-2";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { uri } from "../../../https";
import moment from "moment-jalaali";

ChartJs.register(
  CategoryScale,
  LinearScale,
  LineElement,
  BarElement,
  PointElement
);

const FacebookMatrics = () => {
  
  const [loading, setLoading] = useState(false);

 

  useEffect(() => {
    // FetchFBMatrics28();
  }, []);


  var chart28 = {
    labels: ["Impressions 28 Days", "Engaged 28 Days"],
    datasets: [
      {
        label: [`28 Days Matrics`],
        data: ["1", "2", "3"],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 2,
        borderRadius: 5,
        borderSkipped: false,
      },
    ],
  };
    var chartweekly = {
      labels: ["Impressions Weekly", "Engaged Weekly"],
      datasets: [
        {
          label: [`Weekly Matrics`],
          data: ['1','2','3'],
          backgroundColor: [
            "rgba(255, 99, 132, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(255, 206, 86, 0.2)",
            "rgba(75, 192, 192, 0.2)",
            "rgba(153, 102, 255, 0.2)",
            "rgba(255, 159, 64, 0.2)",
          ],
          borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)",
          ],
          borderWidth: 2,
          borderRadius: 5,
          borderSkipped: false,
        },
      ],
    };

        var chartdaily = {
          labels: ["Impressions Weekly", "Engaged Weekly"],
          datasets: [
            {
              label: [`Weekly Matrics`],
              data: ["1", "2", "3"],
              backgroundColor: [
                "rgba(255, 99, 132, 0.2)",
                "rgba(54, 162, 235, 0.2)",
                "rgba(255, 206, 86, 0.2)",
                "rgba(75, 192, 192, 0.2)",
                "rgba(153, 102, 255, 0.2)",
                "rgba(255, 159, 64, 0.2)",
              ],
              borderColor: [
                "rgba(255, 99, 132, 1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
                "rgba(153, 102, 255, 1)",
                "rgba(255, 159, 64, 1)",
              ],
              borderWidth: 2,
              borderRadius: 5,
              borderSkipped: false,
            },
          ],
        };

  var options = {
    maintainAspectRatio: false,
    scales: {},
    legend: {
      display: false,

      labels: {
        fontSize: 25,
      },
    },
  };
  return (
    <div className=" ml-10 m-2">
      <div>
        <h1 className=" text-[20px] font-semibold">Facebook Matrics</h1>
      </div>
      <div className="mt-5">
        <h1 className=" text-[20px] text-gray-600">Last 28 Days</h1>
      </div>
      <div className=" w-full">
        <Bar data={chart28} options={options} height={400} width={400} />
      </div>
      <div className="mt-5">
        <h1 className=" text-[20px] text-gray-600">Weekly</h1>
      </div>
      <div className=" w-full">
        <Bar data={chartweekly} options={options} height={400} width={400} />
      </div>
      <div className="mt-5">
        <h1 className=" text-[20px] text-gray-600">Daily</h1>
      </div>
      <div className=" w-full">
        <Bar data={chartdaily} options={options} height={400} width={400} />
      </div>
    </div>
  );
};

export default FacebookMatrics;

import axios from "axios";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { uri } from "../../../https";
import TopBar from "../SideMenu/TopBar";
import { data } from "autoprefixer";
import { ToastContainer, toast } from "react-toastify";
import GlobalContext from "../../../context/GlobalContext";
import EditPublishedModal from "./EditPublishedModal";
import { Player } from "@lottiefiles/react-lottie-player";
import SubscriptionModal from "../../../SubscriptionModal";
import { Link, NavLink } from "react-router-dom";
import FooterLoggedIn from "../../Footer/FooterLoggedIn";
import EmojiPicker, {
  EmojiStyle,
  SkinTones,
  Theme,
  Categories,
  EmojiClickData,
  Emoji,
  SuggestionMode,
  SkinTonePickerLocation,
} from "emoji-picker-react";

const Published = () => {
  const [loading, setLoading] = useState(false);
  const [loading_data, setLoadingData] = useState(false);
  const {
    setDaysSelected,
    setShowEventModal,
    savedEvents,
    setSelectedEvent,
    setShowSelectedEventModal,
    daysSelected,
    dispatchCalEvent,
    selectedEvent,
    setIsBackwardDateSelected,
    isBackwardDateSelected,
    editcontentModal,
    setEditContentModal,
    setEditPublishedContentModal,
    editpublishedcontentModal,
    setShowSubscriptionModal,
    showSubscriptionModal,
  } = useContext(GlobalContext);
  const [published_post_type, setPublishedPostType] = useState([
    {
      id: 0,
      option: "post",
    },
    {
      id: 1,
      option: "image",
    },
    {
      id: 2,
      option: "video",
    },
    {
      id: 3,
      option: "reels",
    },
    {
      id: 4,
      option: "story",
    },
  ]);

  const [mobile_sidebar_controller, setMobileSideBarController] =
    useState(false);

  const [publishedPosts, setPublishedPosts] = useState([]);
  const GetPublishedPosts = async () => {
    const url = `${uri}api/get_published_posts`;
    setLoading(true);
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        setPublishedPosts(response?.data?.published_posts);
        setLoading(false);
      },
      (error) => {
        console.log(error);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    GetPublishedPosts();
  }, []);

  console.log("published posts list", publishedPosts);

  const [fetchPostById, setFetchPostById] = useState("");
  const [getPostById, setGetPostById] = useState([]);
  const GetPostsDetailsById = async (posts) => {
    setFetchPostById(posts.id);

    const url = `${uri}api/get_published_posts_id/${posts.social_platform_postid}`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        setGetPostById(response?.data?.published_posts_id);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  console.log("Get post by id:", getPostById[0]?.post_type);

  const [facebookComments, setFacebookComments] = useState([]);
  const GetFacebookPostsComments = async () => {
    let comment_id = getPostById[0].social_platform_postid;
    const url = `${uri}api/get_facebook_comments/${comment_id}`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        setFacebookComments(response?.data?.postid_comment);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const [instagramComments, setInstagramComments] = useState([]);
  const GetInstagramPostsComments = async () => {
    let comment_id = getPostById[0].social_platform_postid;
    const url = `${uri}api/get_facebook_comments/${comment_id}`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        setInstagramComments(response?.data?.postid_comment);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  console.log("Facebook posts comment:", facebookComments);
  console.log("Instagram posts comment:", instagramComments);

  const [commentsModal, setCommentsModal] = useState(false);
  const [commentId, setCommentsId] = useState("");
  const [comment_message, setCommentMessage] = useState("");
  const PublishReplyComment = (comments) => {
    console.log("Replied to the comment", comments?.id);
    setCommentsId(comments?.id);
  };

  const PublishFacebookComment = async () => {
    const url = `${uri}api/post_comment_fb_by_id`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    setLoadingData(true);
    const data = {
      comment_id: commentId,
      post_message: comment_message,
    };
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.post(url, data, config).then(
      (response) => {
        console.log(response.data);
        if (response.data.status === 200) {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setLoadingData(false);
          setCommentMessage("");
        }
      },
      (error) => {
        console.log(error);
        setLoadingData(false);
      }
    );
  };

  const PublishInstagramComment = async () => {
    const url = `${uri}api/post_comment_to_instagram_id`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    setLoadingData(true);
    const data = {
      comment_id: commentId,
      post_message: comment_message,
    };
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.post(url, data, config).then(
      (response) => {
        console.log(response.data);
        if (response.data.status === 200) {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setLoadingData(false);
          setCommentMessage("");
        }
      },
      (error) => {
        console.log(error);
        toast.error("Unable to post your comments", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoadingData(false);
      }
    );
  };

  console.log("Get posts by id", getPostById[0]);

  const SelectedPostType = (selected_post_type) => {
    console.log(selected_post_type);
  };

  const [Post_Interaction_Modal, setPostInteractionModal] = useState(false);

  const [published_post_deleted_message, setPublishedPostDeletedMessage] =
    useState("");
  const deletePublishedPost = (post) => {
    console.log("Post deleted :", post);
    const url = `${uri}api/delete_published_post`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    setLoadingData(true);
    const data = {
      post_id: post.post_id,
      social_platform_postid: post.social_platform_postid,
    };
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    axios.post(url, data, config).then(
      (response) => {
        console.log(response.data);
        if (response.data.status === 200) {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setLoadingData(false);
          setPostInteractionModal(!Post_Interaction_Modal);
          setPublishedPostDeletedMessage(response.data.message);
        }
        const calendarEvent = {
          id: post.id,
        };
        dispatchCalEvent({ type: "delete", payload: post });
      },
      (error) => {
        console.log(error);
        setLoadingData(false);
      }
    );
  };

  const [sidebar_controller, setSideBarController] = useState(false);

  const [published_data_to_edit, setPublishedDataToEdit] = useState("");

  const HandlePublishedDataToEdit = (data) => {
    setPublishedDataToEdit(data);
  };

  console.log("Published data to edit", published_data_to_edit);

  console.log("Edit triggered", getPostById);

  const [facebook_post_analytics, setFacebookPostAnalytics] = useState([]);
  const GetFacebookPostsAnalytics = async () => {
    let social_platform_postid = getPostById[0].social_platform_postid;
    const url = `${uri}api/get_facebook_post_analytics/${social_platform_postid}`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        setFacebookPostAnalytics(response?.data?.facebook_post_analytics.data);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const [instagram_post_analytics, setInstagramPostAnalytics] = useState([]);
  const GetInstagramPostsAnalytics = async () => {
    let social_platform_postid = getPostById[0].social_platform_postid;
    const url = `${uri}api/get_instagram_post_analytics/${social_platform_postid}`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        setInstagramPostAnalytics(
          response?.data?.instagram_post_analytics.data
        );
      },
      (error) => {
        console.log(error);
      }
    );
  };

  // console.log(
  //   "Facebook post analytics",
  //   facebook_post_analytics[0]?.values[0]?.value
  // );

  const [is_subscription_active, setIsSubscriptionActive] = useState([]);
  const GetUserSubscription = async () => {
    const url = `${uri}api/check_subscription_is_active`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        if (response.data.status == 200) {
          setShowSubscriptionModal(false);
        }
        if (response.data.status == 400) {
          setShowSubscriptionModal(true);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    GetUserSubscription();
  }, []);

  useEffect(() => {
    GetPostsDetailsById();
    GetFacebookPostsComments();
    GetInstagramPostsComments();
    GetFacebookPostsAnalytics();
    GetInstagramPostsAnalytics();
  }, [getPostById, published_post_deleted_message]);

  const [metaPageDetails, setMetaPageDetails] = useState([]);
  const FetchMetaPages = async () => {
    const url = `${uri}api/get_meta_pagedetails`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        setMetaPageDetails(response?.data?.meta_pagedetails);
        console.log("meta page details", response?.data?.meta_pagedetails);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    FetchMetaPages();
  }, []);

  const [selectedEmoji, setSelectedEmoji] = useState("");
  const [inputValue, setInputValue] = useState("");

  function Emoji(onEmojiClick) {
    setCommentMessage(
      (inputValue) =>
        inputValue +
        (onEmojiClick.isCustom ? onEmojiClick.unified : onEmojiClick.emoji)
    );
    setSelectedEmoji(emojiData.unified);
    console.log(onEmojiClick.emoji);
    // setCommentMessage(onEmojiClick.emoji);
  }

  return (
    <>
      {loading ? (
        <>
          <div className=" h-screen w-full fixed left-0 bg-white top-[0px] flex justify-center items-center  backdrop-blur-sm">
            <div className=" flex flex-col justify-center items-center">
              <Player
                src="https://lottie.host/1eb4f4a4-706d-498d-bf89-192423f0fd49/DOmyAJcAln.json"
                className="player"
                loop
                autoplay
                className="h-[200px]"
              />
              <p className=" text-[25px] text-gray-800 font-semibold">
                Please wait...
              </p>
            </div>
          </div>
        </>
      ) : (
        <>
          <Fragment>
            <TopBar />
            {showSubscriptionModal && <SubscriptionModal />}
            <div className="flex justify-start items-start mt-0.5">
              <div
                className={`${
                  !sidebar_controller ? " w-[400px] " : " w-[400px] "
                }  h-screen  overflow-y-auto bg-white shadow-md  border-r-gray-700 duration-300 hidden md:block`}
              >
                {/* <div className=" P-3 m-3">
                  <div
                    onClick={() => setSideBarController(!sidebar_controller)}
                    className={` h-[30px] w-[30px] absolute ${
                      !sidebar_controller
                        ? " left-[220px] md:left-[300px]"
                        : " left-[100px] md:left-[120px] "
                    }   bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] border-1 border-white text-white rounded-full flex justify-center items-center cursor-pointer mt-[30px] duration-300`}
                  >
                    <i
                      class={` ${
                        !sidebar_controller
                          ? "fa-solid fa-arrow-left"
                          : "fa-solid fa-arrow-left rotate-180 "
                      } duration-300 `}
                    ></i>
                  </div>
                </div> */}
                <div
                  className={` ${
                    !sidebar_controller ? "grid-cols-2" : "grid-cols-1"
                  } grid  place-items-center p-3 gap-4`}
                >
                  {publishedPosts.map((published, index) => {
                    return (
                      <div key={index}>
                        <div className=" absolute published_social_icon m-1 ">
                          <i
                            className={
                              (published?.social_platform_name === "facebook" &&
                                "  fa-brands fa-facebook text-[22px] text-[#3b5998] h-[20px] bg-white  rounded-full flex justify-center items-center ") ||
                              (published?.social_platform_name ===
                                "instagram" &&
                                "   fa-brands fa-square-instagram  text-[22px] text-[#d62976] bg-white h-[20px] rounded-full flex justify-center items-center") ||
                              (published?.social_platform_name === "linkedin" &&
                                " fa-brands fa-linkedin text-[22px] text-[#0A66C2] bg-white h-[20px]  rounded-full flex justify-center items-center  ") ||
                              (published?.social_platform_name ===
                                "pinterest" &&
                                " fa-brands fa-pinterest text-[22px] text-[#E60023] bg-white h-[20px]  rounded-full flex justify-center items-center ") ||
                              (published?.social_platform_name === "tumblr" &&
                                " fa-brands fa-square-tumblr text-[22px] text-[#34526f] bg-white h-[20px]  rounded-full flex justify-center items-center ")
                            }
                          ></i>
                        </div>
                        <>
                          {published.post_type == "image" ? (
                            <>
                              {" "}
                              <img
                                onClick={() => GetPostsDetailsById(published)}
                                src={published?.media}
                                className={
                                  published.id === fetchPostById
                                    ? ` ${
                                        !sidebar_controller
                                          ? " h-[130px]"
                                          : " h-[90px] "
                                      } rounded-md  border-2  border-[#3EC4E8] cursor-pointer object-contain`
                                    : `${
                                        !sidebar_controller
                                          ? "h-[130px]"
                                          : " h-[90px] "
                                      }  rounded-md   cursor-pointer object-contain`
                                }
                                //   className={` h-[130px] rounded-md  border-1  border-purple-500 cursor-pointer`}
                              />
                            </>
                          ) : published.post_type == "video" ? (
                            <>
                              <video
                                onClick={() => GetPostsDetailsById(published)}
                                src={published?.video_media}
                                className={
                                  published.id === fetchPostById
                                    ? " h-[100px] rounded-md  border-2  border-[#3EC4E8] cursor-pointer object-contain"
                                    : " h-[100px] rounded-md   cursor-pointer object-contain"
                                }
                              ></video>
                            </>
                          ) : published.post_type == "text" ? (
                            <>
                              <img
                                onClick={() => GetPostsDetailsById(published)}
                                src="https://res.cloudinary.com/dv5jjlsd7/image/upload/v1690971298/SP%20ANALYST/no-photo_ubrqja.png"
                                className={
                                  published.id === fetchPostById
                                    ? " h-[100px] rounded-md  border-2  border-[#3EC4E8] cursor-pointer object-contain"
                                    : " h-[100px] rounded-md   cursor-pointer object-contain"
                                }
                                //   className={` h-[130px] rounded-md  border-1  border-purple-500 cursor-pointer`}
                              />
                            </>
                          ) : published.post_type == "reels" ? (
                            <>
                              {" "}
                              <video
                                onClick={() => GetPostsDetailsById(published)}
                                src={published?.video_media}
                                className={
                                  published.id === fetchPostById
                                    ? " h-[100px] rounded-md  border-2  border-[#3EC4E8] cursor-pointer object-contain"
                                    : " h-[100px] rounded-md   cursor-pointer object-contain"
                                }
                              ></video>
                            </>
                          ) : published.post_type == "post" ? (
                            <>
                              <img
                                onClick={() => GetPostsDetailsById(published)}
                                src={published?.media}
                                className={
                                  published.id === fetchPostById
                                    ? " h-[130px] rounded-md  border-2  border-[#3EC4E8] cursor-pointer object-contain"
                                    : " h-[130px] rounded-md   cursor-pointer object-contain"
                                }
                                //   className={` h-[130px] rounded-md  border-1  border-purple-500 cursor-pointer`}
                              />
                            </>
                          ) : published.post_type == "story" ? (
                            <>
                              <img
                                onClick={() => GetPostsDetailsById(published)}
                                src={published?.media}
                                className={
                                  published.id === fetchPostById
                                    ? " h-[130px] rounded-md  border-2  border-[#3EC4E8] cursor-pointer object-contain"
                                    : " h-[130px] rounded-md   cursor-pointer object-contain"
                                }
                                //   className={` h-[130px] rounded-md  border-1  border-purple-500 cursor-pointer`}
                              />
                            </>
                          ) : published.post_type == "pins" ? (
                            <>
                              <img
                                onClick={() => GetPostsDetailsById(published)}
                                src={published?.media}
                                className={
                                  published.id === fetchPostById
                                    ? " h-[130px] rounded-md  border-2  border-[#3EC4E8] cursor-pointer object-contain"
                                    : " h-[130px] rounded-md   cursor-pointer object-contain"
                                }
                                //   className={` h-[130px] rounded-md  border-1  border-purple-500 cursor-pointer`}
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div></div>
              <div>
                <div
                  className={`w-full ${
                    !mobile_sidebar_controller
                      ? " w-full overflow-auto scrollbar-hide "
                      : " w-full overflow-auto scrollbar-hide "
                  } bg-[#f5f5f5] absolute h-[130px] border-1 border-r-gray-200 duration-300 block md:hidden`}
                >
                  <div
                    className={` flex flex-row items-center justify-between mobile_sidebar_scrollbar ${
                      !mobile_sidebar_controller
                        ? " block w-full"
                        : " hidden  w-full"
                    } `}
                  >
                    {publishedPosts?.map((published, index) => {
                      return (
                        <Fragment key={index}>
                          <li className=" flex justify-start items-center  outline-gray-200 hover:bg-[#0180a1]/10 w-full   transition-all hover:transition-all p-2 rounded-md mb-4 mt-3 cursor-pointer">
                            <div className=" h-[50px] w-[50px]     flex justify-center items-center  ">
                              <div
                                className={
                                  (published?.social_platform_name ===
                                    "facebook" &&
                                    "  fa-brands fa-facebook text-[22px] text-[#3b5998] h-[20px] bg-white  rounded-full flex justify-center items-center ") ||
                                  (published?.social_platform_name ===
                                    "instagram" &&
                                    "   fa-brands fa-square-instagram  text-[22px] text-[#d62976] bg-white h-[20px] rounded-full flex justify-center items-center") ||
                                  (published?.social_platform_name ===
                                    "linkedin" &&
                                    " fa-brands fa-linkedin text-[22px] text-[#0A66C2] bg-white h-[20px]  rounded-full flex justify-center items-center  ") ||
                                  (published?.social_platform_name ===
                                    "pinterest" &&
                                    " fa-brands fa-pinterest text-[22px] text-[#E60023] bg-white h-[20px]  rounded-full flex justify-center items-center ") ||
                                  (published?.social_platform_name ===
                                    "tumblr" &&
                                    " fa-brands fa-square-tumblr text-[22px] text-[#34526f] bg-white h-[20px]  rounded-full flex justify-center items-center ")
                                }
                              ></div>
                            </div>
                          </li>
                          <>
                            {published.post_type == "image" ? (
                              <>
                                {" "}
                                <img
                                  onClick={() => GetPostsDetailsById(published)}
                                  src={published?.media}
                                  className={
                                    published.id === fetchPostById
                                      ? ` ${
                                          !sidebar_controller
                                            ? " h-[130px]"
                                            : " h-[90px] "
                                        } rounded-md  border-2  border-[#3EC4E8] cursor-pointer object-contain`
                                      : `${
                                          !sidebar_controller
                                            ? "h-[130px]"
                                            : " h-[90px] "
                                        }  rounded-md   cursor-pointer object-contain`
                                  }
                                  //   className={` h-[130px] rounded-md  border-1  border-purple-500 cursor-pointer`}
                                />
                              </>
                            ) : published.post_type == "video" ? (
                              <>
                                <video
                                  onClick={() => GetPostsDetailsById(published)}
                                  src={published?.video_media}
                                  className={
                                    published.id === fetchPostById
                                      ? " h-[100px] rounded-md  border-2  border-[#3EC4E8] cursor-pointer object-contain"
                                      : " h-[100px] rounded-md   cursor-pointer object-contain"
                                  }
                                ></video>
                              </>
                            ) : published.post_type == "text" ? (
                              <>
                                <img
                                  onClick={() => GetPostsDetailsById(published)}
                                  src="https://res.cloudinary.com/dv5jjlsd7/image/upload/v1690971298/SP%20ANALYST/no-photo_ubrqja.png"
                                  className={
                                    published.id === fetchPostById
                                      ? " h-[100px] rounded-md  border-2  border-[#3EC4E8] cursor-pointer object-contain"
                                      : " h-[100px] rounded-md   cursor-pointer object-contain"
                                  }
                                  //   className={` h-[130px] rounded-md  border-1  border-purple-500 cursor-pointer`}
                                />
                              </>
                            ) : published.post_type == "reels" ? (
                              <>
                                {" "}
                                <video
                                  onClick={() => GetPostsDetailsById(published)}
                                  src={published?.video_media}
                                  className={
                                    published.id === fetchPostById
                                      ? " h-[100px] rounded-md  border-2  border-[#3EC4E8] cursor-pointer object-contain"
                                      : " h-[100px] rounded-md   cursor-pointer object-contain"
                                  }
                                ></video>
                              </>
                            ) : published.post_type == "post" ? (
                              <>
                                <img
                                  onClick={() => GetPostsDetailsById(published)}
                                  src={published?.media}
                                  className={
                                    published.id === fetchPostById
                                      ? " h-[100px] rounded-md  border-2  border-[#3EC4E8] cursor-pointer object-contain"
                                      : "h-[100px] rounded-md   cursor-pointer object-contain"
                                  }
                                  //   className={` h-[130px] rounded-md  border-1  border-purple-500 cursor-pointer`}
                                />
                              </>
                            ) : published.post_type == "story" ? (
                              <>
                                <img
                                  onClick={() => GetPostsDetailsById(published)}
                                  src={published?.media}
                                  className={
                                    published.id === fetchPostById
                                      ? " h-[100px] rounded-md  border-2  border-[#3EC4E8] cursor-pointer object-contain"
                                      : " h-[100px] rounded-md   cursor-pointer object-contain"
                                  }
                                  //   className={` h-[130px] rounded-md  border-1  border-purple-500 cursor-pointer`}
                                />
                              </>
                            ) : published.post_type == "pins" ? (
                              <>
                                <img
                                  onClick={() => GetPostsDetailsById(published)}
                                  src={published?.media}
                                  className={
                                    published.id === fetchPostById
                                      ? " h-[100px] rounded-md  border-2  border-[#3EC4E8] cursor-pointer object-contain"
                                      : "h-[100px] rounded-md   cursor-pointer object-contain"
                                  }
                                  //   className={` h-[130px] rounded-md  border-1  border-purple-500 cursor-pointer`}
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        </Fragment>
                      );
                    })}
                  </div>
                </div>
                {/* <div
        onClick={() => setMobileSideBarController(!mobile_sidebar_controller)}
        className={` h-[30px] w-[30px]    relative  block md:hidden     ${
          !mobile_sidebar_controller ? " " : ""
        }   bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] border-1 border-white text-white rounded-full flex justify-center items-center cursor-pointer mt-[30px] duration-300`}
      >
        <i
          class={` ${
            !mobile_sidebar_controller
              ? "fa-solid fa-arrow-left"
              : "fa-solid fa-arrow-left rotate-180 "
          } duration-300 mobile_sidebar_handle overflow-hidden   `}
        ></i>
      </div> */}
              </div>
              <div className=" bg-[#f5f5f5] overflow-scroll  flex justify-center shadow-md w-full h-screen  p-3 mt-[130px] md:mt-[0px]">
                {getPostById[0]?.post_message ? (
                  <div className=" w-full md:w-[700px] bg-white h-screen overflow-scroll rounded-md border-1 border-gray-600 p-5">
                    <div className=" flex justify-evenly gap-[30px] items-center ">
                      <div>
                        <>
                          {getPostById[0]?.post_type == "image" ? (
                            <>
                              <img
                                src={getPostById[0]?.media}
                                className="  h-[120px] object-contain rounded-md"
                              />
                            </>
                          ) : getPostById[0]?.post_type == "video" ? (
                            <>
                              <video
                                src={getPostById[0]?.video_media}
                                className="  h-[120px] object-contain rounded-md"
                              ></video>
                            </>
                          ) : getPostById[0]?.post_type == "reels" ? (
                            <>
                              {" "}
                              <video
                                src={getPostById[0]?.video_media}
                                className="  h-[120px] object-contain rounded-md"
                              ></video>
                            </>
                          ) : getPostById[0]?.post_type == "post" ? (
                            <>
                              <img
                                src={getPostById[0]?.media}
                                className="  h-[120px] object-contain rounded-md"
                              />
                            </>
                          ) : getPostById[0]?.post_type == "text" ? (
                            <>
                              <img
                                src="https://res.cloudinary.com/dv5jjlsd7/image/upload/v1690971298/SP%20ANALYST/no-photo_ubrqja.png"
                                className="  h-[120px] object-contain rounded-md"
                              />
                            </>
                          ) : getPostById[0]?.post_type == "story" ? (
                            <>
                              <img
                                src={getPostById[0]?.media}
                                className="  h-[120px] object-contain rounded-md"
                              />
                            </>
                          ) : getPostById[0]?.post_type == "pins" ? (
                            <>
                              <img
                                src={getPostById[0]?.media}
                                className="  h-[120px] object-contain rounded-md"
                              />
                            </>
                          ) : null}
                        </>
                      </div>
                      <div className=" w-[340px]">
                        <p className=" text-[17px] font-medium text-justify ">
                          {getPostById[0]?.post_message}
                        </p>

                        <div className="mt-2">
                          <i class="fa-regular fa-clock text-gray-500"></i>{" "}
                          <span className="text-gray-500 text-[15px]">
                            {getPostById[0]?.date_to_post}
                          </span>
                        </div>
                      </div>

                      <div className="mt-2 flex justify-end items-end">
                        <i
                          onClick={() => {
                            setPostInteractionModal(!Post_Interaction_Modal);
                          }}
                          class="fa-solid fa-ellipsis text-[22px] text-gray-900 cursor-pointer"
                        ></i>
                      </div>
                      <>
                        {Post_Interaction_Modal && (
                          <div className=" flex flex-col justify-center items-center  w-[250px] p-3 bg-white shadow-md absolute  mt-[140px] ml-[160px] md:mt-[20px] md:ml-[900px] rounded-md ">
                            <div>
                              {getPostById[0]?.social_platform_name ===
                              "facebook" ? (
                                <>
                                  {" "}
                                  <button
                                    onClick={() => {
                                      setEditPublishedContentModal(true);
                                      HandlePublishedDataToEdit(getPostById);
                                      setPostInteractionModal(
                                        !Post_Interaction_Modal
                                      );
                                    }}
                                    className=" text-blue-500 font-semibold select-none "
                                  >
                                    Edit
                                    {/* Edit */}
                                  </button>
                                </>
                              ) : getPostById[0]?.social_platform_name ===
                                "instagram" ? (
                                <>
                                  {" "}
                                  <button
                                    onClick={() => {
                                      // setEditPublishedContentModal(true);
                                      // HandlePublishedDataToEdit(getPostById);
                                    }}
                                    className=" text-blue-500 font-semibold select-none "
                                  >
                                    edit feature is not supported
                                    {/* Edit */}
                                  </button>
                                </>
                              ) : getPostById[0]?.social_platform_name ===
                                "tumblr" ? (
                                <button
                                  onClick={() => {
                                    setEditPublishedContentModal(true);
                                    HandlePublishedDataToEdit(getPostById);
                                  }}
                                  className=" text-blue-500 font-semibold select-none "
                                >
                                  Edit
                                  {/* Edit */}
                                </button>
                              ) : getPostById[0]?.social_platform_name ===
                                "linkedin" ? (
                                <>
                                  {" "}
                                  <button
                                    onClick={() => {
                                      // setEditPublishedContentModal(true);
                                      // HandlePublishedDataToEdit(getPostById);
                                    }}
                                    className=" text-blue-500 font-semibold select-none "
                                  >
                                    edit feature is not supported
                                    {/* Edit */}
                                  </button>
                                </>
                              ) : null}
                            </div>
                            {/* <hr class="h-px my-2 bg-gray-500 border-1 border-gray-300 w-full " /> */}

                            <div className="mt-3">
                              {loading_data ? (
                                <>
                                  <button className=" text-red-500 font-semibold  select-none ">
                                    <svg
                                      aria-hidden="true"
                                      class="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                                      viewBox="0 0 100 101"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                        fill="currentColor"
                                      />
                                      <path
                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                        fill="currentFill"
                                      />
                                    </svg>
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                    onClick={() => {
                                      deletePublishedPost(getPostById[0]);
                                      dispatchCalEvent({
                                        type: "delete",
                                        payload: selectedEvent,
                                      });
                                    }}
                                    className=" text-red-500 font-semibold  select-none "
                                  >
                                    Delete
                                  </button>
                                </>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    </div>
                    <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
                    <h1 className=" text-gray-700 font-medium text-[16px] md:text-[18px] p-1 capitalize">
                      {" "}
                      {getPostById[0]?.social_platform_name} Comments
                    </h1>
                    {facebookComments[0]?.message ? (
                      <>
                        <div className=" w-full  rounded-md p-4">
                          {facebookComments.map((comments, index) => {
                            return (
                              <>
                                <div className="border-1 rounded-md mb-5  p-3">
                                  <div className=" flex justify-start gap-2">
                                    <div className="bg-[#3b5998] cursor-pointer text-white h-[45px] w-[45px] rounded-full border-1 border-gray-300   flex justify-center items-center">
                                      {comments?.from?.name?.slice(0, 1)}
                                    </div>
                                    <div>
                                      <h1 className=" text-[16px] font-semibold ">
                                        {comments?.from?.name}
                                      </h1>
                                      <p className=" text-gray-500 ">
                                        {comments?.message}{" "}
                                        {/* {facebookComments[0]?.message} */}
                                      </p>
                                    </div>
                                  </div>
                                  <div className=" flex justify-end  cursor-pointer">
                                    <h1
                                      className=" text-gray-500  font-medium"
                                      onClick={() => {
                                        PublishReplyComment(comments);
                                        setCommentsModal(true);
                                      }}
                                    >
                                      Reply
                                    </h1>
                                  </div>
                                  {commentsModal && (
                                    <>
                                      {commentId === comments.id && (
                                        <div className="w-full   mt-3 rounded-md p-5">
                                          <input
                                            type="text"
                                            value={comment_message}
                                            onChange={(e) =>
                                              setCommentMessage(e.target.value)
                                            }
                                            placeholder={`Reply to ${comments?.from?.name}`}
                                            className=" w-full  rounded-md placeholder:text-[15px]"
                                          />

                                          <div className=" mt-[20px]">
                                            <EmojiPicker
                                              onEmojiClick={Emoji}
                                              autoFocusSearch={false}
                                              emojiStyle={EmojiStyle.NATIVE}
                                              height={"400px"}
                                            />
                                          </div>
                                          <div className=" flex justify-end items-end mt-2">
                                            {loading_data ? (
                                              <button
                                                // onClick={PublishReplyComment}
                                                className=" px-6 py-2 bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md"
                                              >
                                                <svg
                                                  aria-hidden="true"
                                                  class="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                                                  viewBox="0 0 100 101"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path
                                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                    fill="currentColor"
                                                  />
                                                  <path
                                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                    fill="currentFill"
                                                  />
                                                </svg>
                                              </button>
                                            ) : (
                                              <button
                                                onClick={PublishFacebookComment}
                                                className=" px-6 py-2 bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9]  text-white rounded-md"
                                              >
                                                Post
                                              </button>
                                            )}
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  )}
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </>
                    ) : null}
                    {instagramComments[0]?.text ? (
                      <>
                        <div className=" w-full  rounded-md p-4">
                          {instagramComments.map((comments, index) => {
                            return (
                              <>
                                <div className="border-1 rounded-md mb-5  p-3">
                                  <div className=" flex justify-start gap-2">
                                    <div className="bg-[#3b5998] cursor-pointer text-white h-[45px] w-[45px] rounded-full border-1 border-gray-300   flex justify-center items-center">
                                      {comments?.id?.slice(0, 1)}
                                    </div>
                                    <div>
                                      <h1 className=" text-[16px] font-semibold ">
                                        {comments?.id}
                                      </h1>
                                      <p className=" text-gray-500 ">
                                        {comments?.text}
                                      </p>
                                    </div>
                                  </div>
                                  <div className=" flex justify-end  cursor-pointer">
                                    <h1
                                      className=" text-gray-500  font-medium"
                                      onClick={() => {
                                        PublishReplyComment(comments);
                                        setCommentsModal(true);
                                      }}
                                    >
                                      Reply
                                    </h1>
                                  </div>
                                  {commentsModal && (
                                    <>
                                      {commentId === comments.id && (
                                        <div className="w-full   mt-3 rounded-md p-5">
                                          <input
                                            type="text"
                                            value={comment_message}
                                            onChange={(e) =>
                                              setCommentMessage(e.target.value)
                                            }
                                            placeholder={`Reply to ${comments?.id}`}
                                            className=" w-full  rounded-md placeholder:text-[15px]"
                                          />
                                          <div className=" mt-[20px]">
                                            <EmojiPicker
                                              onEmojiClick={Emoji}
                                              autoFocusSearch={false}
                                              emojiStyle={EmojiStyle.NATIVE}
                                              height={"400px"}
                                            />
                                          </div>
                                          <div className=" flex justify-end items-end mt-2">
                                            {loading_data ? (
                                              <button
                                                // onClick={PublishInstagramComment}
                                                className=" px-6 py-2 bg-[#3EC4E8] text-white rounded-md"
                                              >
                                                <svg
                                                  aria-hidden="true"
                                                  class="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                                                  viewBox="0 0 100 101"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path
                                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                    fill="currentColor"
                                                  />
                                                  <path
                                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                    fill="currentFill"
                                                  />
                                                </svg>
                                              </button>
                                            ) : (
                                              <button
                                                onClick={
                                                  PublishInstagramComment
                                                }
                                                className=" px-6 py-2 bg-[#3EC4E8] text-white rounded-md"
                                              >
                                                Post
                                              </button>
                                            )}
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  )}
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </>
                    ) : (
                      <h1 className=" text-gray-600 font-medium p-1 flex justify-center items-center  text-[16px] md:text-[18px]">
                        No comments info available at this time
                      </h1>
                    )}
                    <div>
                      <div>
                        <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
                        <h1 className=" text-gray-700 text-[16px] md:text-[18px] font-medium p-1">
                          <span className=" capitalize">
                            {getPostById[0]?.social_platform_name}
                          </span>{" "}
                          Post Analytics
                        </h1>
                      </div>
                      {getPostById[0]?.social_platform_name === "facebook" ? (
                        <div className=" flex justify-start items-center px-1  gap-[80px] mt-[20px]">
                          <div className=" flex flex-col justify-center items-center">
                            <h1 className="  font-medium ">
                              <i class="fa-regular fa-thumbs-up text-gray-600 text-[24px]"></i>
                            </h1>
                            <h1 className=" text-[16px] font-medium text-gray-600">
                              {facebook_post_analytics ? (
                                <>
                                  {facebook_post_analytics[0]?.values[0]?.value}
                                </>
                              ) : (
                                <>0</>
                              )}
                            </h1>
                          </div>
                          <div className=" flex flex-col justify-center items-center">
                            <h1 className=" font-medium text-gray-600">
                              <i class="fa-regular fa-comment text-gray-600 text-[24px]"></i>
                            </h1>
                            <h1 className=" text-[16px] font-medium text-gray-600">
                              {facebookComments?.length}
                            </h1>
                          </div>
                        </div>
                      ) : getPostById[0]?.social_platform_name ===
                        "instagram" ? (
                        <div className=" flex justify-start items-center px-1  gap-[80px] mt-[20px]">
                          <div className=" flex flex-col justify-center items-center">
                            <h1 className="  font-medium ">
                              <i class="fa-regular fa-thumbs-up text-gray-600 text-[24px]"></i>
                            </h1>
                            <h1 className=" text-[16px] font-medium text-gray-600">
                              {instagram_post_analytics ? (
                                <>
                                  {
                                    instagram_post_analytics[0]?.values[0]
                                      ?.value
                                  }
                                </>
                              ) : (
                                <>0</>
                              )}
                            </h1>
                          </div>
                          <div className=" flex flex-col justify-center items-center">
                            <h1 className=" font-medium text-gray-600">
                              <i class="fa-regular fa-comment text-gray-600 text-[24px]"></i>
                            </h1>
                            <h1 className=" text-[16px] font-medium text-gray-600">
                              {instagramComments?.length}
                            </h1>
                          </div>
                          <div className=" flex flex-col justify-center items-center">
                            <h1 className=" font-medium text-gray-600">
                              <i class="fa-solid fa-chart-simple text-gray-600 text-[24px]"></i>
                            </h1>
                            <h1 className=" text-[16px] font-medium text-gray-600">
                              {instagram_post_analytics[1]?.values[0]?.value}
                            </h1>
                          </div>
                          <div className=" flex flex-col justify-center items-center">
                            <h1 className=" font-medium text-gray-600">
                              <i class="fa-solid fa-chart-line text-gray-600 text-[24px]"></i>
                            </h1>
                            <h1 className=" text-[16px] font-medium text-gray-600">
                              {instagram_post_analytics[2]?.values[0]?.value}
                            </h1>
                          </div>
                        </div>
                      ) : (
                        <h1 className=" text-gray-600 font-medium p-1 flex justify-center items-center  text-[16px] md:text-[18px]">
                          No analytics info available at this time
                        </h1>
                      )}
                      {/* {getPostById[0]?.social_platform_name === "instagram" ? (
                  <div className=" flex justify-start items-center px-1  gap-[80px] mt-[20px]">
                    <div className=" flex flex-col justify-center items-center">
                      <h1 className="  font-medium ">
                        <i class="fa-regular fa-thumbs-up text-gray-600 text-[24px]"></i>
                      </h1>
                      <h1 className=" text-[16px] font-medium text-gray-600">
                        {instagram_post_analytics ? (
                          <>{instagram_post_analytics[0]?.values[0]?.value}</>
                        ) : (
                          <>0</>
                        )}
                      </h1>
                    </div>
                    <div className=" flex flex-col justify-center items-center">
                      <h1 className=" font-medium text-gray-600">
                        <i class="fa-regular fa-comment text-gray-600 text-[24px]"></i>
                      </h1>
                      <h1 className=" text-[16px] font-medium text-gray-600">
                        {instagramComments?.length}
                      </h1>
                    </div>
                  </div>
                ) : (
                  <h1 className=" text-gray-600 font-medium p-1 flex justify-center items-center  text-[18px]">
                    No analytics info available at this time
                  </h1>
                )} */}
                    </div>
                  </div>
                ) : (
                  <div className=" h-screen flex items-center justify-center">
                    <div className=" flex  justify-center items-center  w-[600px] md:w-[1100px] h-screen">
                      <div>
                        <Player
                          src="https://lottie.host/f203792f-02bd-4d25-ba18-167f0f82f5c5/xVxXiELBDq.json"
                          className="player"
                          loop
                          autoplay
                          className="h-[300px]"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
            {/* Same as */}
            <ToastContainer />
            {editpublishedcontentModal && (
              <EditPublishedModal
                published_data_to_edit={published_data_to_edit}
              />
            )}
          </Fragment>
        </>
      )}
      <FooterLoggedIn />
    </>
  );
};

export default Published;

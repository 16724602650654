import React, { useContext, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import GlobalContext from "../../../context/GlobalContext";
import { uri } from "../../../https";
import axios from "axios";

const PromoCodeModal = ({ subscription_data }) => {
  const [loading, setLoading] = useState(false);
  const {
    setShowEventModal,
    daysSelected,
    dispatchCalEvent,
    selectedEvent,
    setShowShareLocation,
    showShareLocation,
    setShowSubscribeModal,
    showSubscribeModal,
    setShowPromoCodeModal,
    showPromoCodeModal,
  } = useContext(GlobalContext);

  console.log("PromoCode", subscription_data);

  const [promocode_text, processPromoCodeText] = useState("");
  const [serverpromocode_text, processServerPromoCodeText] = useState(null);
  const [promocode_applied_message, processPromoCodeAppliedMessage] =
    useState("");
  const [
    promocode_applied_invalid_message,
    processPromoCodeAppliedInvalidMessage,
  ] = useState("");
  const handleSubmit = () => {
    console.log("Submited Plan", promocode_text);
    setLoading(true);
    const formData = new FormData();
    formData.append("promo_code", promocode_text);

    const url = `${uri}api/validate_promo_code`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;

    let config = {
      headers: {
        Authorization: "Bearer " + token,
        accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    };
    axios.post(url, formData, config).then(
      (response) => {
        console.log(response.data);

        if (response.data.status == 200) {
          setLoading(false);
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: true,
            theme: "light",
          });
          processPromoCodeAppliedMessage(response.data.message);
          processServerPromoCodeText(response.data.promo_code.promo_code);
          processPromoCodeAppliedInvalidMessage("");
        }
        if (response.data.status == 400) {
          setLoading(false);
          toast.error(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: true,
            theme: "light",
          });
          processPromoCodeAppliedInvalidMessage(response.data.message);
          processPromoCodeAppliedMessage("");
        }
      },
      (error) => {
        console.log(error.response.data);

        toast.error("Something went wrong try again later..", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: true,
          theme: "light",
        });
        setLoading(false);
      }
    );
  };

  console.log("PromoCode ", serverpromocode_text);

  const PromoCodeCheckout = () => {
    console.log("Submited PromoCode", serverpromocode_text);
    setLoading(true);
    const formData = new FormData();
    formData.append("promo_code", serverpromocode_text);
    formData.append("sub_plan_id", subscription_data.stripe_price_id);
    formData.append("sub_amount", subscription_data.total_price);
    formData.append("sub_name", subscription_data.package_name);
    formData.append("sub_tenure", subscription_data.tenure);

    const url = `${uri}api/promo_code_checkout`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;

    let config = {
      headers: {
        Authorization: "Bearer " + token,
        accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    };
    axios.post(url, formData, config).then(
      (response) => {
        console.log(response.data);
        if (response.data.status == 200) {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: true,
            theme: "light",
          });
          window.location.replace(response.data.redirect_url);
        }
      },
      (error) => {
        console.log(error.response.data);

        setLoading(false);
      }
    );
  };

  return (
    <>
      <div className=" h-screen w-full fixed left-0 bg-black/50 top-[0px] flex justify-center items-center ">
        <div className=" flex flex-row gap-2 m-[20px] md:m-[80px] ">
          <div className=" bg-white rounded-md shadow-lg  w-full md:w-[600px] ">
            <header className="bg-[#f5f5f5]  px-4 py-2 flex justify-between items-center rounded-md  shadow-md">
              <h1 className=" font-semibold text-[16px] text-gray-800">
                Promo Code Modal
              </h1>
              <button onClick={() => setShowPromoCodeModal(false)}>
                <span className="material-icons-outlined text-gray-400">
                  close
                </span>
              </button>
            </header>
            <div className=" p-3">
              <div className=" w-full  bg-white  p-5 ">
                <div className=" flex justify-center items-center">
                  <img
                    src="https://res.cloudinary.com/dv5jjlsd7/image/upload/v1694588721/SP%20ANALYST/Promocode_devzzx.png"
                    className="  h-[250px] object-contain"
                  />
                </div>
                <div className=" flex justify-center items-center">
                  <p className=" text-[16px] md:text-[18px] font-semibold text-center md:text-left ">
                    Apply Promo Code
                  </p>
                </div>

                <div className=" flex flex-col md:flex-row justify-start items-center">
                  <div className=" w-full mt-[20px]">
                    <input
                      type="text"
                      name="promocode"
                      id="promocode"
                      value={promocode_text}
                      onChange={(e) => processPromoCodeText(e.target.value)}
                      className=" w-full py-3 outline-none bg-[#F0F0F0] rounded-md px-3 ring-1  ring-[#5aa7bc] focus:ring-[#015e75] "
                    />
                  </div>
                  <div className=" hidden md:block absolute  mt-[20px]  ml-[470px]  ">
                    {loading ? (
                      <>
                        <p className=" font-semibold text-green-500 cursor-pointer">
                          {serverpromocode_text ? (
                            <></>
                          ) : (
                            <svg
                              aria-hidden="true"
                              class="inline w-8 h-8 mr-2 text-green-500 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                          )}
                        </p>
                      </>
                    ) : (
                      <>
                        {promocode_applied_message ? (
                          <>
                            {" "}
                            <i class="fa-solid fa-check text-green-500 ml-[20px] text-[20px]"></i>
                          </>
                        ) : promocode_applied_invalid_message ? (
                          <>
                            {" "}
                            <i class="fa-solid fa-xmark"></i>
                          </>
                        ) : promocode_text.length !== null ? (
                          <>
                            <p
                              onClick={handleSubmit}
                              className=" font-semibold text-green-500 cursor-pointer"
                            >
                              Apply
                            </p>
                          </>
                        ) : (
                          <>
                            <p className=" font-semibold text-gray-500 cursor-pointer">
                              Apply
                            </p>
                          </>
                        )}
                      </>
                    )}
                  </div>
                  <div className=" mt-[20px] block md:hidden ">
                    {loading ? (
                      <>
                        <p className=" font-semibold text-green-500 cursor-pointer">
                          {serverpromocode_text ? (
                            <></>
                          ) : (
                            <svg
                              aria-hidden="true"
                              class="inline w-8 h-8 mr-2 text-green-500 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                          )}
                        </p>
                      </>
                    ) : (
                      <>
                        {promocode_applied_message ? (
                          <>
                            {" "}
                            <i class="fa-solid fa-check text-green-500 ml-[20px] text-[20px]"></i>
                          </>
                        ) : promocode_applied_invalid_message ? (
                          <>
                            {" "}
                            <i class="fa-solid fa-xmark"></i>
                          </>
                        ) : promocode_text.length !== null ? (
                          <>
                            <p
                              onClick={handleSubmit}
                              className=" font-semibold text-green-500 cursor-pointer"
                            >
                              Apply
                            </p>
                          </>
                        ) : (
                          <>
                            <p className=" font-semibold text-gray-500 cursor-pointer">
                              Apply
                            </p>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className=" flex justify-center items-center gap-[20px] p-5">
                {serverpromocode_text !== null ? (
                  <>
                    <button
                      onClick={PromoCodeCheckout}
                      className="py-2 w-[100px]  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9]  rounded-md text-white font-semibold text-[16px] hover:bg-[#086885] transition-all hover:transition-all hover:shadow-xl  "
                    >
                      Checkout
                    </button>
                  </>
                ) : (
                  <>
                    <button className="py-2 w-[100px]  bg-gray-500  rounded-md text-white font-semibold text-[16px]  transition-all hover:transition-all hover:shadow-xl  ">
                      Checkout
                    </button>
                  </>
                )}

                <button
                  onClick={() => setShowPromoCodeModal(false)}
                  className="py-2 w-[100px] bg-[#e24245] rounded-md text-white font-semibold text-[16px] hover:bg-[#b03030] transition-all hover:transition-all hover:shadow-xl  "
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
    </>
  );
};

export default PromoCodeModal;

import { Label } from "@syncfusion/ej2/circulargauge";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useStateContext } from "../../contexts/ContextProvider";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);





const options = {
  elements: {
    bar: {
      borderWidth: 1,
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
    xAxes: [
       { 
        
       }
    ],
  },
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Chart.js Horizontal Bar Chart",
    },
  },
};


const LineChart = () => {
  const { currentMode } = useStateContext();

  const [facebookData, setFacebookData] = useState([]);

  const [profileKey, setProfileKey] = useState(
    "48DV17T-4X840VT-M4AR09K-GHN7MTQ"
  );



  console.log(facebookData)

   const [facebook, setFacebook] = useState(["facebook"]);

   const array = ["1", "2", "3"]

    const API_KEY = "B4FA0G2-EPP4KT6-QTAZEHZ-V4QJ805";

    const [data, setData] = useState({
      labels: ["Sunday", "Monday", "Tuesday"],
      datasets: [
        {
          label: "Dataset 1",
          data: [1, 2, 3, 4, 5, 6],
          borderColor: "rgba(255,99,132)",
          backgroundColor: "rgba(255, 99,132, 0.5)",
        },
        {
          label: "Dataset 2",
          data: [1, 2, 3, 4, 5, 3],
          borderColor: "rgba(255,99,132)",
          backgroundColor: "rgba(255, 99,132, 0.5)",
        },
        {
          label: "Dataset 3",
          data: [1, 2, 3, 4, 5, 3],
          borderColor: "rgba(255,99,132)",
          backgroundColor: "rgba(255, 99,132, 0.5)",
        },
      ],
    });


    useEffect(() =>{
       const fetchData = () =>{
        const url = " https://app.ayrshare.com/api/analytics/social";
           const dataSet1 = [];
           fetch(url, {
             method: "POST",
             headers: {
               "Content-Type": "application/json",
               "Access-Control-Allow-Origin": "*",
               Authorization: `Bearer ${API_KEY}`,
             },
             body: JSON.stringify({
               profileKey: profileKey,
               platforms: ["facebook"],
             }),
           })
             .then((data) => {
               console.log("API DATA", data);
               const res = data.json()
               return res
             }).then(res =>{
              console.log("Data", res)
              // dataSet1.push(res)
              console.log("Data Set ", dataSet1);
               setData({
                 labels: ["Sunday", "Monday", "Tuesday"],
                 datasets: [
                   {
                     label: "Dataset 1",
                     data: [1, 2, 3, 4, 5, 3],
                     borderColor: "rgba(255,99,132)",
                     backgroundColor: "rgba(255, 99,132, 0.5)",
                   },
                   {
                     label: "Dataset 2",
                     data: [1, 2, 3, 4, 5, 3],
                     borderColor: "rgba(255,99,132)",
                     backgroundColor: "rgba(255, 99,132, 0.5)",
                   },
                 ],
               });
             })
             .catch((e) => {
               console.log("error", e);
             });
             console.log("arraData", dataSet1);
       }
        
      //  fetchData()
    },[])





    const FetchfaceBook = () => {
      const url = `https://app.ayrshare.com/api/analytics/social`;
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${API_KEY}`,
        },
        body: JSON.stringify({
          profileKey: profileKey,
          platforms: ["facebook"]
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          setFacebookData(response.facebook);
        });
    };

    



  return <Bar options={options} data={data}  />;
};

export default LineChart;

import React, { useContext, useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useSearchParams,
} from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { Navbar, Footer, Sidebar, ThemeSettings } from "./components";

import "./App.css";
import { useStateContext } from "./contexts/ContextProvider";
import SocialMedia from "./pages/SocialMedia";
import ParentComponent from "./pages/ParentComponent";
import MyProfile from "./pages/MyProfile";
import ActivityFeed from "./pages/ActivityFeed";
// import Login from "./pages/login/Login";
// import Signup from "./pages/signup/Signup";
import Settings from "./pages/settings/Settings";
import LinkSocialMediaAccounts from "./pages/linkaccounts/LinkSocialMediaAccounts";
import { useState } from "react";
import FacebookMatrics from "./pages/matrics/facebook/FacebookMatrics";
import Login from "./new-pages/Login/Login";
import Signup from "./new-pages/Signup/Signup";
import LandingPage from "./new-pages/pages/LandingPage/LandingPage";
import Dashboard from "./new-pages/pages/Dashboard/Dashboard";
import ContextWrapper from "./context/ContextWrapper";
import PostInfo from "./new-pages/pages/PostInfo";
import ConnectChannels from "./new-pages/pages/ConnectChannels/ConnectChannels";
import Agenda from "./new-pages/pages/Calender/Agenda";
import Published from "./new-pages/pages/Published/Published";
import ProfileDashboard from "./new-pages/pages/Profile/ProfileDashboard";
import ConnectedChannels from "./new-pages/pages/Profile/ConnectedChannels";
import SideMenuCommon from "./new-pages/pages/SideMenu/SideMenuCommon";
import Profile from "./new-pages/pages/Profile/Profile";
import Billing from "./new-pages/pages/Profile/Billing";
import PrivacyPolicy from "./new-pages/pages/PrivacyPolicy/PrivacyPolicy";
import ForgotPassword from "./new-pages/pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./new-pages/pages/ResetPassword/ResetPassword";

import PorfileEditPersonal from "./new-pages/pages/Profile/PorfileEditPersonal";
import PorfileEditAddress from "./new-pages/pages/Profile/PorfileEditAddress";
import Analytics from "./new-pages/pages/Analytics/Analytics";
import Subscription from "./new-pages/pages/Subscription/Subscription";
import Success from "./new-pages/pages/Payments/Success";
import Cancel from "./new-pages/pages/Payments/Cancel";
import Contact from "./new-pages/Contact/Contact";
import ComingSoon from "./new-pages/ComingSoon";
import GlobalContext from "./context/GlobalContext";
import ShareLocationModal from "./ShareLocationModal";
import Guides from "./new-pages/pages/Guides/Guides";
import PublicSubscription from "./new-pages/pages/Subscription/PublicSubscription";
import AdminDashboard from "./new-pages/Admin/AdminDashboard";
import Posts from "./new-pages/Admin/Posts";
import Users from "./new-pages/Admin/Users";
import Purchases from "./new-pages/Admin/Purchases";
import Admins from "./new-pages/Admin/Admins";
import Aboutus from "./new-pages/AboutUs/Aboutus";
import Blogs from "./new-pages/Blogs/Blogs";
import TermsAndServices from "./new-pages/pages/TermsAndServices/TermsAndServices";
import SignupNew from "./new-pages/Signup/SignupNew";

const App = () => {
  const {
    setCurrentColor,
    setCurrentMode,
    currentMode,
    activeMenu,
    currentColor,
    themeSettings,
    setThemeSettings,
  } = useStateContext();

  const { showShareLocation, setShowShareLocation } = useContext(GlobalContext);

  useEffect(() => {
    const currentThemeColor = localStorage.getItem("colorMode");
    const currentThemeMode = localStorage.getItem("themeMode");
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);

  const [dummyitems, setDummyItems] = useState([]);
  useEffect(() => {
    localStorage.getItem("analyst_token")
      ? JSON.parse(localStorage.getItem("analyst_token"))
      : localStorage.setItem("analyst_token", JSON.stringify(dummyitems));
  }, []);

  let token = JSON.parse(window.localStorage.getItem("analyst_token"));
  const analyst_clientid = token?.token;





  console.log("ANALYST CLIENT ID", token?.token);

  // if (analyst_clientid == undefined) {
  //   return (
  //     <BrowserRouter>
  //       <div className="flex relative dark:bg-main-dark-bg">
  //         <div
  //           className="fixed right-4 bottom-4"
  //           style={{ zIndex: "1000" }}
  //         ></div>

  //         <div
  //           className={
  //             activeMenu
  //               ? "dark:bg-main-dark-bg  bg-main-bg min-h-screen w-full  "
  //               : "bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 "
  //           }
  //         >
  //           <div>
  //             <Routes>
  //               {/* dashboard  */}

  //               <Route path="/" element={<Login />} />
  //               <Route path="/dashboard" element={<DashboardNew />} />
  //               <Route path="/" element={<DashboardNew />} />
  //               <Route path="/social" element={<SocialMedia />} />
  //               <Route path="/signup" element={<Signup />} />
  //               <Route path="/provide-page-access" element={<Settings />} />
  //               <Route
  //                 path="/link-accounts"
  //                 element={<LinkSocialMediaAccounts />}
  //               />
  //               <Route path="/facebook-matrics" element={<FacebookMatrics />} />
  //             </Routes>
  //           </div>
  //         </div>
  //       </div>
  //     </BrowserRouter>
  //   );
  // } else {
  //   return (
  //     <div className={currentMode === "Dark" ? "dark" : ""}>
  //       <BrowserRouter>
  //         <div className="flex relative dark:bg-main-dark-bg">
  //           <div className="fixed right-4 bottom-4" style={{ zIndex: "1000" }}>
  //             <TooltipComponent content="Settings" position="Top">
  //               <button
  //                 type="button"
  //                 onClick={() => setThemeSettings(true)}
  //                 style={{ background: currentColor, borderRadius: "50%" }}
  //                 className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
  //               >
  //                 <FiSettings />
  //               </button>
  //             </TooltipComponent>
  //           </div>
  //           {activeMenu ? (
  //             <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white ">
  //               <Sidebar />
  //             </div>
  //           ) : (
  //             <div className="w-0 dark:bg-secondary-dark-bg">
  //               <Sidebar />
  //             </div>
  //           )}
  //           <div
  //             className={
  //               activeMenu
  //                 ? "dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-72 w-full  "
  //                 : "bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 "
  //             }
  //           >
  //             <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
  //               <Navbar />
  //             </div>
  //             <div>
  //               {themeSettings && <ThemeSettings />}

  //               <Routes>
  //                 <Route path="/dashboard" element={<DashboardNew />} />
  //                 <Route path="/" element={<DashboardNew />} />
  //                 <Route path="/activity-feed" element={<ActivityFeed />} />
  //                 <Route
  //                   path="/link-accounts"
  //                   element={<LinkSocialMediaAccounts />}
  //                 />
  //                 <Route path="/color-picker" element={<ColorPicker />} />
  //                 <Route path="/my-profile" element={<MyProfile />} />
  //                 <Route path="/provide-page-access" element={<Settings />} />
  //                 <Route
  //                   path="/facebook-matrics"
  //                   element={<FacebookMatrics />}
  //                 />
  //               </Routes>
  //             </div>
  //             <Footer />
  //           </div>
  //         </div>
  //       </BrowserRouter>
  //     </div>
  //   );
  // }

  return (
    <>
      <ContextWrapper>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<LandingPage />} />
            <Route exact path="/login" element={<Login />} />
            {/* <Route exact path="/signup" element={<Signup />} /> */}
            <Route exact path="/signup" element={<SignupNew />} />
            <Route exact path="/forgot-password" element={<ForgotPassword />} />
            <Route
              exact
              path="/reset-password/:id"
              element={<ResetPassword />}
            />
            <Route
              exact
              path="/dashboard/clientId=:clientid"
              element={<Dashboard />}
            />
            <Route
              exact
              path="/channels/connect"
              element={<ConnectChannels />}
            />
            <Route exact path="/agenda/:id" element={<Agenda />} />
            <Route exact path="/published" element={<Published />} />
            <Route exact path="/profile-details" element={<Profile />} />
            <Route
              exact
              path="/profile-details/:id/personal-info"
              element={<PorfileEditPersonal />}
            />
            <Route
              exact
              path="/profile-details/:id/address"
              element={<PorfileEditAddress />}
            />
            <Route exact path="/billing-details" element={<Billing />} />
            <Route
              exact
              path="/connected-channels"
              element={<ConnectedChannels />}
            />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
              exact
              path="/terms-and-services"
              element={<TermsAndServices />}
            />
            <Route exact path="/analytics" element={<Analytics />} />
            <Route exact path="/subscription" element={<Subscription />} />
            <Route exact path="/success" element={<Success />} />
            <Route exact path="/cancel" element={<Cancel />} />
            <Route exact path="/contactus" element={<Contact />} />
            <Route exact path="/guide" element={<Guides />} />
            <Route exact path="/pricing" element={<PublicSubscription />} />
            <Route exact path="/admin-dashboard" element={<AdminDashboard />} />
            <Route exact path="/posts" element={<Posts />} />
            <Route exact path="/users" element={<Users />} />
            <Route exact path="/purchases" element={<Purchases />} />
            <Route exact path="/admins" element={<Admins />} />
            <Route exact path="/about-us" element={<Aboutus />} />
            <Route exact path="/blogs" element={<Blogs />} />
            <Route exact path="*" element={<ComingSoon />} />
          </Routes>
        </BrowserRouter>
      </ContextWrapper>
    </>
  );
};

export default App;

import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { uri } from "../../https";
import axios from "axios";

const Navbar = () => {
  const [navbar_controller, setNavbarController] = useState(false);

  const [userProfileData, setUserProfileData] = useState([]);
  const GetUserProfile = async () => {
    const url = `${uri}api/me`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        setUserProfileData(response?.data?.user_profile);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    GetUserProfile();
  }, []);
  return (
    <>
      <div className=" w-full bg-white py-2 px-[60px] flex flex-row justify-around gap-[200px] items-center ">
        <div className=" flex flex-row justify-center md:justify-start items-center ">
          <div className=" mt-[10px]">
            <NavLink to="/">
              <img
                src="https://res.cloudinary.com/dv5jjlsd7/image/upload/v1688033750/analyst_logo_efwgjv.png"
                className=" h-[100px] md:h-[80px] object-contain cursor-pointer"
              />
            </NavLink>
          </div>
          <div className=" ml-[170px] hidden md:block">
            <ul className=" flex flex-row justify-center items-center gap-[30px] font-semibold text-gray-700 cursor-pointer ">
              <NavLink
                exact={true}
                activeClassName="active"
                to="/"
                end
                className="hover:text-[#5aa7bc] px-4 py-3 flex justify-center items-center  cursor-pointer rounded-md transition-all hover:transition-all"
              >
                <a>Home</a>
              </NavLink>
              <NavLink
                activeClassName="active"
                to="/pricing"
                className="hover:text-[#5aa7bc] px-4 py-3 flex justify-center items-center  cursor-pointer rounded-md transition-all hover:transition-all"
              >
                <a>Pricing</a>
              </NavLink>
              <NavLink
                activeClassName="active"
                to="/about-us"
                className="hover:text-[#5aa7bc] px-4 py-3 flex justify-center items-center  cursor-pointer rounded-md transition-all hover:transition-all"
              >
                <a>About Us</a>
              </NavLink>
              <NavLink
                activeClassName="active"
                to="/guide"
                className="hover:text-[#5aa7bc] px-4 py-3 flex justify-center items-center  cursor-pointer rounded-md transition-all hover:transition-all"
              >
                <a>Guide</a>
              </NavLink>
              <NavLink
                activeClassName="active"
                to="/contactus"
                className="hover:text-[#5aa7bc] px-4 py-3 flex justify-center items-center  cursor-pointer rounded-md transition-all hover:transition-all"
              >
                <a>Contact Us</a>
              </NavLink>
              <NavLink
                activeClassName="active"
                to="/blogs"
                className="hover:text-[#5aa7bc] px-4 py-3 flex justify-center items-center  cursor-pointer rounded-md transition-all hover:transition-all"
              >
                <a>Blogs</a>
              </NavLink>
            </ul>
          </div>
        </div>
        <div>
          <button
            onClick={() => setNavbarController(!navbar_controller)}
            className=" block md:hidden"
          >
            <i
              class={`${
                !navbar_controller ? "fa-solid fa-bars" : "fa-solid fa-x"
              } text-[24px] text-gray-700 duration-300`}
            ></i>
          </button>
          {userProfileData?.client_id !== undefined ? (
            <>
              <Link to={`/dashboard/clientId=${userProfileData?.client_id}`}>
                <button className=" hidden md:block w-[170px] py-3  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all md:hover:scale-90">
                  Dashboard
                </button>
              </Link>
            </>
          ) : (
            <>
              <Link to="/signup">
                <button className=" hidden md:block w-[170px] py-3  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all md:hover:scale-90">
                  Get Started
                </button>
              </Link>
            </>
          )}
        </div>
      </div>
      {navbar_controller && (
        <>
          <div className=" w-full bg-white px-3 p-3 mobile_nav absolute duration-300 block md:hidden">
            <ul className=" flex flex-col justify-start items-start gap-[20px]  md:gap-[50px] font-semibold text-gray-700  p-3 ml-[20px]">
              <NavLink
                exact={true}
                activeClassName="active"
                to="/"
                end
                className="hover:text-[#5aa7bc] transition-all hover:transition-all border-b  border-b-gray-600 p-3 w-full flex-1 cursor-pointer "
              >
                <a>Home</a>
              </NavLink>

              <NavLink
                activeClassName="pricing"
                to="/pricing"
                className="hover:text-[#5aa7bc] transition-all hover:transition-all border-b  border-b-gray-600 p-3 w-full flex-1 cursor-pointer"
              >
                <a>Pricing</a>
              </NavLink>
              <NavLink
                activeClassName="active"
                to="/about-us"
                className="hover:text-[#5aa7bc] transition-all hover:transition-all border-b  border-b-gray-600 p-3 w-full flex-1 cursor-pointer"
              >
                <a>About Us</a>
              </NavLink>
              <NavLink
                activeClassName="active"
                to="/guide"
                className="hover:text-[#5aa7bc] transition-all hover:transition-all border-b  border-b-gray-600 p-3 w-full flex-1 cursor-pointer"
              >
                <a>Guide</a>
              </NavLink>
              <NavLink
                activeClassName="active"
                to="/contactus"
                className="hover:text-[#5aa7bc] transition-all hover:transition-all border-b  border-b-gray-600 p-3 w-full flex-1 cursor-pointer"
              >
                <a>Contact Us</a>
              </NavLink>
              <NavLink
                activeClassName="active"
                to="/blogs"
                className="hover:text-[#5aa7bc] transition-all hover:transition-all border-b  border-b-gray-600 p-3 w-full flex-1 cursor-pointer"
              >
                <a>Blogs</a>
              </NavLink>
            </ul>
            <div className=" flex justify-center items-center">
              {userProfileData?.client_id !== undefined ? (
                <>
                  <Link
                    to={`/dashboard/clientId=${userProfileData?.client_id}`}
                  >
                    <button className="  mt-[20px] ml-[20px]  w-[170px] py-3  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all md:hover:scale-90 cursor-pointer">
                      Dashboard
                    </button>
                  </Link>
                </>
              ) : (
                <>
                  <Link to="signup">
                    <button className=" mt-[20px] ml-[20px]  w-[170px] py-3  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all md:hover:scale-90 cursor-pointer">
                      Get Started
                    </button>
                  </Link>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Navbar;

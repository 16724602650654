import React, { useContext, useState } from "react";
import { ToastContainer } from "react-toastify";
import GlobalContext from "../../../context/GlobalContext";
import axios from "axios";
import { uri } from "../../../https";
import PromoCodeModal from "./PromoCodeModal";

const SubscribeModal = ({ subscription_data }) => {
  const [loading, setLoading] = useState(false);
  const {
    setShowEventModal,
    daysSelected,
    dispatchCalEvent,
    selectedEvent,
    setShowShareLocation,
    showShareLocation,
    setShowSubscribeModal,
    showSubscribeModal,
    setShowPromoCodeModal,
    showPromoCodeModal,
  } = useContext(GlobalContext);

  const handleSubmit = () => {
    console.log("Submited Plan", subscription_data);
    setLoading(true);
    const formData = new FormData();
    formData.append("sub_plan_id", subscription_data.stripe_price_id);
    formData.append("sub_amount", subscription_data.total_price);
    formData.append("sub_name", subscription_data.package_name);
    formData.append("sub_tenure", subscription_data.tenure);
    const url = `${uri}api/checkout`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;

    let config = {
      headers: {
        Authorization: "Bearer " + token,
        accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    };
    axios.post(url, formData, config).then(
      (response) => {
        console.log(response.data);
        setLoading(false);
        window.location.replace(response.data.stripe_url);
      },
      (error) => {
        console.log(error.response.data);
        setLoading(false);
      }
    );
  };

  return (
    <>
      <div className=" h-screen w-full fixed left-0 bg-black/50 top-[0px] flex justify-center items-center backdrop-blur-sm  ">
        <div className=" flex flex-row gap-2 m-[20px] md:m-[80px] ">
          <div className=" bg-white rounded-md shadow-lg  w-full md:w-[600px] ">
            <header className="bg-[#f5f5f5]  px-4 py-2 flex justify-between items-center rounded-md  shadow-md">
              <h1 className=" font-semibold text-[16px] text-gray-800">
                Subscription Modal
              </h1>
              <button onClick={() => setShowSubscribeModal(false)}>
                <span className="material-icons-outlined text-gray-400">
                  close
                </span>
              </button>
            </header>
            <div className=" p-3">
              <div className=" w-full  flex flex-col justify-center items-center bg-white  p-5 ">
                <div className=" flex justify-center items-center">
                  <img
                    src="https://res.cloudinary.com/dv5jjlsd7/image/upload/v1694588721/SP%20ANALYST/Promocode_devzzx.png"
                    className="  h-[250px] object-contain"
                  />
                </div>
                <p className=" text-[16px] text-center md:text-left md:text-[18px] font-semibold">
                  Do You Have A Valid Promo Code ?
                </p>
              </div>
              <div className=" flex justify-center items-center gap-[20px] p-5">
                <button
                  onClick={() => {
                    setShowPromoCodeModal(true);
                    setShowSubscribeModal(false);
                  }}
                  className="py-2 w-[100px]  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] rounded-md text-white font-semibold text-[16px] hover:bg-[#086885] transition-all hover:transition-all hover:shadow-xl  "
                >
                  Yes
                </button>
                {loading ? (
                  <>
                    <button className="py-2 w-[200px] bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] rounded-md text-white font-semibold text-[16px] hover:bg-[#b03030] transition-all hover:transition-all hover:shadow-xl  ">
                      <svg
                        aria-hidden="true"
                        class="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      onClick={handleSubmit}
                      className="py-2 w-[100px] bg-gradient-to-r from-[#e93939] via-[#bc5a5a] to-[#d85c5e] rounded-md text-white font-semibold text-[16px] hover:bg-[#b03030] transition-all hover:transition-all hover:shadow-xl  "
                    >
                      No
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />

        <ToastContainer />
      </div>
    </>
  );
};

export default SubscribeModal;

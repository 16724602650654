import React, { useState } from "react";
import {
  Chart as ChartJs,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  RadialLinearScale,
} from "chart.js";
import { Line, Radar } from "react-chartjs-2";

ChartJs.register(
  CategoryScale,
  LinearScale,
  LineElement,
  RadialLinearScale,
  PointElement
);
const ChannelOverView = ({ dataone, datatwo, datathree, datafour }) => {
  const [chart, setChart] = useState([]);

  var data = {
    labels: ["followers", "total likes", "impressions"],
    datasets: [
      {
        label: [` ${dataone} Channel Overview`],
        data: [datatwo, datathree, datafour],
        backgroundColor: [
          "rgba(1, 94, 117, 1)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(1, 94, 117, 1)",
          "rgba(57, 198, 233, 1)",
          "rgba(57, 198, 233, 1)",
          "rgba(57, 198, 233, 1)",
          "rgba(57, 198, 233, 1)",
          "rgba(57, 198, 233, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  var options = {
    maintainAspectRatio: false,
    scales: {},
    legend: {
      labels: {
        fontSize: 25,
      },
    },
  };
  return (
    <>
      <Line data={data} options={options} height={50} width={100} />
    </>
  );
};

export default ChannelOverView;

import { Player } from '@lottiefiles/react-lottie-player';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { uri } from '../../../https';

const Success = () => {

   const [userProfileData, setUserProfileData] = useState([]);
   const GetUserProfile = async () => {
     const url = `${uri}api/me`;
     let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
     const token = tokendata?.token;
     let config = {
       headers: {
         Authorization: "Bearer " + token,
         "content-type": "application/vnd.api+json",
         accept: "application/vnd.api+json",
       },
     };
     await axios.get(url, config).then(
       (response) => {
         setUserProfileData(response?.data?.user_profile);
       },
       (error) => {
         console.log(error);
       }
     );
   };

   useEffect(() => {
     GetUserProfile();
   }, []);

  //  const [userProfileData, setUserProfileData] = useState([]);
   const SendMail = async () => {
     const url = `${uri}sendmail`;
     let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
     const token = tokendata?.token;
     let config = {
       headers: {
         Authorization: "Bearer " + token,
         "content-type": "application/vnd.api+json",
         accept: "application/vnd.api+json",
       },
     };
     await axios.get(url, config).then(
       (response) => {
        //  setUserProfileData(response?.data?.user_profile);
       },
       (error) => {
         console.log(error);
       }
     );
   };

   useEffect(() => {
     SendMail();
   }, []);
  return (
    <>
      <div>
        <Player
          src="https://lottie.host/38c681f0-27a5-4f0f-8637-b814b29fa5d2/NrJlnYoLhP.json"
          className="player"
          loop
          autoplay
          className="h-[500px]"
        />
        <div className=" flex justify-center items-center">
          <Link to={`/dashboard/clientId=${userProfileData?.client_id}`}>
            <button className="py-2 w-full p-3  bg-[#0884AA] rounded-md text-white font-semibold text-[16px] hover:bg-[#086885] transition-all hover:transition-all hover:shadow-xl  ">
             Back To Dashboard
            </button>
          </Link>
        </div>
      </div>
    </>
  );
}

export default Success
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import GlobalContext from "../../../context/GlobalContext";
import "react-tooltip/dist/react-tooltip.css";

import { Tooltip as ReactTooltip } from "react-tooltip";
import PostInfo from "../PostInfo";
import axios from "axios";
import moment from "moment";
import { uri } from "../../../https";
import BackwardDateWarningModal from "../Events/BackwardDateWarningModal";
import EditEventsModal from "../Events/EditEventsModal";
import ReactPlayer from "react-player";

const Day = ({ day, rowIdx }) => {
  const {
    setDaysSelected,
    setShowEventModal,
    savedEvents,
    setSelectedEvent,
    setShowSelectedEventModal,
    daysSelected,
    dispatchCalEvent,
    selectedEvent,
    setIsBackwardDateSelected,
    isBackwardDateSelected,
    editcontentModal,
    setEditContentModal,
    setLoading,
    loading,
  } = useContext(GlobalContext);

  // console.log("selected event", selectedEvent);
  const labelsClasses = ["blue"];
  const [title, setTitle] = useState(selectedEvent ? selectedEvent.title : "");
  // const [title, setTitle] = useState("");
  const [description, setDescription] = useState(
    selectedEvent ? selectedEvent.description : ""
  );

  // const [description, setDescription] = useState("");
  const [selectedLabel, setSelectedLabel] = useState(
    selectedEvent
      ? labelsClasses.find((lbl) => lbl === selectedEvent.label)
      : labelsClasses[0]
  );

  const getCurrentDayClass = () => {
    return day?.format("DD-MM-YY") === dayjs().format("DD-MM-YY")
      ? "bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] cursor-pointer text-white rounded-md w-7"
      : "";
  };

  const [dayEvents, setDayEvents] = useState([]);

  // console.log("");

  useEffect(() => {
    const events = savedEvents?.filter(
      (evt) =>
        dayjs(evt?.unix_datestamp).format("DD MM YY") == day?.format("DD MM YY")
    );
    // console.log("events", events);
    setDayEvents(events);
  }, [savedEvents, day]);

  // console.log("Day selected", daysSelected.format("DD-MM-YYYY"));

  const handleSubmit = (e) => {
    e.preventDefault();
    const calendarEvent = {
      title,
      description,
      label: selectedLabel,
      day: daysSelected.valueOf(),
      id: Date.now(),
    };
    if (selectedEvent) {
      dispatchCalEvent({ type: "update", payload: calendarEvent });
    } else {
      console.log("Calender event", calendarEvent);
      dispatchCalEvent({ type: "push", payload: calendarEvent });
    }

    setShowSelectedEventModal(false);
  };

  const [displayInfo, SetDisplayInfo] = useState(false);

  const deletePost = (selectedEvent) => {
    console.log(selectedEvent);
    const url = `${uri}api/delete_users_posts`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;

    const data = {
      post_id: selectedEvent.post_id,
    };

    let config = {
      headers: {
        Authorization: "Bearer " + token,
        accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    };
    axios.post(url, data, config).then(
      (response) => {
        console.log(response.data);
      },
      (error) => {
        console.log(error.response.data);
      }
    );
  };

  // const deletePost = (selectedEvent) => {
  //   console.log("Post deleted",selectedEvent)
  //   const url = `${uri}api/delete_users_posts/${selectedEvent.id}`;
  //   let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
  //   const token = tokendata?.token;

  //   let config = {
  //     headers: {
  //       Authorization: "Bearer " + token,
  //       accept: "application/json",
  //       "Content-Type": "multipart/form-data",
  //     },
  //   };
  //   axios.post(url, config).then(
  //     (response) => {
  //       console.log(response.data);
  //     },
  //     (error) => {
  //       console.log(error.response.data);
  //     }
  //   );
  //   console.log("Event deleted", selectedEvent);
  // };

  // const [publishedPosts, setPublishedPosts] = useState([]);
  // const GetPublishedPosts = () => {
  //   const url = `${uri}api/get_published_posts`;
  //   let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
  //   const token = tokendata?.token;
  //   let config = {
  //     headers: {
  //       Authorization: "Bearer " + token,
  //       "content-type": "application/vnd.api+json",
  //       accept: "application/vnd.api+json",
  //     },
  //   };
  //   axios.get(url, config).then(
  //     (response) => {
  //       setPublishedPosts(response?.data?.published_posts);
  //     },
  //     (error) => {
  //       console.log(error);
  //     }
  //   );
  // };

  // useEffect(() => {
  //   GetPublishedPosts();
  // }, [userProfileData]);

  var today, dd, mm, yyy;
  today = new Date();
  dd = today.getDate() + 5;
  mm = today.getMonth() + 1;
  yyy = today.getFullYear();

  // if (daysSelected.format("DD-MM-YYYY") < dayjs(today).format("DD-MM-YYYY")) {
  //   setIsBackwardDateSelected(true);
  //   console.log("Date selected is backwards");
  // }

  var curr = new Date(); // get current date
  var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
  var last = first + 6; // last day is the first day + 6

  var firstday = new Date(curr.setDate(first)).toUTCString();
  var lastday = new Date(curr.setDate(last)).toUTCString();
  // console.log("First day of the week", dayjs(firstday).format("DD"));
  // console.log("Last day of the week", dayjs(lastday).format("DD"));

  const [updatetitle, updateTitle] = useState("");

  const newtitle = selectedEvent?.title;

  const UpdatePost = () => {
    const calendarEvent = {
      title: updatetitle,
    };

    console.log("Calender event", calendarEvent);
    dispatchCalEvent({ type: "update", payload: calendarEvent });

    // setShowEventModal(false);
  };

  const updatePost = () => {
    console.log("Update post event");
    const calendarEvent = {
      title: "New Title qdqd",
      description: selectedEvent.description,
      label: selectedEvent.label,
      day: selectedEvent.day,
      id: selectedEvent.id,
    };

    if (selectedEvent) {
      dispatchCalEvent({ type: "update", payload: calendarEvent });
    }
  };

  // console.log("Selected event", selectedEvent);

  return (
    <>
      <div
        className={` md:border  md:border-gray-200 flex flex-col  w-full  overflow-hidden `}
      >
        {editcontentModal && <EditEventsModal />}
        <header className=" flex flex-col items-center">
          <p className="text-sm mt-1  capitalize ">{day?.format("ddd")}</p>

          <p
            className={`text-sm p-1  my-1 text-center ${getCurrentDayClass()}`}
          >
            {" "}
            {day?.format("DD")}
          </p>
        </header>
        <div className=" flex-1 cursor-pointer">
          {dayEvents.map((item, index) => {
            return (
              <>
                <div
                  className=" md:flex transition-all hover:delay-1000 hidden  "
                  onMouseEnter={() => {
                    setSelectedEvent(item);
                  }}
                  onMouseLeave={() => {
                    SetDisplayInfo(false);
                  }}
                >
                  {/* <TooltipComponent
                  content={selectedEvent?.title}
                  position="Left"
                ></TooltipComponent> */}
                  {displayInfo && (
                    <div className=" ">
                      {selectedEvent.id === item.id && (
                        <div className="   absolute -ml-[370px] -mt-[100px] bg-white w-[420px]  shadow-lg rounded-md border-1 border-gray-300 more-info-modal">
                          <div className=" flex justify-start items-center  gap-3 m-4 border-b-1">
                            <div>
                              <div className=" flex justify-center items-center text-white text-[18px] font-semibold h-[40px] w-[40px] bg-[#0180a1] rounded-full cursor-pointer ">
                                {selectedEvent?.page_name?.slice(0, 1)}
                              </div>
                            </div>
                            <div>
                              <h1 className=" text-[15px] text-gray-700 font-semibold">
                                {selectedEvent?.page_name}
                              </h1>
                            </div>
                            <div className=" flex justify-start items-center">
                              <div className=" flex justify-start items-center gap-3">
                                <i className="fa-regular fa-clock text-gray-700"></i>
                                <p className=" text-gray-700">
                                  {selectedEvent?.time_to_post}
                                </p>
                              </div>
                            </div>
                            {item.post_status == "success" ? (
                              <>
                                <div className=" flex justify-start items-center">
                                  <i class="fa-regular fa-trash-can ml-3 text-gray-500 "></i>
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  onClick={() => {
                                    deletePost(selectedEvent);
                                    dispatchCalEvent({
                                      type: "delete",
                                      payload: selectedEvent,
                                    });
                                    SetDisplayInfo(false);
                                  }}
                                  className=" flex justify-start items-center"
                                >
                                  <i class="fa-regular fa-trash-can ml-3 text-gray-700 hover:text-red-500"></i>
                                </div>
                              </>
                            )}
                            {item.post_status == "success" ? (
                              <>
                                <div
                                
                                  className=" flex justify-start items-center"
                                >
                                  <i class="fa-solid fa-pen  text-gray-500"></i>
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  onClick={() => {
                                    // updatePost(selectedEvent);
                                    // dispatchCalEvent({
                                    //   type: "update",
                                    //   payload: selectedEvent.title,
                                    // });
                                    SetDisplayInfo(false);
                                    setEditContentModal(true);
                                  }}
                                  className=" flex justify-start items-center"
                                >
                                  <i class="fa-solid fa-pen  text-gray-700 hover:text-yellow-500"></i>
                                </div>
                              </>
                            )}
                          </div>
                          <div className=" flex flex-row justify-between items-center">
                            <div className=" m-4 text-gray-700">
                              {/* {selectedEvent?.title} */}
                              {selectedEvent?.post_message}
                            </div>
                            <div className=" m-4 text-gray-700">
                              <img
                                src={selectedEvent?.media}
                                className=" h-[40px] rounded-md"
                              />
                            </div>
                          </div>
                          <div className=" border-t-1 m-3 p-4">
                            <div className=" flex justify-between items-center">
                              <div className=" flex justify-start items-center gap-3">
                                <p className=" text-gray-700">
                                  {dayjs(selectedEvent?.unix_datestamp).format(
                                    "DD MMMM YYYY"
                                  )}
                                </p>
                              </div>
                              {/* <div className=" flex justify-center items-center m-3">
                              <button className=" px-4 py-1 bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] transition-all hover:transition-all text-white rounded-md">
                                Details
                              </button>
                            </div> */}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  <div
                    key={index}
                    onMouseEnter={() => SetDisplayInfo(true)}
                    onClick={() => {
                      setSelectedEvent(item);
                      // setShowSelectedEventModal(true);
                    }}
                    className={`bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9]  p-2 md:p-3 text-white text-sm rounded mb-1 truncate  w-full  `}
                  >
                    <div className=" flex justify-start items-center">
                      {/* <p className=" w-[160px] truncate">{item.title}</p> */}
                      <p className=" w-[120px] md:w-[160px] truncate">
                        {item.post_message}
                      </p>
                      {/* {publishedPosts?.find(
                      (x) => x?.post_id == selectedEvent?.id
                    ) ? (
                      <span className=" w-full py-1 bg-green-500 flex justify-center items-center rounded-md">
                        success
                      </span>
                    ) : (
                      <span className=" w-full py-1 bg-red-500 flex justify-center items-center rounded-md">
                        scheduled
                      </span>
                    )} */}

                      {item.post_status == "success" ? (
                        <span className=" w-[10px] h-[10px] md:w-[10px] md:h-[10px] py-1 bg-green-700 flex justify-center items-center rounded-full"></span>
                      ) : (
                        <span className=" w-[10px] h-[10px] md:w-[10px] md:h-[10px] md:py-1 bg-red-500 flex justify-center items-center rounded-full"></span>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className=" flex md:hidden transition-all hover:delay-1000    "
                  onMouseEnter={() => {
                    setSelectedEvent(item);
                  }}
                >
                  {/* <TooltipComponent
                  content={selectedEvent?.title}
                  position="Left"
                ></TooltipComponent> */}
                  {displayInfo && (
                    <div className=" ">
                      {selectedEvent.id === item.id && (
                        <div className=" h-screen w-full fixed left-0 bg-black/25 top-[0px] flex justify-center items-center shadow-md pinterestmodal">
                          <div className=" flex flex-row gap-2">
                            <div className=" bg-white rounded-md  w-full  md:w-[600px] ">
                              <header className="bg-[#f5f5f5]  px-4 py-2 flex justify-between items-center rounded-md  ">
                                <span>
                                  <h1 className=" text-gray-700 font-semibold ">
                                    Events Info
                                  </h1>
                                </span>
                                <button onClick={() => SetDisplayInfo(false)}>
                                  <span className="material-icons-outlined text-gray-400">
                                    close
                                  </span>
                                </button>
                              </header>

                              <div className="  w-full m-3 p-5">
                                <div className=" flex flex-row items-center gap-[20px]">
                                  <div className=" flex justify-center items-center text-white text-[18px] font-semibold h-[40px] w-[40px] bg-[#0180a1] rounded-full cursor-pointer capitalize ">
                                    {selectedEvent?.page_name?.slice(0, 1)}
                                  </div>
                                  <h1 className=" text-[15px] text-gray-700 font-semibold capitalize">
                                    {selectedEvent?.page_name}
                                  </h1>
                                </div>
                                <div className=" flex flex-row gap-[5px] items-center">
                                  <div className=" m-4 text-gray-700">
                                    {/* {selectedEvent?.title} */}
                                    <p className=" font-medium">
                                      {" "}
                                      {selectedEvent?.post_message}
                                    </p>
                                  </div>
                                  <div className=" m-4 text-gray-700">
                                    <img
                                      src={selectedEvent?.media}
                                      className=" h-[60px] rounded-md"
                                    />
                                    <ReactPlayer
                                      width={350}
                                      height={250}
                                      pip="true"
                                      controls="true"
                                      url={selectedEvent?.video_media}
                                      config={{
                                        file: {
                                          attributes: {
                                            controlsList: "nodownload",
                                          },
                                        },
                                      }}
                                    />
                                  </div>
                                </div>
                                <div>
                                  <p className=" text-gray-700">
                                    {dayjs(
                                      selectedEvent?.unix_datestamp
                                    ).format("DD MMMM YYYY")}
                                  </p>
                                  <p className=" text-gray-700 mt-1">
                                    {selectedEvent?.time_to_post}
                                  </p>
                                </div>
                              </div>
                              {item.post_status == "success" ? (
                                <>
                                  <div className=" p-3 flex justify-center gap-[10px] items-center">
                                    <button className=" bg-gradient-to-r from-[#e93939] via-[#bc5a5a] to-[#d85c5e]  font-semibold hover:bg-[#2491af] transition-all hover:transition-all hover:scale-90 w-[150px] py-2 rounded text-white">
                                      <i class="fa-solid fa-trash-can text-white"></i>{" "}
                                      Delete
                                    </button>
                                    <button className=" bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9]  font-semibold hover:bg-[#2491af] transition-all hover:transition-all hover:scale-90 w-[150px] py-2 rounded text-white">
                                      <i class="fa-solid fa-pen text-white"></i>{" "}
                                      Edit
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className=" p-3 flex justify-center gap-[10px] items-center">
                                    <button
                                      onClick={() => {
                                        deletePost(selectedEvent);
                                        dispatchCalEvent({
                                          type: "delete",
                                          payload: selectedEvent,
                                        });
                                        SetDisplayInfo(false);
                                      }}
                                      className=" bg-gradient-to-r from-[#e93939] via-[#bc5a5a] to-[#d85c5e]  font-semibold hover:bg-[#2491af] transition-all hover:transition-all hover:scale-90 w-[150px] py-2 rounded text-white"
                                    >
                                      <i class="fa-solid fa-trash-can text-white"></i>{" "}
                                      Delete
                                    </button>
                                    <button
                                      onClick={() => {
                                        // updatePost(selectedEvent);
                                        // dispatchCalEvent({
                                        //   type: "update",
                                        //   payload: selectedEvent.title,
                                        // });
                                        SetDisplayInfo(false);
                                        setEditContentModal(true);
                                      }}
                                      className=" bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9]  font-semibold hover:bg-[#2491af] transition-all hover:transition-all hover:scale-90 w-[150px] py-2 rounded text-white"
                                    >
                                      <i class="fa-solid fa-pen text-white"></i>{" "}
                                      Edit
                                    </button>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  <div
                    key={index}
                    onMouseEnter={() => SetDisplayInfo(true)}
                    onClick={() => {
                      setSelectedEvent(item);
                      // setShowSelectedEventModal(true);
                    }}
                    className={` md:flex hidden  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9]  p-2 md:p-3 text-white text-sm rounded mb-1 truncate  w-full  `}
                  >
                    <div className=" flex justify-start items-center">
                      {/* <p className=" w-[160px] truncate">{item.title}</p> */}
                      <p className=" w-[120px] md:w-[160px] truncate">
                        {item.post_message}
                      </p>
                      {/* {publishedPosts?.find(
                      (x) => x?.post_id == selectedEvent?.id
                    ) ? (
                      <span className=" w-full py-1 bg-green-500 flex justify-center items-center rounded-md">
                        success
                      </span>
                    ) : (
                      <span className=" w-full py-1 bg-red-500 flex justify-center items-center rounded-md">
                        scheduled
                      </span>
                    )} */}

                      {item.post_status == "success" ? (
                        <span className=" w-[10px] h-[10px] md:w-[10px] md:h-[10px] py-1 bg-green-700 flex justify-center items-center rounded-full"></span>
                      ) : (
                        <span className=" w-[10px] h-[10px] md:w-[10px] md:h-[10px] md:py-1 bg-red-500 flex justify-center items-center rounded-full"></span>
                      )}
                    </div>
                  </div>
                  <div
                    key={index}
                    onClick={() => {
                      SetDisplayInfo(true);
                      setSelectedEvent(item);
                      // setShowSelectedEventModal(true);
                    }}
                    className={` md:hidden flex  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9]  p-2 md:p-3 text-white text-sm rounded mb-1 truncate  w-full  `}
                  >
                    <div className=" flex justify-start items-center">
                      {/* <p className=" w-[160px] truncate">{item.title}</p> */}
                      <p className=" w-[120px] md:w-[160px] truncate">
                        {item.post_message}
                      </p>
                      {/* {publishedPosts?.find(
                      (x) => x?.post_id == selectedEvent?.id
                    ) ? (
                      <span className=" w-full py-1 bg-green-500 flex justify-center items-center rounded-md">
                        success
                      </span>
                    ) : (
                      <span className=" w-full py-1 bg-red-500 flex justify-center items-center rounded-md">
                        scheduled
                      </span>
                    )} */}

                      {item.post_status == "success" ? (
                        <span className=" w-[10px] h-[10px] md:w-[10px] md:h-[10px] py-1 bg-green-700 flex justify-center items-center rounded-full"></span>
                      ) : (
                        <span className=" w-[10px] h-[10px] md:w-[10px] md:h-[10px] md:py-1 bg-red-500 flex justify-center items-center rounded-full"></span>
                      )}
                    </div>
                  </div>
                </div>
              </>
            );
          })}
          <div className=" flex justify-center items-center  border-none text-gray-200 hover:text-[#0180a1]  transition-all hover:transition-all md:m-3 rounded-full  ">
            <button
              onClick={() => {
                setDaysSelected(day);
                setShowEventModal(true);
              }}
              className=" md:px-6 md:py-1  "
            >
              <i className="fa-solid fa-plus "></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Day;

import React from 'react'
import { NavLink } from 'react-router-dom';

const AdminSidebar = () => {
  return (
    <div className=" p-[5px] border border-r-gray-400 hidden md:block h-screen">
      <ul className=" p-[5px] mt-[50px] ">
        <NavLink
          to="/admin-dashboard"
          className=" flex items-center p-[14px]  rounded-md  cursor-pointer gap-[10px]"
        >
          <i class="fa-regular fa-calendar "></i>{" "}
          <a className="  font-medium">Dashboard</a>
        </NavLink>
        <NavLink
          to="/posts"
          className=" flex items-center p-[14px]  rounded-md mt-[20px]  cursor-pointer gap-[10px]"
        >
          <i class="fa-regular fa-calendar "></i>{" "}
          <a className="  font-medium">Posts</a>
        </NavLink>
        <NavLink
          to="/users"
          className=" flex items-center gap-[10px] p-[14px] mt-[20px] cursor-pointer"
        >
          <i class="fa-solid fa-users"></i> <a className="font-medium">Users</a>
        </NavLink>
        <NavLink
          to="/admins"
          className=" flex items-center gap-[10px] p-[14px] mt-[20px] cursor-pointer"
        >
          <i class="fa-solid fa-shield"></i>{" "}
          <a className="font-medium">Admin</a>
        </NavLink>
        <NavLink
          to="/purchases"
          className=" flex items-center gap-[10px] p-[14px] mt-[20px] cursor-pointer"
        >
          <i class="fa-solid fa-barcode"></i>{" "}
          <a className="font-medium">Purchases</a>
        </NavLink>
      </ul>
    </div>
  );
}

export default AdminSidebar
import React, { useEffect, useState } from "react";
import AdminSidebar from "./AdminSidebar";
import { uri } from "../../https";
import axios from "axios";
import ReactPlayer from "react-player";
import Pagination from "react-js-pagination";
import AdminTopBar from "../pages/SideMenu/AdminTopBar";

const Posts = () => {
  const [scheduled_post, setScheduledPostList] = useState([]);
  const [pagination, setPagination] = useState([]);
  const ScheduledPosts = async () => {
    // const url = `${uri}api/get_scheduled_posts?page=${pageNumber}`;
    const url = `${uri}api/get_scheduled_posts`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        if (response.data.status == 200) {
          // setScheduledPostList(response.data.scheduled_posts?.data);
          setScheduledPostList(response.data.scheduled_posts);
          // setPagination(response?.data?.scheduled_posts);
        }
        if (response.data.status == 400) {
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const [published_post, setPublishedPostList] = useState([]);
  const PublishedPosts = async () => {
    const url = `${uri}api/get_published_posts_admin`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        if (response.data.status == 200) {
          setPublishedPostList(response.data.published_posts);
        }
        if (response.data.status == 400) {
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    ScheduledPosts();
    PublishedPosts();
  }, []);

  const [charts, Setcharts] = useState([
    {
      id: 0,
      name: "ScheduledPost",
    },
    {
      id: 1,
      name: "PublishedPost",
    },
  ]);

  const [selectedposts, setSelectedPosts] = useState("");

  const onChangeChart = (charts) => {};
  console.log(selectedposts);
  return (
    <>
      <AdminTopBar />
      <div className=" flex flex-row">
        <div className="  hidden md:block w-[0px]  md:w-[340px] h-screen">
          <AdminSidebar />
        </div>
        <div className=" w-full h-screen overflow-scroll">
          <div className=" mb-[40px]">
            <div className=" p-[10px] mt-[30px] pl-[50px]">
              <select
                className="disabled:bg-gray-300 w-[200px] mt-2  py-3 outline-none bg-[#F0F0F0] rounded-md px-3 ring-1  ring-[#5aa7bc] focus:ring-[#015e75] "
                id="charts"
                name="charts"
                onChange={(e) => setSelectedPosts(e.target.value)}
                value={selectedposts}
              >
                {charts?.map((state, index) => {
                  return (
                    <option
                      key={state.index}
                      value={state.name}
                      // onChange={() => onChangeChart(state.name)}
                    >
                      {state.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className=" flex flex-row justify-between p-[10px] px-[50px]  mt-[50px]">
              {selectedposts == "ScheduledPost" ? (
                <>
                  <div>
                    {scheduled_post?.map((posts, index) => {
                      return (
                        <>
                          <div className=" flex flex-col md:flex-row items-start  gap-[40px] mb-[45px]">
                            <div>
                              <div></div>
                              {posts?.media ? (
                                <>
                                  <img
                                    src={posts?.media}
                                    className=" h-[220px] object-contain rounded-md "
                                  />
                                </>
                              ) : (
                                <>
                                  <img
                                    src="https://res.cloudinary.com/dv5jjlsd7/image/upload/v1688649971/SP%20ANALYST/arthur-hinton-9Y1iOQ4rvtw-unsplash_qccbhf.jpg"
                                    className=" h-[220px] object-contain rounded-md "
                                  />
                                </>
                              )}
                            </div>
                            <div>
                              <div className=" flex flex-col gap-[15px]">
                                <div>
                                  <h1 className=" text-[20px] font-medium text-gray-800">
                                    Platform :
                                    <span className="ml-[10px] mt-[5px]">
                                      {posts.social_platform_name ==
                                      "facebook" ? (
                                        <>
                                          <i class="fa-brands fa-facebook text-[22px] text-[#3b5998]"></i>
                                        </>
                                      ) : posts.social_platform_name ==
                                        "instagram" ? (
                                        <>
                                          <i class="fa-brands fa-instagram text-[22px] text-[#d62976]"></i>
                                        </>
                                      ) : posts.social_platform_name ==
                                        "linkedin" ? (
                                        <>
                                          <i class="fa-brands fa-linkedin text-[22px] text-[#0A66C2]"></i>
                                        </>
                                      ) : posts.social_platform_name ==
                                        "pinterest" ? (
                                        <>
                                          <i class="fa-brands fa-pinterest text-[22px] text-[#E60023]"></i>
                                        </>
                                      ) : posts.social_platform_name ==
                                        "tumblr" ? (
                                        <>
                                          <i class="fa-brands fa-tumblr text-[22px] text-[#34526f]"></i>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </span>
                                  </h1>
                                </div>
                                <div>
                                  <h1 className=" text-[20px] font-medium text-gray-800">
                                    Date And Time : {posts?.date_to_post} ,
                                    {posts?.time_to_post}
                                  </h1>
                                </div>
                                <div className=" w-full">
                                  <p className=" text-[20px] font-medium text-gray-800 w-full md:w-[600px] text-justify">
                                    Content :{" "}
                                    <span className="text-[17px]">
                                      {posts?.post_message}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <h1 className=" text-[20px] font-medium text-gray-800">
                                    Status :{" "}
                                    <span className="  text-red-500 rounded-md text-[17px]">
                                      Scheduled
                                    </span>
                                  </h1>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </>
              ) : selectedposts == "PublishedPost" ? (
                <>
                  <div>
                    {published_post?.map((posts, index) => {
                      return (
                        <>
                          <div className=" flex flex-col md:flex-row items-start  gap-[40px] mb-[45px]">
                            <div>
                              <div></div>
                              {posts?.media ? (
                                <>
                                  <img
                                    src={posts?.media}
                                    className=" h-[220px] object-contain rounded-md "
                                  />
                                </>
                              ) : (
                                <>
                                  <img
                                    src="https://res.cloudinary.com/dv5jjlsd7/image/upload/v1688649971/SP%20ANALYST/arthur-hinton-9Y1iOQ4rvtw-unsplash_qccbhf.jpg"
                                    className=" h-[220px] object-contain rounded-md "
                                  />
                                </>
                              )}
                            </div>
                            <div>
                              <div className=" flex flex-col gap-[15px]">
                                <div>
                                  <h1 className=" text-[20px] font-medium text-gray-800">
                                    Platform :
                                    <span className="ml-[10px] mt-[5px]">
                                      {posts.social_platform_name ==
                                      "facebook" ? (
                                        <>
                                          <i class="fa-brands fa-facebook text-[22px] text-[#3b5998]"></i>
                                        </>
                                      ) : posts.social_platform_name ==
                                        "instagram" ? (
                                        <>
                                          <i class="fa-brands fa-instagram text-[22px] text-[#d62976]"></i>
                                        </>
                                      ) : posts.social_platform_name ==
                                        "linkedin" ? (
                                        <>
                                          <i class="fa-brands fa-linkedin text-[22px] text-[#0A66C2]"></i>
                                        </>
                                      ) : posts.social_platform_name ==
                                        "pinterest" ? (
                                        <>
                                          <i class="fa-brands fa-pinterest text-[22px] text-[#E60023]"></i>
                                        </>
                                      ) : posts.social_platform_name ==
                                        "tumblr" ? (
                                        <>
                                          <i class="fa-brands fa-tumblr text-[22px] text-[#34526f]"></i>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </span>
                                  </h1>
                                </div>
                                <div>
                                  <h1 className=" text-[20px] font-medium text-gray-800">
                                    Date And Time : {posts?.date_to_post} ,
                                    {posts?.time_to_post}
                                  </h1>
                                </div>
                                <div className=" w-full">
                                  <p className=" text-[20px] font-medium text-gray-800 w-full md:w-[600px] text-justify">
                                    Content :{" "}
                                    <span className="text-[17px]">
                                      {posts?.post_message}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <h1 className=" text-[20px] font-medium text-gray-800">
                                    Status :{" "}
                                    <span className="  text-green-500 rounded-md text-[17px]">
                                      Published
                                    </span>
                                  </h1>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </>
              ) : (
                <>
                  <div>
                    {scheduled_post?.map((posts, index) => {
                      return (
                        <>
                          <div className=" flex flex-col md:flex-row items-start  gap-[40px] mb-[45px]">
                            <div>
                              <div></div>
                              {posts?.media ? (
                                <>
                                  <img
                                    src={posts?.media}
                                    className=" h-[220px] object-contain rounded-md "
                                  />
                                </>
                              ) : (
                                <>
                                  <img
                                    src="https://res.cloudinary.com/dv5jjlsd7/image/upload/v1688649971/SP%20ANALYST/arthur-hinton-9Y1iOQ4rvtw-unsplash_qccbhf.jpg"
                                    className=" h-[220px] object-contain rounded-md "
                                  />
                                </>
                              )}
                            </div>
                            <div>
                              <div className=" flex flex-col gap-[15px]">
                                <div>
                                  <h1 className=" text-[20px] font-medium text-gray-800">
                                    Platform :
                                    <span className="ml-[10px] mt-[5px]">
                                      {posts.social_platform_name ==
                                      "facebook" ? (
                                        <>
                                          <i class="fa-brands fa-facebook text-[22px] text-[#3b5998]"></i>
                                        </>
                                      ) : posts.social_platform_name ==
                                        "instagram" ? (
                                        <>
                                          <i class="fa-brands fa-instagram text-[22px] text-[#d62976]"></i>
                                        </>
                                      ) : posts.social_platform_name ==
                                        "linkedin" ? (
                                        <>
                                          <i class="fa-brands fa-linkedin text-[22px] text-[#0A66C2]"></i>
                                        </>
                                      ) : posts.social_platform_name ==
                                        "pinterest" ? (
                                        <>
                                          <i class="fa-brands fa-pinterest text-[22px] text-[#E60023]"></i>
                                        </>
                                      ) : posts.social_platform_name ==
                                        "tumblr" ? (
                                        <>
                                          <i class="fa-brands fa-tumblr text-[22px] text-[#34526f]"></i>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </span>
                                  </h1>
                                </div>
                                <div>
                                  <h1 className=" text-[20px] font-medium text-gray-800">
                                    Date And Time : {posts?.date_to_post} ,
                                    {posts?.time_to_post}
                                  </h1>
                                </div>
                                <div className=" w-full">
                                  <p className=" text-[20px] font-medium text-gray-800 w-full md:w-[600px] text-justify">
                                    Content :{" "}
                                    <span className="text-[17px]">
                                      {posts?.post_message}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <h1 className=" text-[20px] font-medium text-gray-800">
                                    Status :{" "}
                                    <span className="  text-red-500 rounded-md text-[17px]">
                                      Scheduled
                                    </span>
                                  </h1>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
            {/* <div className=" flex flex-col justify-center  items-center mt-6  w-[500px]">
              <Pagination
                activePage={pagination.current_page}
                totalItemsCount={pagination.total}
                itemsCountPerPage={pagination.per_page}
                onChange={(pageNumber) => ScheduledPosts(pageNumber)}
              />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Posts;

import React, { useCallback, useEffect } from "react";
import { useContext } from "react";
import GlobalContext from "../../../context/GlobalContext";
import { useState } from "react";
import { Fragment } from "react";
import axios from "axios";
import { v4 as uuid } from "uuid";
import { uri } from "../../../https";
import { useDropzone } from "react-dropzone";
import dayjs from "dayjs";
import { ToastContainer, toast } from "react-toastify";
import EventsVideoModal from "./EventsVideoModal";
import { Link } from "react-router-dom";
import PinterestBoardsForm from "./PinterestBoardsForm";
import { useFormik } from "formik";
import { pinterestboardPageSchema } from "../../../validation";

const EventsModal = ({ className, selected_date, current_date }) => {
  const [loading, setLoading] = useState(false);
  const { setShowEventModal, daysSelected, dispatchCalEvent, selectedEvent } =
    useContext(GlobalContext);
  const labelsClasses = ["blue"];
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [pinterest_board_id, setPinterestBoardId] = useState("");
  const [selectedLabel, setSelectedLabel] = useState(labelsClasses[0]);

  const [Time, setTime] = useState([
    {
      id: 0,
      time: "12:00",
    },
    {
      id: 1,
      time: "12:30",
    },
    {
      id: 2,
      time: "01:00",
    },
    {
      id: 3,
      time: "01:30",
    },
    {
      id: 4,
      time: "02:00",
    },
    {
      id: 5,
      time: "02:30",
    },
    {
      id: 6,
      time: "03:00",
    },
    {
      id: 7,
      time: "03:30",
    },
    {
      id: 8,
      time: "04:00",
    },
    {
      id: 9,
      time: "04:30",
    },
    {
      id: 10,
      time: "05:00",
    },
    {
      id: 10,
      time: "05:30",
    },
    {
      id: 10,
      time: "06:00",
    },
    {
      id: 10,
      time: "06:30",
    },
    {
      id: 10,
      time: "07:00",
    },
    {
      id: 10,
      time: "07:30",
    },
    {
      id: 10,
      time: "08:00",
    },
    {
      id: 10,
      time: "08:30",
    },
    {
      id: 10,
      time: "09:00",
    },
    {
      id: 10,
      time: "09:30",
    },
    {
      id: 10,
      time: "10:00",
    },
    {
      id: 10,
      time: "10:30",
    },
  ]);

  const [instagramOptions, setInstagramOptions] = useState([
    {
      id: 0,
      option: "post",
    },
    {
      id: 1,
      option: "reels",
    },
    {
      id: 2,
      option: "story",
    },
  ]);

  const [facebookOptions, setFacebookOptions] = useState([
    {
      id: 0,
      option: "text",
    },
    {
      id: 1,
      option: "image",
    },
    {
      id: 2,
      option: "video",
    },
  ]);

  const [linkedOptions, setLinkedOptions] = useState([
    {
      id: 0,
      option: "text",
    },
  ]);

  const [tumblrOptions, setTumblrOptions] = useState([
    {
      id: 0,
      option: "text",
    },
    {
      id: 1,
      option: "image",
    },
  ]);

  const [pinterestOptions, setPinterestOptions] = useState([
    {
      id: 0,
      option: "pins",
    },
    {
      id: 1,
      option: "boards",
    },
    {
      id: 1,
      option: "pins_video",
    },
  ]);
  const [dummyOptions, setDummyOptions] = useState([
    {
      id: 0,
      option: "post",
    },
    {
      id: 1,
      option: "image",
    },
  ]);

  // console.log("event selected", selectedEvent);

  // console.log("days", daysSelected);

  const [metaPageDetails, setMetaPageDetails] = useState([]);
  const FetchMetaPages = async () => {
    const url = `${uri}api/get_meta_pagedetails`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        setMetaPageDetails(response?.data?.meta_pagedetails);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  console.log("meta pages", metaPageDetails);

  useEffect(() => {
    FetchMetaPages();
  }, []);
  const [socialMediaPlatformSelected, setSocialMediaPlatformSelected] =
    useState([]);

  console.log(socialMediaPlatformSelected);
  const SocialMediaPlatformSelected = (pages) => {
    console.log("Platform", pages);
    setSocialMediaPlatformSelected(pages);
  };

  const [selectedImages, setSelectedImages] = useState([]);
  const [image, setImage] = useState([]);
  const [event_video, setEventVideo] = useState("");
  const onDrop = useCallback((acceptedFiles) => {
    setSelectedImages(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );

    setImage(acceptedFiles[0]);
  }, []);

  console.log("Image uploaded", image);
  console.log("Video uploaded", event_video);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/png": [],
    },
    onDrop,
  });

  const removeFile = (name) => {
    setSelectedImages((files) => files.filter((file) => file.name !== name));
  };

  const selected_images = selectedImages?.map((file) => (
    <div className=" flex">
      <div className=" h-[20px] w-[20px] flex justify-center items-center rounded-full bg-red-500 text-white ">
        <i
          onClick={() => removeFile(file.name)}
          class="fa-solid fa-xmark cursor-pointer"
        ></i>
      </div>
      <img src={file.preview} className=" w-[90px]  rounded-md " />
    </div>
  ));

  const [instagramOptionsSelected, setInstagramOptionsSelected] = useState("");
  const [facebookOptionsSelected, setFacebookOptionsSelected] = useState("");
  const [linkedOptionsSelected, setLinkedOptionsSelected] = useState("");
  const [tumblrOptionsSelected, setTumblrOptionsSelected] = useState("");
  const [pinterestOptionsSelected, setPinterestOptionsSelected] = useState("");

  const [dummyOptionsSelected, setDummyOptionsSelected] = useState("");
  let checked =
    instagramOptions.option === instagramOptionsSelected &&
    facebookOptions.option === facebookOptionsSelected;

  let checkedlinkedin = linkedOptions.option == linkedOptionsSelected;
  let checkedtumblr = tumblrOptions.option == tumblrOptionsSelected;
  let checkedpinterest = pinterestOptions.option == pinterestOptionsSelected;

  const selectedPlatform =
    instagramOptionsSelected ||
    facebookOptionsSelected ||
    linkedOptionsSelected ||
    tumblrOptionsSelected ||
    pinterestOptionsSelected;

  const linkedinPlatform = linkedOptionsSelected;

  if (linkedOptionsSelected == "post") {
  }
  const unique_id = uuid();
  const small_id = unique_id.slice(0, 8);

  const [selectedTime, setSelectedTime] = useState("");
  console.log("Time selected", selectedTime);
  const [tempImage, setTempImage] = useState("");
  const handleSubmitEvent = (e) => {
    e.preventDefault();
    setLoading(true);
    const calendarEvent = {
      post_message: title,
      description: description,
      pinterest_board_id: pinterest_board_id,
      // label: selectedLabel,
      unix_datestamp: daysSelected.valueOf(),
      page_name: socialMediaPlatformSelected?.page_name,
      social_platform_id: socialMediaPlatformSelected.page_id,
      social_platform_name: socialMediaPlatformSelected.platform,

      post_type:
        instagramOptionsSelected ||
        facebookOptionsSelected ||
        linkedOptionsSelected ||
        tumblrOptionsSelected ||
        pinterestOptionsSelected,
      time_to_post: selectedTime,
      post_id: small_id,
      id: small_id,
      media: image,
      video_media: event_video,
      post_status: "scheduled",
    };
    const scheduled_date = dayjs(calendarEvent.unix_datestamp).format(
      "DD-MM-YYYY"
    );
    console.log("Scheduled date", scheduled_date);
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("pinterest_board_id", pinterest_board_id);
    formData.append("date_to_post", scheduled_date);
    formData.append("post_id", small_id);
    // formData.append("media", tempImage);
    formData.append("media", image);
    formData.append("video_media", event_video);
    formData.append("time_to_post", selectedTime);
    formData.append("social_platform_id", socialMediaPlatformSelected.page_id);
    formData.append(
      "social_platform_name",
      socialMediaPlatformSelected.platform
    );
    formData.append("page_name", socialMediaPlatformSelected?.page_name);
    formData.append("unix_datestamp", daysSelected.valueOf());
    formData.append(
      "post_type",
      instagramOptionsSelected ||
        facebookOptionsSelected ||
        linkedOptionsSelected ||
        tumblrOptionsSelected ||
        pinterestOptionsSelected
    );

    const url = `${uri}api/users_posts`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;

    let config = {
      headers: {
        Authorization: "Bearer " + token,
        accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    };
    axios.post(url, formData, config).then(
      (response) => {
        if (response.data.status === 200) {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setLoading(false);
          setShowEventModal(false);
          localStorage.setItem(
            "savedEvents",
            JSON.stringify(response.data.response)
          );
          dispatchCalEvent({ type: "push", payload: calendarEvent });
        }
        if (response.data.status === 400) {
          toast.error(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setLoading(false);
        }
        // console.log(response.data);
      },
      (error) => {
        toast.error("Failed to create event try again later", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.log(error.response.data);
        setLoading(false);
      }
    );

    // console.log("Calender event", calendarEvent);
  };

  console.log(socialMediaPlatformSelected.platform);
  console.log(
    instagramOptionsSelected,
    facebookOptionsSelected,
    linkedOptionsSelected
  );

  const InstagramCheckSelected = () => {};

  const [isDisabled, setDisabled] = useState(false);
       {
         /* Selecte all platforms */
       }
  const [allcheckbox, setChecked] = useState([]);
  const handleCheckbox = (e) => {
    e.preventDefault();
    setChecked({ ...allcheckbox, [e.target.name]: e.target.checked });
  };

  console.log("checkboxes", allcheckbox);

  const date = dayjs().format("DD-MM");

  const date_selected = daysSelected.format("DD-MM");
  console.log("Date selected", date_selected);
  console.log("Current date", date);

  const [pinterestboardsid, setPinterestBoardsId] = useState([
    {
      id: 0,
      option: "957507639465127275",
    },
  ]);

  const [pinterest_boards, setPinterestBoards] = useState([]);
  const GetPinterestBoards = async () => {
    const url = `${uri}api/get_published_pin_boards`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        setPinterestBoards(response?.data?.published_pin_boards);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    GetPinterestBoards();
  }, []);

  return (
    <>
      <div className=" h-screen w-full fixed left-0 top-[0px] flex justify-center items-center   backdrop-blur-sm">
        <div className=" flex flex-row gap-2 md:m-[80px] ">
          <div className=" bg-white rounded-md shadow-lg  w-full md:w-[600px] ">
            <header className="bg-[#f5f5f5]  px-4 py-2 flex justify-between items-center rounded-md  shadow-md">
              <span className="material-icons-outlined text-gray-400"></span>
              <button onClick={() => setShowEventModal(false)}>
                <span className="material-icons-outlined text-gray-400">
                  close
                </span>
              </button>
            </header>
            <div className="w-full p-[50px] md:p-[0px]">
              <div className=" w-full  bg-white  md:p-5  overflow-y-auto rounded-md">
                <div className=" overflow-scroll h-[600px]">
                  <div className="flex justify-start items-center gap-7  overflow-auto">
                    {metaPageDetails.map((pages, index) => {
                      return (
                        <>
                          {pages.status == "active" && (
                            <div key={index}>
                              {socialMediaPlatformSelected.platform ===
                              pages.platform ? (
                                <button className=" bg-[#0180a1]/20 cursor-pointer font-semibold capitalize  h-[45px] w-[45px] rounded-full border-2 border-[#0180a1]   flex justify-center items-center ">
                                  {pages.page_name.slice(0, 1)}
                                  {/* <span className="  mt-[40px]">
                                    <i
                                      className={
                                        (pages?.platform === "facebook" &&
                                          "  fa-brands fa-facebook text-[22px] text-[#3b5998] ") ||
                                        (pages?.platform === "instagram" &&
                                          "   fa-brands fa-square-instagram  text-[22px] text-[#d62976]") ||
                                        (pages?.platform === "linkedin" &&
                                          " fa-brands fa-linkedin text-[22px] text-[#0A66C2]  ") ||
                                        (pages?.platform === "tumblr" &&
                                          "fa-brands fa-square-tumblr text-[22px] text-[#34526f]   ") ||
                                        (pages?.platform === "pinterest" &&
                                          "fa-brands fa-pinterest text-[22px] text-[#E60023] ")
                                      }
                                    ></i>
                                  </span> */}
                                </button>
                              ) : (
                                <button
                                  onClick={() =>
                                    SocialMediaPlatformSelected(pages)
                                  }
                                  className={
                                    (pages.platform === "facebook" &&
                                      " bg-[#3b5998] cursor-pointer text-white h-[45px] w-[45px] rounded-full border-1 border-gray-300   flex justify-center items-center") ||
                                    (pages.platform === "instagram" &&
                                      " bg-[#d62976] cursor-pointer text-white h-[45px] w-[45px] rounded-full border-1 border-gray-300   flex justify-center items-center") ||
                                    (pages.platform === "linkedin" &&
                                      " bg-[#0A66C2] cursor-pointer text-white h-[45px] w-[45px] rounded-full border-1 border-gray-300   flex justify-center items-center") ||
                                    (pages.platform === "pinterest" &&
                                      " bg-[#E60023] cursor-pointer text-white h-[45px] w-[45px] rounded-full border-1 border-gray-300   flex justify-center items-center") ||
                                    (pages.platform === "tumblr" &&
                                      " bg-[#34526f] cursor-pointer text-white h-[45px] w-[45px] rounded-full border-1 border-gray-300   flex justify-center items-center")
                                  }
                                >
                                  {/* platform */}
                                  <p className=" font-semibold capitalize text-white  relative mr-[0px]">
                                    {pages.page_name.slice(0, 1)}
                                  </p>
                                  {/* <span className=" mt-[40px] ml-14">
                                    <i
                                      className={
                                        (pages?.platform === "facebook" &&
                                          "  fa-brands fa-facebook text-[22px] text-[#3b5998] ") ||
                                        (pages?.platform === "instagram" &&
                                          "   fa-brands fa-square-instagram  text-[22px] text-[#d62976]") ||
                                        (pages?.platform === "linkedin" &&
                                          " fa-brands fa-linkedin text-[22px] text-[#0A66C2]  ") ||
                                        (pages?.platform === "pinterest" &&
                                          " fa-brands fa-pinterest text-[22px] text-[#E60023]  ") ||
                                        (pages?.platform === "tumblr" &&
                                          "fa-brands fa-square-tumblr text-[22px] text-[#34526f]  ")
                                      }
                                    ></i>
                                  </span> */}
                                  {/* Selecte all platforms */}
                                  {/* <input
                                    className=" absolute mt-[60px] "
                                    type="checkbox"
                                    name={pages.platform}
                                    onChange={handleCheckbox}
                                    defaultChecked={
                                      allcheckbox.platform === 1 ? true : false
                                    }
                                  /> */}
                                </button>
                              )}
                            </div>
                          )}
                        </>
                      );
                    })}
                  </div>
                  <form>
                    {metaPageDetails[0]?.page_name ? (
                      <>
                        <div className=" mt-[20px]">
                          {socialMediaPlatformSelected.platform ==
                            "instagram" && (
                            <div className=" flex justify-start items-center gap-6 w-full bg-gray-100 rounded-t-md p-2">
                              {instagramOptions.map((options, index) => {
                                return (
                                  <div
                                    key={index}
                                    className=" flex gap-1 justify-center items-center"
                                  >
                                    <input
                                      onClick={() => {
                                        setFacebookOptionsSelected("");
                                        setLinkedOptionsSelected("");
                                      }}
                                      type="radio"
                                      value={options.option}
                                      checked={checked}
                                      onChange={(e) =>
                                        setInstagramOptionsSelected(
                                          e.target.value
                                        )
                                      }
                                      className={
                                        options.option ==
                                        instagramOptionsSelected
                                          ? "w-4 h-4 text-blue-600 bg-[#0180a1] border-gray-300 focus:ring-[#0180a1]  focus:ring-1"
                                          : "w-4 h-4 text-blue-600 bg-white border-gray-300 focus:ring-blue-500  focus:ring-1"
                                      }
                                    />
                                    <span className="text-[14px] font-medium">
                                      {options.option}
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          )}

                          {socialMediaPlatformSelected.platform ==
                            "facebook" && (
                            <div className=" flex justify-start items-center gap-6 w-full bg-gray-100 rounded-t-md p-2">
                              {facebookOptions.map((options, index) => {
                                return (
                                  <div
                                    key={index}
                                    className=" flex gap-1 justify-center items-center"
                                  >
                                    <input
                                      onClick={() => {
                                        setInstagramOptionsSelected("");
                                        setLinkedOptionsSelected("");
                                      }}
                                      type="radio"
                                      value={options.option}
                                      checked={checked}
                                      onChange={(e) =>
                                        setFacebookOptionsSelected(
                                          e.target.value
                                        )
                                      }
                                      className={
                                        options.option ==
                                        facebookOptionsSelected
                                          ? "w-4 h-4 text-blue-600 bg-[#0180a1] border-gray-300 focus:ring-[#0180a1]  focus:ring-1"
                                          : "w-4 h-4 text-blue-600 bg-white border-gray-300 focus:ring-blue-500  focus:ring-1"
                                      }
                                    />
                                    <span className="text-[14px] font-medium">
                                      {options.option}
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          )}

                          {socialMediaPlatformSelected.platform == "tumblr" && (
                            <div className=" flex justify-start items-center gap-6 w-full bg-gray-100 rounded-t-md p-2">
                              {tumblrOptions.map((options, index) => {
                                return (
                                  <div
                                    key={index}
                                    className=" flex gap-1 justify-center items-center"
                                  >
                                    <input
                                      onClick={() => {
                                        setInstagramOptionsSelected("");
                                        setFacebookOptionsSelected("");
                                        setLinkedOptionsSelected("");
                                      }}
                                      type="radio"
                                      value={options.option}
                                      checked={checkedtumblr}
                                      onChange={(e) =>
                                        setTumblrOptionsSelected(e.target.value)
                                      }
                                      className={
                                        options.option == tumblrOptionsSelected
                                          ? "w-4 h-4 text-blue-600 bg-[#0180a1] border-gray-300 focus:ring-[#0180a1]  focus:ring-1"
                                          : "w-4 h-4 text-blue-600 bg-white border-gray-300 focus:ring-blue-500  focus:ring-1"
                                      }
                                    />
                                    <span className="text-[14px] font-medium">
                                      {options.option}
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          )}

                          {socialMediaPlatformSelected.platform ==
                            "pinterest" && (
                            <div className=" flex justify-start items-center gap-6 w-full bg-gray-100 rounded-t-md p-2">
                              {pinterestOptions.map((options, index) => {
                                return (
                                  <div
                                    key={index}
                                    className=" flex gap-1 justify-center items-center"
                                  >
                                    <input
                                      onClick={() => {
                                        setInstagramOptionsSelected("");
                                        setFacebookOptionsSelected("");
                                        setLinkedOptionsSelected("");
                                        setTumblrOptionsSelected("");
                                      }}
                                      type="radio"
                                      value={options.option}
                                      checked={checkedpinterest}
                                      onChange={(e) =>
                                        setPinterestOptionsSelected(
                                          e.target.value
                                        )
                                      }
                                      className={
                                        options.option ==
                                        pinterestOptionsSelected
                                          ? "w-4 h-4 text-blue-600 bg-[#0180a1] border-gray-300 focus:ring-[#0180a1]  focus:ring-1"
                                          : "w-4 h-4 text-blue-600 bg-white border-gray-300 focus:ring-blue-500  focus:ring-1"
                                      }
                                    />
                                    <span className="text-[14px] font-medium">
                                      {options.option}
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          )}

                          {socialMediaPlatformSelected.platform ==
                            "linkedin" && (
                            <div className=" flex justify-start items-center gap-6 w-full bg-gray-100 rounded-t-md p-2">
                              {linkedOptions.map((options, index) => {
                                return (
                                  <div
                                    key={index}
                                    className=" flex gap-1 justify-center items-center"
                                  >
                                    <input
                                      onClick={() => {
                                        setInstagramOptionsSelected("");
                                        setFacebookOptionsSelected("");
                                        setLinkedOptionsSelected("");
                                        setTumblrOptionsSelected("");
                                        setPinterestOptionsSelected("");
                                      }}
                                      type="radio"
                                      value={options.option}
                                      checked={checkedlinkedin}
                                      onChange={(e) =>
                                        setLinkedOptionsSelected(e.target.value)
                                      }
                                      className={
                                        options.option == linkedOptionsSelected
                                          ? "w-4 h-4 text-blue-600 bg-[#0180a1] border-gray-300 focus:ring-[#0180a1]  focus:ring-1"
                                          : "w-4 h-4 text-blue-600 bg-white border-gray-300 focus:ring-blue-500  focus:ring-1"
                                      }
                                    />
                                    <span className="text-[14px] font-medium">
                                      {options.option}
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          )}

                          {(socialMediaPlatformSelected &&
                            instagramOptionsSelected) ||
                          (socialMediaPlatformSelected &&
                            facebookOptionsSelected) ||
                          (socialMediaPlatformSelected &&
                            linkedOptionsSelected) ||
                          (socialMediaPlatformSelected &&
                            tumblrOptionsSelected) ||
                          (socialMediaPlatformSelected &&
                            pinterestOptionsSelected) ? (
                            <>
                              {selectedPlatform === "pins" ? (
                                <>
                                  <div className=" mb-[200px] mt-[30px]">
                                    <div className=" mb-[20px]">
                                      <input
                                        type="text"
                                        placeholder="Title"
                                        value={title}
                                        onChange={(e) =>
                                          setTitle(e.target.value)
                                        }
                                        className=" disabled:bg-gray-300 w-full mt-2  py-3 outline-none bg-[#F0F0F0] rounded-md px-3 ring-1  ring-[#5aa7bc] focus:ring-[#015e75] "
                                      />
                                    </div>
                                    <div className=" mb-[20px]">
                                      <input
                                        type="text"
                                        placeholder="Description"
                                        value={description}
                                        onChange={(e) =>
                                          setDescription(e.target.value)
                                        }
                                        className=" disabled:bg-gray-300 w-full mt-2  py-3 outline-none bg-[#F0F0F0] rounded-md px-3 ring-1  ring-[#5aa7bc] focus:ring-[#015e75] "
                                      />
                                    </div>
                                    <div className=" mb-[20px]">
                                      <select
                                        onChange={(e) =>
                                          setPinterestBoardId(e.target.value)
                                        }
                                        className=" disabled:bg-gray-300 w-full mt-2  py-3 outline-none bg-[#F0F0F0] rounded-md px-3 ring-1  ring-[#5aa7bc] focus:ring-[#015e75] "
                                      >
                                        <option value="value" selected>
                                          {" "}
                                          Select Board Id{" "}
                                        </option>
                                        {pinterest_boards.map((data, index) => {
                                          return (
                                            <option
                                              key={index}
                                              value={data?.pin_board_id}
                                            >
                                              {data?.pin_board_id}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                </>
                              ) : selectedPlatform === "boards" ? (
                                <>
                                  <div className=" flex justify-center items-center mb-[200px] mt-[30px]">
                                    <button className="py-3  px-6 bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all  md:hover:scale-90">
                                      Go To Boards
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <textarea
                                    type="text"
                                    name="title"
                                    placeholder={
                                      "What would your like to share ?"
                                    }
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    className="  h-[300px] resize-none text-gray-600  pb-2 w-full  border-2 rounded-md border-gray-200 focus:outline-none focus:ring-0 focus:border-[#3EC4E8] py-7"
                                  ></textarea>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <textarea
                                type="text"
                                name="title"
                                placeholder="Choose platform and type "
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                disabled
                                className=" disabled:bg-gray-100  h-[300px] resize-none text-gray-600  pb-2 w-full  border-2 rounded-md border-gray-200 focus:outline-none focus:ring-0 focus:border-[#3EC4E8] py-7"
                              ></textarea>
                            </>
                          )}
                        </div>
                        {selectedPlatform == "text" ? (
                          <></>
                        ) : selectedPlatform === "video" ? (
                          <EventsVideoModal setEventVideo={setEventVideo} />
                        ) : selectedPlatform == "reels" ? (
                          <>
                            <EventsVideoModal setEventVideo={setEventVideo} />
                          </>
                        ) : selectedPlatform == "image" ? (
                          <>
                            <div className=" m-3 relative  flex justify-center  z-[10000] items-center  -mt-[130px] cursor-pointer h-[100px] w-[130px] border-2 border-dashed border-gray-300 rounded-md hover:border-[#3EC4E8] transition-all hover:transition-all">
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />

                                <div className=" flex flex-col justify-center items-center">
                                  <i className="fa-solid fa-image text-[24px] text-gray-600"></i>
                                  <p>PNG</p>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : selectedPlatform == "story" ? (
                          <>
                            <div className=" m-3 relative flex justify-center  z-[10000] items-center  -mt-[130px] cursor-pointer h-[100px] w-[130px] border-2 border-dashed border-gray-300 rounded-md hover:border-[#3EC4E8] transition-all hover:transition-all">
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />

                                <div className=" flex flex-col justify-center items-center">
                                  <i className="fa-solid fa-image text-[24px] text-gray-600"></i>
                                  <p>PNG</p>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : selectedPlatform == "post" ? (
                          <>
                            {" "}
                            <div className=" m-3 relative  flex justify-center  z-[10000] items-center  -mt-[130px] cursor-pointer h-[100px] w-[130px] border-2 border-dashed border-gray-300 rounded-md hover:border-[#3EC4E8] transition-all hover:transition-all">
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />

                                <div className=" flex flex-col justify-center items-center">
                                  <i className="fa-solid fa-image text-[24px] text-gray-600"></i>
                                  <p>PNG</p>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : selectedPlatform == "pins" ? (
                          <>
                            <div className=" m-3 relative flex justify-center  z-[10000] items-center  -mt-[130px] cursor-pointer h-[100px] w-[130px] border-2 border-dashed border-gray-300 rounded-md hover:border-[#3EC4E8] transition-all hover:transition-all">
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />

                                <div className=" flex flex-col justify-center items-center">
                                  <i className="fa-solid fa-image text-[24px] text-gray-600"></i>
                                  <p>PNG</p>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : selectedPlatform === "pins_video" ? (
                          <EventsVideoModal setEventVideo={setEventVideo} />
                        ) : null}

                        <div className=" w-full    rounded-md">
                          <div className="  m-3       flex flex-col">
                            {selected_images}
                          </div>
                        </div>
                        {/* <div className="">
                          <div className="  m-3       flex flex-col">
                            <input
                              value={tempImage}
                              onChange={(e) => setTempImage(e.target.value)}
                              type="text"
                              placeholder="Paste image url here"
                              className=" rounded-md"
                            />
                          </div>
                        </div> */}
                        {selectedPlatform === "boards" ? (
                          <></>
                        ) : (
                          <>
                            <div>
                              <p className=" font-medium text-[16px]">
                                Platform Choosen :{" "}
                                {socialMediaPlatformSelected.platform}
                              </p>
                            </div>
                            <div className=" flex flex-col md:flex-row justify-between md:justify-between items-center  mt-[30px] md:mt-[70px] ">
                              <div className=" m-2  flex flex-col md:flex-row justify-between  gap-2 items-center">
                                <div>
                                  <p className=" text-gray-600 font-semibold">
                                    {" "}
                                    <i className="fa-regular fa-calendar"></i>{" "}
                                    {daysSelected.format("dddd MMMM DD")}
                                  </p>
                                </div>
                                <div>
                                  <select
                                    className=" disabled:bg-gray-300 w-full mt-2  py-3 outline-none bg-[#F0F0F0] rounded-md px-9 ring-1  ring-[#5aa7bc] focus:ring-[#015e75] "
                                    onChange={(e) =>
                                      setSelectedTime(e.target.value)
                                    }
                                  >
                                    <option value="value" selected>
                                      Select time
                                    </option>
                                    {Time.map((time, index) => {
                                      return (
                                        <option key={index} value={time?.time}>
                                          {time?.time}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>
                              {loading ? (
                                <>
                                  <button
                                    // onClick={handleSubmit}
                                    // type="submit"
                                    className=" bg-[#0180a1] hover:bg-[#0180a1]/95 w-[150px] py-2 rounded text-white"
                                  >
                                    <svg
                                      aria-hidden="true"
                                      class="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                                      viewBox="0 0 100 101"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                        fill="currentColor"
                                      />
                                      <path
                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                        fill="currentFill"
                                      />
                                    </svg>
                                  </button>
                                </>
                              ) : (
                                <>
                                  {title.length == "" || selectedTime == "" ? (
                                    <>
                                      <button
                                        // onClick={handleSubmitEvent}
                                        className="py-3  px-6 bg-gray-500 text-white rounded-md font-semibold  transition-all hover:transition-all  md:hover:scale-90"
                                      >
                                        Schedule
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      {pinterestOptionsSelected ==
                                      "pins_video" ? (
                                        <>
                                          <button className="py-3  px-6 bg-gray-500 text-white rounded-md font-semibold  transition-all hover:transition-all  md:hover:scale-90">
                                            Coming Soon
                                          </button>
                                        </>
                                      ) : (
                                        <>
                                          <button
                                            onClick={handleSubmitEvent}
                                            className="py-3  px-6 bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all  md:hover:scale-90"
                                          >
                                            Schedule
                                          </button>
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <div className=" mt-[20px]">
                          <textarea
                            type="text"
                            name="title"
                            placeholder="What would your like to share ?"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            className=" h-[380px] resize-none text-gray-600  pb-2 w-full  border-2 rounded-md border-gray-200 focus:outline-none focus:ring-0 focus:border-[#3EC4E8] py-7"
                          ></textarea>
                        </div>
                        <div className=" m-3 absolute flex justify-center  z-[10000] items-center  -mt-[120px] cursor-pointer h-[100px] w-[130px] border-2 border-dashed border-gray-300 rounded-md hover:border-[#3EC4E8] transition-all hover:transition-all">
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />

                            <i className="fa-solid fa-image text-[24px] text-gray-600"></i>
                            <p></p>
                          </div>
                        </div>
                        <div className=" w-full    rounded-md">
                          <div className="  m-3       flex flex-col">
                            {selected_images}
                          </div>
                        </div>
                        {/* <div className="">
                          <div className="  m-3       flex flex-col">
                            <input
                              value={tempImage}
                              onChange={(e) => setTempImage(e.target.value)}
                              type="text"
                              placeholder="Paste image url here"
                              className=" rounded-md"
                            />
                          </div>
                        </div> */}
                        <div className=" m-2 mt-[50px] flex flex-row gap-2 items-center">
                          <div>
                            <p className=" text-gray-600 font-semibold">
                              {" "}
                              <i className="fa-regular fa-calendar"></i>{" "}
                              {daysSelected.format("dddd MMMM DD")}
                            </p>
                          </div>
                          <div>
                            <select
                              onChange={(e) => setSelectedTime(e.target.value)}
                            >
                              <option value="value" selected>
                                {" "}
                                Select time{" "}
                              </option>
                              {Time.map((time, index) => {
                                return (
                                  <option key={index} value={time?.time}>
                                    {time?.time}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div className=" flex justify-end items-end ">
                            <Link to="/channels/connect">
                              <button className="py-3  px-6 bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all  md:hover:scale-90">
                                Connect Channels
                              </button>
                            </Link>
                          </div>
                        </div>
                      </>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* <div className=" hidden md:block md:w-[370px] bg-white h-[690px] shadow-lg rounded-md">
            <div className=" m-5">
              <div className=" mb-3">
                <h1 className=" font-semibold text-[16px] text-gray-700">
                  Editor Preview
                </h1>
              </div>
              {title && (
                <div className=" w-full border-1 border-gray-200 rounded-md">
                  <div className=" flex flex-col justify-start gap-2 p-5 items-start">
                    <div className=" flex flex-row gap-3 items-start justify-start">
                      <div>
                        <button
                          className={
                            (socialMediaPlatformSelected.platform ===
                              "facebook" &&
                              " bg-[#3b5998] cursor-pointer text-white h-[45px] w-[45px] rounded-full border-1 border-gray-300   flex justify-center items-center") ||
                            (socialMediaPlatformSelected.platform ===
                              "instagram" &&
                              " bg-[#d62976] cursor-pointer text-white h-[45px] w-[45px] rounded-full border-1 border-gray-300   flex justify-center items-center") ||
                            (socialMediaPlatformSelected.platform ===
                              "linkedin" &&
                              " bg-[#0A66C2] cursor-pointer text-white h-[45px] w-[45px] rounded-full border-1 border-gray-300   flex justify-center items-center") ||
                            (socialMediaPlatformSelected.platform ===
                              "tumblr" &&
                              " bg-[#34526f] cursor-pointer text-white h-[45px] w-[45px] rounded-full border-1 border-gray-300   flex justify-center items-center") ||
                            (socialMediaPlatformSelected.platform ===
                              "pinterest" &&
                              " bg-[#E60023] cursor-pointer text-white h-[45px] w-[45px] rounded-full border-1 border-gray-300   flex justify-center items-center")
                          }
                        >
                          
                          <p className=" font-semibold capitalize">
                            {socialMediaPlatformSelected?.page_name?.slice(
                              0,
                              1
                            )}
                          </p>
                        </button>
                      </div>
                      <div>
                        <h1 className=" font-semibold text-gray-600 capitalize">
                          {socialMediaPlatformSelected?.page_name}
                        </h1>
                        <span className=" text-gray-500 text-[12px]">
                          Just now
                        </span>
                        {"  "}{" "}
                        <i class="fa-solid fa-earth-europe text-[12] text-gray-500"></i>
                      </div>
                    </div>

                    <div className=" w-full flex flex-wrap text-sm break-normal  overflow-hidden">
                      <p className=" ">{title}</p>
                    </div>
                    <div className=" border border-t-gray-300 border-b-0 border-r-0 border-l-0 w-full p-2">
                      <div className=" flex justify-between items-center mt-2">
                        <div>
                          <i class="fa-regular fa-thumbs-up"></i>{" "}
                          <span>Like</span>
                        </div>
                        <div>
                          <i class="fa-regular fa-message"></i>{" "}
                          <span>Comment</span>
                        </div>
                        <div>
                          <i class="fa-regular fa-share-from-square"></i>{" "}
                          <span>Share</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div> */}
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
    </>
  );
};

export default EventsModal;

import React, { useEffect, useState } from "react";
import AdminSidebar from "./AdminSidebar";
import Posts from "./Posts";
import { Link } from "react-router-dom";
import AdminTopBar from "../pages/SideMenu/AdminTopBar";
import { uri } from "../../https";
import axios from "axios";
import PurchaseDetailsChart from "./PurchaseDetailsChart";

const AdminDashboard = () => {
  const [loading_data, setLoadingData] = useState(false);

  const [scheduled_post, setScheduledPost] = useState([]);
  const [published_post, setPublishedPost] = useState([]);
  const [users_list, setUsersList] = useState([]);

  const FetchScheduledPostLength = async () => {
    const url = `${uri}api/get_scheduled_posts`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));

    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        console.log("SCHEDULED POSTS", response.data.scheduled_posts);
        setScheduledPost(response.data?.scheduled_posts);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const FetchPublishedPostLength = async () => {
    const url = `${uri}api/get_published_posts_admin`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));

    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        console.log("PUBLISHED POSTS", response.data.published_posts);
        setPublishedPost(response.data?.published_posts);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const FetchUsersLength = async () => {
    const url = `${uri}api/get_userslist_admin`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));

    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        setUsersList(response.data?.user_info);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    FetchScheduledPostLength();
    FetchPublishedPostLength();
    FetchUsersLength();
  }, []);

  return (
    <>
      <AdminTopBar />
      <div className=" flex justify-start items-start">
        <div className="  hidden md:block w-[0px]  md:w-[340px] h-screen">
          <AdminSidebar />
        </div>
        <div className=" w-full h-screen">
          <div className=" flex flex-col md:flex-row justify-between items-center gap-5 mt-10 px-[30px] ">
            <div class=" w-full p-6 mr-[25px] md:mr-[0px]   bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
              <div className=" flex flex-row  justify-between items-center">
                <a href="#">
                  <h5 class="mb-2 text-[14px] font-bold tracking-normal text-gray-500 uppercase">
                    Scheduled Posts
                  </h5>
                </a>
                <div class="w-12 h-12 bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] rounded-full flex justify-center items-center text-white">
                  <i class="fa-solid fa-clock"></i>
                </div>
              </div>
              <p class="mb-3 text-[25px] font-bold text-gray-700 ">
                {scheduled_post?.length}
              </p>
              <Link
                to="/posts"
                className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] rounded-md hover:scale-90 transition-all hover:transition-all"
              >
                View more
                <svg
                  aria-hidden="true"
                  class="w-4 h-4 ml-2 -mr-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </Link>
            </div>
            <div class=" w-full p-6  mr-[25px] md:mr-[0px]  bg-white border border-gray-200 rounded-lg shadow ">
              <div className=" flex flex-row  justify-between items-center">
                <a href="#">
                  <h5 class="mb-2 text-[14px] font-bold tracking-normal text-gray-500 uppercase">
                    Published Posts
                  </h5>
                </a>
                <div class="w-12 h-12 bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] rounded-full flex justify-center items-center text-white">
                  <i class="fa-solid fa-burst"></i>
                </div>
              </div>
              <p class="mb-3 text-[25px] font-bold text-gray-700 ">
                {published_post?.length}
              </p>
              <Link
                to="/posts"
                className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] rounded-md hover:scale-90 transition-all hover:transition-all"
              >
                View more
                <svg
                  aria-hidden="true"
                  class="w-4 h-4 ml-2 -mr-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </Link>
            </div>
            <div class=" w-full p-6 mr-[25px] md:mr-[0px]  bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
              <div className=" flex flex-row  justify-between items-center">
                <a href="#">
                  <h5 class="mb-2 text-[14px] font-bold tracking-normal text-gray-500 uppercase">
                    Numbers Of Users
                  </h5>
                </a>
                <div class="w-12 h-12 bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] rounded-full flex justify-center items-center text-white">
                  <i class="fa-solid fa-users-line"></i>
                </div>
              </div>
              <p class="mb-3 text-[25px] font-bold text-gray-700 ">
                {users_list?.length}
              </p>
              <Link
                to="/users"
                className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] rounded-md hover:scale-90 transition-all hover:transition-all"
              >
                View more
                <svg
                  aria-hidden="true"
                  class="w-4 h-4 ml-2 -mr-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </Link>
            </div>
          </div>
          <div className="px-[30px] mt-[50px]">
            <h1 className=" text-[23px] font-semibold text-gray-700">
              PURCHASE GRAPH
            </h1>
            <PurchaseDetailsChart />
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;

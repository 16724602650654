import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { uri } from "../../../https";
import axios from "axios";
import { useFormik } from "formik";
import { loginPageSchema, resetPasswordPageSchema } from "../../../validation";
import { ToastContainer, toast } from "react-toastify";

const ResetPassword = () => {
  const history = useNavigate();

  const { id } = useParams();

  console.log("password reset token", id);
  const onSubmit = async (values, actions) => {
    console.log("VALUES", values);
    console.log("ACTIONS", actions);

    const url = `${uri}api/reset-password`;
    const data = {
      token: id,
      email: values.email,
      password: values.password,
    };

    await axios.post(url, data).then(
      (response) => {
        if (response.data.status === 200) {
          actions.resetForm();
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: true,
            theme: "light",
          });
        }
      },
      (error) => {
        console.log(error.response.data);
      }
    );
  };
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: {
      password: "",
      password_confirmation: "",
    },
    validationSchema: resetPasswordPageSchema,
    onSubmit,
  });

  console.log(errors);

  const [passwordShown, setPasswordShown] = useState(false);
  // Password toggle handler
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };
  return (
    <div className=" flex justify-start items-center place-items-start">
      <div className="bg-[#3DC5E8] md:w-full md:h-screen hidden md:block">
        {/* <div className=" m-[80px] mt-[90px]">
          <h1 className=" bg-white text-gray-700 p-1 flex justify-center items-center rounded-full w-[140px] font-semibold">
            SP Analyst
          </h1>
          <div>
            <h1 className=" text-[34px] font-semibold text-gray-800 mt-4">
              Lorem Ipsum is dummy <br /> text by Spanalyst
            </h1>
          </div>
          <div className=" mt-4">
            <p className=" text-gray-800">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              <br />
              industry. Lorem Ipsum has been the industry's standard dummy{" "}
              <br /> text ever since the 1500s,
            </p>
          </div>
        </div> */}
        <img src="https://res.cloudinary.com/dv5jjlsd7/image/upload/v1695720804/SP%20ANALYST/Group_301_efej0c.png" />
      </div>
      <div className=" w-full md:w-[800px] h-screen mt-[50px] md:mt-[0px]">
        <div className=" m-4 p-8">
          <div className=" flex justify-center items-center">
            <img
              src="https://res.cloudinary.com/dv5jjlsd7/image/upload/v1688033750/analyst_logo_efwgjv.png"
              className="relative h-[100px] md:h-[150px]"
            />
          </div>
          <div>
            <h1 className=" text-[20px] md:text-[23px] flex justify-center md:justify-start items-center  md:mt-[50px] font-semibold text-black">
              Reset Password
            </h1>
          </div>
          <form onSubmit={handleSubmit}>
            <div className=" mt-5">
              <div className=" mt-4">
                <label>New Password</label>
                <div className=" mt-2 mb-2">
                  <input
                    type={passwordShown ? "text" : "password"}
                    name="password"
                    id="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className=" w-full py-3 outline-none bg-[#F0F0F0] rounded-md px-3 ring-1  ring-[#5aa7bc] focus:ring-[#015e75]"
                  />
                  {passwordShown ? (
                    <>
                      <i
                        onClick={togglePassword}
                        class="fa-solid fa-eye-slash cursor-pointer text-gray-600 text-[18px] absolute ml-[420px]  md:-ml-[70px] mt-[17px]"
                      ></i>
                    </>
                  ) : (
                    <>
                      <i
                        onClick={togglePassword}
                        class="fa-solid fa-eye cursor-pointer text-gray-600 text-[18px] absolute ml-[420px] md:-ml-[70px] mt-[17px]"
                      ></i>
                    </>
                  )}
                  {errors.password && touched.password && (
                    <span className="text-red-500 text-[16px]">
                      {errors.password}
                    </span>
                  )}
                </div>
                <label>Confirm Password</label>
                <div className=" mt-2">
                  <input
                    type={passwordShown ? "text" : "password"}
                    name="password_confirmation"
                    id="password_confirmation"
                    value={values.password_confirmation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className=" w-full py-3 outline-none bg-[#F0F0F0] rounded-md px-3 ring-1   ring-[#015e75] focus:ring-[#015e75]"
                  />

                  {errors.password_confirmation &&
                    touched.password_confirmation && (
                      <span className="text-red-500 text-[16px]">
                        {errors.password_confirmation}
                      </span>
                    )}
                </div>
              </div>
              {isSubmitting ? (
                <div className=" mt-5">
                  <button className=" w-full py-3  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all md:hover:scale-90">
                    <svg
                      aria-hidden="true"
                      class="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  </button>
                </div>
              ) : (
                <div className=" mt-5">
                  <button
                    type="submit"
                    className="w-full py-3  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all md:hover:scale-90"
                  >
                    Submit
                  </button>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
    </div>
  );
};

export default ResetPassword;

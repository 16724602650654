import React, { Fragment, useEffect, useState } from "react";
import { uri } from "../../../https";
import axios from "axios";
import { Link, NavLink } from "react-router-dom";

const SideMenuMobile = () => {
  const [mobile_sidebar_controller, setMobileSideBarController] =
    useState(false);

  const [metaPageDetails, setMetaPageDetails] = useState([]);
  const FetchMetaPages = async () => {
    const url = `${uri}api/get_meta_pagedetails`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        setMetaPageDetails(response?.data?.meta_pagedetails);
        console.log("meta page details", response?.data?.meta_pagedetails);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    FetchMetaPages();
  }, []);
  return (
    <div>
      <div
        className={`w-full ${
          !mobile_sidebar_controller
            ? " w-full overflow-auto scrollbar-hide "
            : " w-full overflow-auto scrollbar-hide "
        } bg-[#fcfcfc] absolute h-[100px] border-1 border-r-gray-200 duration-300 block md:hidden`}
      >
        <div
          className={` flex flex-row items-center justify-between mobile_sidebar_scrollbar ${
            !mobile_sidebar_controller ? " block w-full" : " hidden  w-full"
          } `}
        >
          <NavLink to="/channels/connect" activeClassName="active">
            <li className=" w-full flex justify-start items-center gap-5   outline-gray-200 hover:bg-[#0180a1]/10    transition-all hover:transition-all p-2 rounded-md mb-4 mt-3 cursor-pointer">
              <div className="    flex justify-center items-center  w-full ">
                <i className="fa-solid fa-plus text-[20px] text-gray-500 w-full  "></i>
              </div>
              <p className="text-[15px] font-semibold w-full">Add Channels</p>
            </li>
          </NavLink>

          {metaPageDetails?.map((metapages, index) => {
            return (
              <Fragment key={index}>
                {metapages.status == "active" && (
                  <Link to={`/agenda/${metapages?.page_id}`}>
                    <li className=" flex justify-start items-center  outline-gray-200 hover:bg-[#0180a1]/10 w-full   transition-all hover:transition-all p-2 rounded-md mb-4 mt-3 cursor-pointer">
                      <div className=" h-[50px] w-[50px]     flex justify-center items-center  ">
                        <div
                          className={
                            (metapages?.platform === "facebook" &&
                              " flex justify-center gap-5 items-center text-white uppercase text-[18px] font-semibold h-[40px] w-[40px]  rounded-full cursor-pointer ") ||
                            (metapages?.platform === "instagram" &&
                              "  flex justify-center items-center text-white uppercase text-[18px] font-semibold h-[40px] w-[40px]  rounded-full cursor-pointer") ||
                            (metapages?.platform === "linkedin" &&
                              "   flex justify-center items-center text-white uppercase text-[18px] font-semibold h-[40px] w-[40px]  rounded-full cursor-pointer") ||
                            (metapages?.platform === "pinterest" &&
                              "   flex justify-center gap-5 items-center text-white uppercase text-[18px] font-semibold h-[40px] w-[40px]  rounded-full cursor-pointer") ||
                            (metapages?.platform === "tumblr" &&
                              "   flex justify-center items-center text-white uppercase text-[18px] font-semibold h-[40px] w-[40px]  rounded-full cursor-pointer")
                          }
                        >
                          <p>
                            {metapages?.platform == "facebook" ? (
                              <>
                                <i className=" fa-brands fa-facebook text-[25px] text-[#3b5998]  "></i>
                              </>
                            ) : metapages?.platform == "instagram" ? (
                              <>
                                <i className=" fa-brands fa-square-instagram  text-[25px] text-[#d62976] "></i>
                              </>
                            ) : metapages?.platform == "linkedin" ? (
                              <>
                                <i className="  fa-brands fa-linkedin text-[25px] text-[#0A66C2] "></i>
                              </>
                            ) : metapages?.platform == "pinterest" ? (
                              <>
                                <i className="  fa-brands fa-pinterest text-[25px] text-[#E60023] "></i>
                              </>
                            ) : metapages?.platform == "tumblr" ? (
                              <>
                                <i className=" fa-brands fa-square-tumblr text-[25px] text-[#34526f] "></i>
                              </>
                            ) : (
                              <></>
                            )}
                          </p>
                          {/* <span className=" absolute mt-[40px] ml-14">
                            <i
                              className={
                                (metapages?.platform === "facebook" &&
                                  "  fa-brands fa-facebook text-[22px] text-[#3b5998] ") ||
                                (metapages?.platform === "instagram" &&
                                  "   fa-brands fa-square-instagram  text-[22px] text-[#d62976]") ||
                                (metapages?.platform === "linkedin" &&
                                  " fa-brands fa-linkedin text-[22px] text-[#0A66C2]  ") ||
                                (metapages?.platform === "pinterest" &&
                                  " fa-brands fa-pinterest text-[22px] text-[#E60023]  ") ||
                                (metapages?.platform === "tumblr" &&
                                  " fa-brands fa-square-tumblr text-[22px] text-[#34526f]  ")
                              }
                            ></i>
                          </span> */}
                        </div>
                      </div>

                      <li
                        className={` text-[14px] font-semibold capitalize ${
                          !mobile_sidebar_controller ? "block" : "hidden"
                        }`}
                      >
                        {metapages?.page_name}
                      </li>
                    </li>
                  </Link>
                )}
              </Fragment>
            );
          })}
        </div>
      </div>
      {/* <div
        onClick={() => setMobileSideBarController(!mobile_sidebar_controller)}
        className={` h-[30px] w-[30px]    relative  block md:hidden     ${
          !mobile_sidebar_controller ? " " : ""
        }   bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] border-1 border-white text-white rounded-full flex justify-center items-center cursor-pointer mt-[30px] duration-300`}
      >
        <i
          class={` ${
            !mobile_sidebar_controller
              ? "fa-solid fa-arrow-left"
              : "fa-solid fa-arrow-left rotate-180 "
          } duration-300 mobile_sidebar_handle overflow-hidden   `}
        ></i>
      </div> */}
    </div>
  );
};

export default SideMenuMobile;

import React from 'react'

const Footer = () => {
  return (
    <footer class="bg-[#F5F5F5] dark:bg-gray-900 mt-[50px]">
      <div class="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div class="md:flex md:justify-start gap-[50px]">
          <div class="mb-6 md:mb-0 flex flex-col justify-center items-center">
            <a href="https://spanalyst.com/" class="flex items-center">
              <img
                src="https://res.cloudinary.com/dv5jjlsd7/image/upload/v1688033750/analyst_logo_efwgjv.png"
                class="h-[100px] object-contain mr-3"
                alt="SPAnalyst Logo "
              />
            </a>
            <span class="text-sm text-gray-800 sm:text-center ">
              © 2023{" "}
              <a href="https://spanalyst.com/" class="hover:underline">
                SPAnalyst
              </a>
              <br />
              All Rights Reserved.
            </span>
          </div>
          <div class="grid grid-cols-1 place-items-center md:place-items-start  md:gap-[120px] md:grid-cols-4">
            <div className=" flex flex-col justify-center items-center md:justify-start md:items-start">
              <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Contact
              </h2>
              <ul class="text-gray-500 dark:text-gray-400 font-medium">
                <li class="mb-4">
                  <a href="#" class="hover:underline">
                    Link 1
                  </a>
                </li>
                <li className=" mb-4">
                  <a href="#" class="hover:underline">
                    Link 2
                  </a>
                </li>
              </ul>
            </div>
            <div className=" flex flex-col justify-center items-center md:justify-start md:items-start mt-[20px] md:mt-[0px]">
              <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Information
              </h2>
              <ul class="text-gray-500 dark:text-gray-400 font-medium">
                <li class="mb-4">
                  <a href="#" class="hover:underline">
                    Link 1
                  </a>
                </li>
                <li className=" mb-4">
                  <a href="#" class="hover:underline">
                    Link 2
                  </a>
                </li>
              </ul>
            </div>
            <div className=" flex flex-col justify-center items-center md:justify-start md:items-start mt-[20px] md:mt-[0px]">
              <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Legal
              </h2>
              <ul class="text-gray-500 dark:text-gray-400 font-medium">
                <li class="mb-4">
                  <a href="#" class="hover:underline">
                    Link 1
                  </a>
                </li>
                <li className=" mb-4">
                  <a href="#" class="hover:underline">
                    Link 2
                  </a>
                </li>
              </ul>
            </div>
            <div className=" flex flex-col justify-center items-center md:justify-start md:items-start mt-[20px] md:mt-[0px]">
              <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Support
              </h2>
              <ul class="text-gray-500 dark:text-gray-400 font-medium">
                <li class="mb-4">
                  <a href="#" class="hover:underline">
                    Link 1
                  </a>
                </li>
                <li className=" mb-4">
                  <a href="#" class="hover:underline">
                    Link 2
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <div class=" flex justify-end items-center">
          <div class="flex mt-4 space-x-5 sm:justify-center sm:mt-0">
            <a href="#" className="text-gray-500 hover:text-gray-900 ">
              <i className="fa-brands fa-facebook text-[20px]"></i>
            </a>

            <a href="#" className="text-gray-500 hover:text-gray-900 ">
              <i className="fa-brands fa-instagram  text-[20px]"></i>
            </a>
            <a href="#" className="text-gray-500 hover:text-gray-900 ">
              <i className="fa-brands fa-x-twitter text-[20px]"></i>
            </a>
            <a href="#" className="text-gray-500 hover:text-gray-900 ">
              <i className="fa-brands fa-threads text-[20px]"></i>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer
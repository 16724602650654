import React, { Fragment } from "react";
import TopBar from "../SideMenu/TopBar";
import Footer from "../../Footer/Footer";
import Navbar from "../../Navbar/Navbar";

const PrivacyPolicy = () => {
  return (
    <>
      <Fragment>
        <Navbar />

        <div className=" flex justify-start items-start">
          <div className=" w-full  h-full px-[10px] md:px-[0px]">
            <div className=" w-full h-[400px] bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white flex justify-center items-center ">
              <div className=" flex flex-col justify-center items-center">
                <h1 className=" text-[40px] md:text-[70px] font-semibold">
                  Privacy Policy
                </h1>
                {/* <h1 className=" text-[20px] md:text-[30px] font-semibold">
                V1.0 Internal Beta
              </h1> */}
                <div className=" flex flex-row  gap-[50px] mt-[10px]">
                  <div>
                    <i className="fa-brands fa-facebook text-[25px] cursor-pointer"></i>
                  </div>
                  <div>
                    <i class="fa-brands fa-instagram text-[25px] cursor-pointer"></i>
                  </div>
                  <div>
                    <i class="fa-brands fa-x-twitter text-[25px] cursor-pointer"></i>
                  </div>
                  <div>
                    <i class="fa-brands fa-pinterest text-[25px] cursor-pointer"></i>
                  </div>
                  <div>
                    <i class="fa-brands fa-square-tumblr text-[25px] cursor-pointer"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" flex justify-start items-start ">
          <div className=" w-full  bg-gray-100/10">
            <div className=" container    px-[300px] text-justify">
              <div className=" flex flex-col  justify-start items-start gap-y-3  mt-4">
                <p className="text-gray-600 text-[16px] font-semibold">
                  Effective Date: 19-06-2023
                </p>
                <p className="text-gray-600 text-[16px] mt-3">
                  This Privacy Policy governs the manner in which SP Analyst
                  ("we," "us," or "our") collects, uses, maintains, and
                  discloses information collected from users (referred to as
                  "you" or "your") of the SP Analyst web application worldwide
                  (referred to as the "Service") This Privacy Policy applies to
                  all users of the Service.
                </p>
                <p className="text-gray-600 text-[16px] font-semibold">
                  Information We Collect
                </p>
                <p className="text-gray-600 text-[16px]">
                  1.1 Personal Information We may collect personal information
                  from you in connection with your use of the Service. This
                  personal information may include, but is not limited to:
                </p>
                <p className="text-gray-600 text-[16px]">Your name</p>
                <p className="text-gray-600 text-[16px]">Email address</p>
                <p className="text-gray-600 text-[16px]">Username</p>
                <p className="text-gray-600 text-[16px]">Profile picture</p>
                <p className="text-gray-600 text-[16px]">
                  1.2 Usage Information We may also collect non-personal
                  information about how you interact with and use the Service.
                  This may include, but is not limited to:
                </p>
                <p className="text-gray-600 text-[16px]">Log files</p>
                <p className="text-gray-600 text-[16px]">Device information</p>
                <p className="text-gray-600 text-[16px]">IP address</p>
                <p className="text-gray-600 text-[16px]">Browser type</p>
                <p className="text-gray-600 text-[16px]">
                  Referring/exit pages
                </p>
                <p className="text-gray-600 text-[16px]">Clickstream data</p>
                <p className="text-gray-600 text-[16px]">
                  Time and date stamps
                </p>
                <p className="text-gray-600 text-[16px] font-semibold">
                  How We Use Collected Information
                </p>
                <p className="text-gray-600 text-[16px]">
                  2.1 Personal Information We may use the personal information
                  collected from you for the following purposes:
                </p>
                <p className="text-gray-600 text-[16px]">
                  To provide and improve our Service
                </p>
                <p className="text-gray-600 text-[16px]">
                  To personalize your experience with the Service
                </p>
                <p className="text-gray-600 text-[16px]">
                  To send periodic emails regarding updates, news, or related
                  information
                </p>
                <p className="text-gray-600 text-[16px]">
                  2.2 Usage Information The non-personal information collected
                  is used to analyse trends, administer the Service, track user
                  movements, and gather demographic information for aggregate
                  use. This information helps us understand how users interact
                  with the Service and allows us to improve its functionality
                  and performance.
                </p>
                <p className="text-gray-600 text-[16px] font-semibold ">
                  How We Protect Your Information
                </p>
                <p className="text-gray-600 text-[16px]">
                  We take reasonable security measures to protect your
                  information from unauthorized access, alteration, disclosure,
                  or destruction. However, please be aware that no method of
                  transmission over the Internet or electronic storage is 100%
                  secure. Therefore, we cannot guarantee the absolute security
                  of your information.
                </p>
                <p className="text-gray-600 text-[16px] font-semibold">
                  Disclosure of Information
                </p>
                <p className="text-gray-600 text-[16px]">
                  We may disclose your information to third parties under the
                  following circumstances:
                </p>
                <p className="text-gray-600 text-[16px]">
                  When required by law or in response to a valid legal request
                </p>
                <p className="text-gray-600 text-[16px]">
                  To protect our rights or the rights of others
                </p>
                <p className="text-gray-600 text-[16px]">
                  To investigate fraud or respond to a government request
                </p>
                <p className="text-gray-600 text-[16px]">With your consent</p>
                <p className="text-gray-600 text-[16px]">
                  Third-Party Services
                </p>
                <p className="text-gray-600 text-[16px]">
                  The Service may include links to third-party websites,
                  services, or advertisements. These third-party sites have
                  their own privacy policies, which we do not control. We
                  encourage you to review the privacy policies of any
                  third-party sites or services before providing any personal
                  information.
                </p>
                <p className="text-gray-600 text-[16px] font-semibold">
                  International Data Transfer
                </p>
                <p className="text-gray-600 text-[16px]">
                  By using the Service, you understand and agree that your
                  information may be transferred to and stored on servers
                  located in countries different from your own. These countries
                  may have different data protection laws than your country of
                  residence.
                </p>
                <p className="text-gray-600 text-[16px] font-semibold">
                  Children's Privacy
                </p>
                <p className="text-gray-600 text-[16px]">
                  The Service is not intended for use by individuals under the
                  age of 13. We do not knowingly collect personal information
                  from children under 13. If you believe we may have collected
                  information from a child under 13, please contact us
                  immediately at [info@sparklerpro.com], and we will promptly
                  delete the information.
                </p>
                <p className="text-gray-600 text-[16px] font-semibold">
                  Changes to this Privacy Policy
                </p>
                <p className="text-gray-600 text-[16px]">
                  We reserve the right to update or modify this Privacy Policy
                  at any time. We will notify you of any changes by posting the
                  updated policy on the Service. It is your responsibility to
                  review this Privacy Policy periodically for any updates or
                  changes.
                </p>
                <p className="text-gray-600 text-[16px] font-semibold">
                  Contact Us
                </p>
                <p className="text-gray-600 text-[16px]">
                  If you have any questions or concerns regarding this Privacy
                  Policy or our privacy practices, please contact us at
                  [info@sparklerpro.com].
                </p>
                <p className="text-gray-600 text-[16px]">
                  By using the Service, you acknowledge that you have read,
                  understood, and agreed to the terms of this Privacy Policy.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;

import React from 'react';
import {createRoot} from 'react-dom/client'
import store from './store'
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import { ContextProvider } from './contexts/ContextProvider';

const container = document.getElementById('root')

const root = createRoot(container)

root.render(
  <Provider store={store}>
    <ContextProvider>
      <App />
    </ContextProvider>
  </Provider>,
);

import React, { Fragment, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import SmallCalender from "../Calender/SmallCalender";
import Sidebar from "../Calender/Sidebar";
import axios from "axios";
import { app_version, uri, year } from "../../../https";
import { Player } from "@lottiefiles/react-lottie-player";

const SideMenu = () => {
  const [sidebar_controller, setSideBarController] = useState(false);
  const [loading,setLoading] = useState(false)

  const [metaPageDetails, setMetaPageDetails] = useState([]);
  const FetchMetaPages = async () => {
    const url = `${uri}api/get_meta_pagedetails`;
    setLoading(true)
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        setMetaPageDetails(response?.data?.meta_pagedetails);
        console.log("meta page details", response?.data?.meta_pagedetails);
        setLoading(false);
      },
      (error) => {
        console.log(error);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    FetchMetaPages();
  }, []);
  return (
    <>
      {/* w-[400px] */}
      <div
        className={` ${
          !sidebar_controller
            ? " w-[400px] hidden md:block"
            : " w-[100px] hidden md:block "
        } bg-[#fcfcfc]  h-full border-1 border-r-gray-200 duration-300`}
      >
        <div className=" p-5  h-screen">
          {/* <div
            onClick={() => setSideBarController(!sidebar_controller)}
            className={` h-[30px] w-[30px] absolute ${
              !sidebar_controller
                ? " left-[220px] md:left-[300px]"
                : " left-[80px] md:left-[80px] "
            }   bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] border-1 border-white text-white rounded-full flex justify-center items-center cursor-pointer mt-[30px] duration-300`}
          >
            <i
              class={` ${
                !sidebar_controller
                  ? "fa-solid fa-arrow-left"
                  : "fa-solid fa-arrow-left rotate-180 "
              } duration-300 `}
            ></i>
          </div> */}
          <ul>
            <NavLink
              activeClassName="active"
              className=" flex justify-start items-center gap-5 bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] p-4 rounded-md mb-4 cursor-pointer "
            >
              <i className="fa-regular fa-calendar text-[20px]"></i>
              <li
                className={` text-[15px] font-semibold ${
                  !sidebar_controller ? "block" : "hidden"
                }`}
              >
                Calendar
              </li>
            </NavLink>
            {/* <li className=" flex justify-start items-center gap-5  hover:bg-gray-200 transition-all hover:transition-all  p-4 rounded-md mb-4 cursor-pointer">
              <i className="fa-solid fa-arrow-trend-up text-[20px]"></i>
              <li className=" text-[15px] font-semibold">Campaigns</li>
            </li> */}
            <hr />
            {/* <li className=" flex justify-start items-center gap-5 outline outline-1  outline-gray-200 hover:bg-[#0180a1]/10 transition-all hover:transition-all  p-2 rounded-md mb-4 mt-3 cursor-pointer">
              <div className=" h-[40px] w-[40px] rounded-full border-1 border-gray-300 flex justify-center items-center">
                <i className="fa-solid fa-plus text-[20px] text-gray-500"></i>
              </div>
              <li
                className={` text-[15px] font-semibold ${
                  !sidebar_controller ? "block" : "hidden"
                } `}
              >
                <Sidebar />
              </li>
            </li> */}
            <NavLink to="/channels/connect" activeClassName="active">
              <li className=" flex justify-start items-center gap-5 outline outline-1  outline-gray-200 hover:bg-[#0180a1]/10    transition-all hover:transition-all p-2 rounded-md mb-4 mt-3 cursor-pointer">
                <div className=" h-[40px] w-[40px] rounded-full border-1 border-gray-300   flex justify-center items-center ">
                  <i className="fa-solid fa-plus text-[20px] text-gray-500 "></i>
                </div>
                <li
                  className={`text-[15px] font-semibold ${
                    !sidebar_controller ? "block" : "hidden"
                  }`}
                >
                  Add Channels
                </li>
              </li>
            </NavLink>
            {loading ? (
              <>
                <div className=" h-screen w-full fixed left-0 bg-white top-[0px] flex justify-center items-center  backdrop-blur-sm">
                  <div className=" flex flex-col justify-center items-center">
                    <Player
                      src="https://lottie.host/1eb4f4a4-706d-498d-bf89-192423f0fd49/DOmyAJcAln.json"
                      className="player"
                      loop
                      autoplay
                      className="h-[200px]"
                    />
                    <p className=" text-[25px] text-gray-800 font-semibold">
                      Please wait...
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className=" h-max">
                  {metaPageDetails?.map((metapages, index) => {
                    return (
                      <Fragment key={index}>
                        {metapages.status == "active" && (
                          <Link to={`/agenda/${metapages?.page_id}`}>
                            <li className=" flex justify-start items-center gap-5 outline outline-1  outline-gray-200 hover:bg-[#0180a1]/10    transition-all hover:transition-all p-2 rounded-md mb-4 mt-3 cursor-pointer">
                              <div className=" h-[50px] w-[50px] rounded-full border-1 border-gray-300   flex justify-center items-center ">
                                <div
                                  className={
                                    (metapages?.platform === "facebook" &&
                                      " bg-[#3b5998] flex justify-center items-center text-white uppercase text-[18px] font-semibold h-[40px] w-[40px]  rounded-full cursor-pointer ") ||
                                    (metapages?.platform === "instagram" &&
                                      " bg-[#d62976]  flex justify-center items-center text-white uppercase text-[18px] font-semibold h-[40px] w-[40px]  rounded-full cursor-pointer") ||
                                    (metapages?.platform === "linkedin" &&
                                      " bg-[#0A66C2]  flex justify-center items-center text-white uppercase text-[18px] font-semibold h-[40px] w-[40px]  rounded-full cursor-pointer") ||
                                    (metapages?.platform === "pinterest" &&
                                      " bg-[#E60023]  flex justify-center items-center text-white uppercase text-[18px] font-semibold h-[40px] w-[40px]  rounded-full cursor-pointer") ||
                                    (metapages?.platform === "tumblr" &&
                                      " bg-[#34526f]  flex justify-center items-center text-white uppercase text-[18px] font-semibold h-[40px] w-[40px]  rounded-full cursor-pointer")
                                  }
                                >
                                  {metapages?.page_name?.slice(0, 1)}
                                  <span className=" absolute mt-[40px] ml-14">
                                    <i
                                      className={
                                        (metapages?.platform === "facebook" &&
                                          "  fa-brands fa-facebook text-[22px] text-[#3b5998] ") ||
                                        (metapages?.platform === "instagram" &&
                                          "   fa-brands fa-square-instagram  text-[22px] text-[#d62976]") ||
                                        (metapages?.platform === "linkedin" &&
                                          " fa-brands fa-linkedin text-[22px] text-[#0A66C2]  ") ||
                                        (metapages?.platform === "pinterest" &&
                                          " fa-brands fa-pinterest text-[22px] text-[#E60023]  ") ||
                                        (metapages?.platform === "tumblr" &&
                                          " fa-brands fa-square-tumblr text-[22px] text-[#34526f]  ")
                                      }
                                    ></i>
                                  </span>
                                </div>
                              </div>
                              <li
                                className={` text-[15px] font-semibold capitalize ${
                                  !sidebar_controller ? "block" : "hidden"
                                }`}
                              >
                                {metapages?.page_name}
                              </li>
                            </li>
                          </Link>
                        )}
                      </Fragment>
                    );
                  })}
                </div>
              </>
            )}
          </ul>
          {/* <SmallCalender /> */}
        </div>
        {!sidebar_controller ? (
          <>
            
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default SideMenu;

import React, { Fragment, useContext, useEffect, useState } from "react";
import GlobalContext from "../../../context/GlobalContext";
import axios from "axios";
import { uri } from "../../../https";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const FacebookPageAccessModal = ({ facebook_page_access_token }) => {
  const [loading, setLoading] = useState(false);
  console.log("FACEBOOK PAGES ACCESSED", facebook_page_access_token);
  const {
    setDaysSelected,
    setShowEventModal,
    savedEvents,
    setSelectedEvent,
    setShowSelectedEventModal,
    daysSelected,
    dispatchCalEvent,
    selectedEvent,
    setShowSelectedFacebookModal,
    setShowSelectedInstagramModal,
    showFacebookPageModal,

    setShowFacebookPageModal,
    Channel_Interaction_ModalFacebook,
    setChannelInteractionModalFacebook,
    Channel_Interaction_ModalInstagram,
    setChannelInteractionModalInstagram,
    Channel_Interaction_ModalPinterest,
    setChannelInteractionModalPinterest,
    Channel_Interaction_ModalLinkedIn,
    setChannelInteractionModalLinkedin,
    Channel_Interaction_ModalTumblr,
    setChannelInteractionModalTumblr,
  } = useContext(GlobalContext);

  const [facebook_page_data, setFacebookPageData] = useState("");
  const [submittedTokenMessage, setSubmittedTokenMessage] = useState("");
  const StoreFaceBookPage = (pages) => {
    setFacebookPageData(pages);
    console.log("Page Submitted", pages);
    setLoading(true);
    const url = `${uri}api/store_facebook_access_token`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    const data = {
      page_name: pages.name,
      access_token: pages.access_token,
      page_category: pages.category,
      page_id: pages.id,
      platform: "facebook",
    };
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    axios.post(url, data, config).then(
      (response) => {
        console.log(response.data);
        if (response.data.status == 200) {
          setSubmittedTokenMessage(response.data.message);
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setLoading(false);
        }
        if (response.data.status == 400) {
          setSubmittedTokenMessage(response.data.message);
          toast.error(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setLoading(false);
        }
        // setShowFacebookPageModal(false);
      },
      (error) => {
        setLoading(false);
        console.log(error);
      }
    );
  };

  const [facebookpageid, setFacebookPageId] = useState([]);
  const FetchFacebookPageLinked = async () => {
    const url = `${uri}api/get_facebook_pageid`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        setFacebookPageId(response?.data?.facebook_page_id);
        console.log("Page id from db", response.data.facebook_page_id.page_id);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    FetchFacebookPageLinked();
  }, [submittedTokenMessage]);
  setChannelInteractionModalFacebook(false);
  return (
    <div className=" h-full w-full bg-black/25  absolute left-0   flex justify-center items-center">
      <div className=" w-full md:w-[600px] shadow-lg bg-white  relative -top-[120px] rounded-md p-[20px] md:p-[0px] subscription_modal">
        <header className="bg-white px-4 py-2 flex justify-between items-center rounded-md">
          <h1 className=" text-gray-700 font-semibold">
            Connect your facebook page
          </h1>
          <div className=" gap-3">
            <button onClick={() => setShowFacebookPageModal(false)}>
              <span className="material-icons-outlined text-gray-400">
                close
              </span>
            </button>
          </div>
        </header>
        <div></div>
        <div className=" w-full bg-[#f5f5f5] h-full ">
          <div className=" p-3 ">
            <p className="text-gray-700 font-semibold">
              Your can link single page at a time.
            </p>
          </div>
          {facebook_page_access_token.map((pages, index) => {
            return (
              <Fragment key={index}>
                <div className=" flex justify-between items-center gap-3 p-4">
                  <div>
                    <h1 className=" font-semibold text-gray-700 text-[15px]">
                      {pages.name}
                    </h1>
                  </div>
                  {/* <div>
                    <h1 className=" font-semibold text-gray-700 text-[15px]">
                      {pages.id}
                    </h1>
                  </div> */}
                  <div>
                    {loading ? (
                      <>
                        {facebook_page_data == pages && (
                          <button className=" px-6 py-2  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] rounded-md shadow-md">
                            <svg
                              aria-hidden="true"
                              class="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                          </button>
                        )}
                      </>
                    ) : (
                      <>
                        {facebookpageid.find((x) => x?.page_id == pages.id) ? (
                          <>
                            <button className=" px-6 py-2  bg-gray-500 rounded-md shadow-md  cursor-not-allowed">
                              <i className="fa-solid fa-link text-[20px] text-white"></i>
                            </button>
                          </>
                        ) : (
                          <button
                            onClick={() => StoreFaceBookPage(pages)}
                            className=" px-6 py-2  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] rounded-md shadow-md"
                          >
                            <i className="fa-solid fa-link text-[20px] text-white"></i>
                          </button>
                        )}
                        {/* <button
                          onClick={() => StoreFaceBookPage(pages)}
                          className=" px-6 py-2  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] rounded-md shadow-md"
                        >
                          <i className="fa-solid fa-link text-[20px] text-white"></i>
                        </button> */}
                      </>
                    )}
                    {/* {facebookpageid.find((x) => x?.page_id == pages.id) ? (
                      <button className=" px-6 py-2  bg-red-500 rounded-md shadow-md ">
                        <i class="fa-solid fa-link-slash text-[20px] text-white"></i>
                      </button>
                    ) : (
                      <>
                        {facebookpageid.find((x) => x?.page_id !== pages.id) ? (
                          <button className=" px-6 py-2  bg-gray-500 rounded-md shadow-md  cursor-not-allowed">
                            <i className="fa-solid fa-link text-[20px] text-white"></i>
                          </button>
                        ) : (
                          <button
                            onClick={() => StoreFaceBookPage(pages)}
                            className=" px-6 py-2  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] rounded-md shadow-md"
                          >
                            <i className="fa-solid fa-link text-[20px] text-white"></i>
                          </button>
                        )}
                      </>
                    )} */}
                  </div>
                </div>
              </Fragment>
            );
          })}
        </div>
        <footer className=" flex justify-end  border-t  p-3 gap-3">
          <button
            onClick={() => setShowFacebookPageModal(false)}
            className="  px-3 py-2 rounded text-gray-600 font-semibold hover:text-gray-800 transition-all hover:transition-all"
          >
            Cancel
          </button>
          {/* <button className="  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] hover:scale-90 transition-all hover:transition-all px-3 py-2 rounded text-white">
            Authenticate with Facebook
          </button> */}
        </footer>{" "}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
    </div>
  );
};

export default FacebookPageAccessModal;

import React, { createContext } from "react";

const GlobalContext = createContext({
  monthIndex: 0,
  setmonthIndex: (index) => {},
  showEventModal: false,
  showSelectedEventModal: false,
  showSelectedFacebookModal: false,
  showSelectedInstagramModal: false,
  showSelectedLinkedInModal: false,
  showFacebookPageModal: false,
  isBackwardDateSelected: false,
  editcontentModal: false,
  showSelectedPintrestModal: false,
  showSelectedTumblrModal: false,
  showSelectedPinterestBoardModal: false,
  editpublishedcontentModal: false,
  showShareLocation: false,
  showSubscriptionModal: false,
  showSubscribeModal: false,
  showPromoCodeModal: false,
  connectChannelModal: false,
  Channel_Interaction_ModalFacebook: false,
  Channel_Interaction_ModalInstagram: false,
  Channel_Interaction_ModalPinterest: false,
  Channel_Interaction_ModalLinkedIn: false,
  Channel_Interaction_ModalTumblr: false,
  loading: false,
  trigger_event: "",
  smallCalendarMonth: 0,
  setSmallCalendarMonth: (index) => {},
  daysSelected: null,
  setDaysSelected: (day) => {},
  setShowEventModal: () => {},
  setShowSelectedEventModal: () => {},
  dispatchCalEvent: ({ type, payload }) => {},
  savedEvents: [],
  selectedEvent: null,
  setSelectedEvent: () => {},
  setShowSelectedFacebookModal: () => {},
  setShowSelectedInstagramModal: () => {},
  setShowSelectedLinkedInModal: () => {},
  setShowFacebookPageModal: () => {},
  setIsBackwardDateSelected: () => {},
  setEditContentModal: () => {},
  setShowSelectedPintrestModal: () => {},
  setShowSelectedTumblrModal: () => {},
  setShowSelectedPinterestBoardModal: () => {},
  setEditPublishedContentModal: () => {},
  setTriggerEvent: () => {},
  setShowShareLocation: () => {},
  setShowSubscriptionModal: () => {},
  setShowSubscribeModal: () => {},
  setShowPromoCodeModal: () => {},
  setConectChannelModal: () => {},
  setLoading: () => {},
  setChannelInteractionModalFacebook: () => {},
  setChannelInteractionModalInstagram: () => {},
  setChannelInteractionModalPinterest: () => {},
  setChannelInteractionModalLinkedin: () => {},
  setChannelInteractionModalTumblr: () => {},
});

export default GlobalContext;

import React, { useEffect, useState } from "react";
import AdminSidebar from "./AdminSidebar";
import { uri } from "../../https";
import axios from "axios";
import AdminTopBar from "../pages/SideMenu/AdminTopBar";

const Users = () => {
  const [users_list, setUsersList] = useState([]);
  const GetUsersList = async () => {
    const url = `${uri}api/get_userslist_admin`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        if (response.data.status == 200) {
          setUsersList(response.data.user_info);
        }
        if (response.data.status == 400) {
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    GetUsersList();
  }, []);

  return (
    <>
      <AdminTopBar />
      <div className=" flex flex-row">
        <div className="  hidden md:block w-[0px]  md:w-[340px] h-screen">
          <AdminSidebar />
        </div>
        <div className=" w-full h-screen">
          <div class="relative overflow-x-auto mt-[50px] px-[20px]">
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-white uppercase bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] ">
                <tr>
                  <th scope="col" class="px-6 py-4">
                    Client Id
                  </th>
                  <th scope="col" class="px-6 py-4">
                    Username
                  </th>
                  <th scope="col" class="px-6 py-4">
                    Phone
                  </th>
                  <th scope="col" class="px-6 py-4">
                    Address
                  </th>
                  {/* <th scope="col" class="px-6 py-4">
                  Package Active
                </th> */}
                </tr>
              </thead>
              <tbody>
                {users_list?.map((users, index) => {
                  console.log("Users List", users);
                  return (
                    <tr
                      class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                      key={index}
                    >
                      <th
                        scope="row"
                        class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {users.client_id}
                      </th>
                      <th
                        scope="row"
                        class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {users.username}
                      </th>
                      <th
                        scope="row"
                        class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {users.mobile_no}
                      </th>
                      <th
                        scope="row"
                        class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {users.address_line_1}, {users.address_line_2}
                      </th>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Users;

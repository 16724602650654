import React, { Fragment } from "react";
import Footer from "../../Footer/Footer";
import Navbar from "../../Navbar/Navbar";

const TermsAndServices = () => {
  return (
    <>
      <Fragment>
        <Navbar />

        <div className=" flex justify-start items-start">
          <div className=" w-full  h-full px-[10px] md:px-[0px]">
            <div className=" w-full h-[400px] bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white flex justify-center items-center ">
              <div className=" flex flex-col justify-center items-center">
                <h1 className=" text-[40px] md:text-[70px] font-semibold">
                  Terms and Services
                </h1>
                {/* <h1 className=" text-[20px] md:text-[30px] font-semibold">
                V1.0 Internal Beta
              </h1> */}
                <div className=" flex flex-row  gap-[50px] mt-[10px]">
                  <div>
                    <i className="fa-brands fa-facebook text-[25px] cursor-pointer"></i>
                  </div>
                  <div>
                    <i class="fa-brands fa-instagram text-[25px] cursor-pointer"></i>
                  </div>
                  <div>
                    <i class="fa-brands fa-x-twitter text-[25px] cursor-pointer"></i>
                  </div>
                  <div>
                    <i class="fa-brands fa-pinterest text-[25px] cursor-pointer"></i>
                  </div>
                  <div>
                    <i class="fa-brands fa-square-tumblr text-[25px] cursor-pointer"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" flex justify-start items-start ">
          <div className=" w-full  bg-gray-100/10">
            <div className=" container    px-[300px] text-justify">
              <div className=" flex flex-col  justify-start items-start gap-y-3  mt-4">
                <p className="text-gray-600 text-[16px] font-semibold">
                  Effective Date: 05-02-2024
                </p>
                <p className="text-gray-600 text-[16px] mt-3">
                  Welcome to SP Analyst, a platform that allows users to
                  schedule and manage posts on various social media platforms
                  using their respective Application Programming Interfaces
                  (APIs). These Terms of Service (the "Terms") govern your use
                  of the SP Analyst website and services (the "Service"). By
                  using our Service, you agree to comply with and be bound by
                  these terms.
                </p>
                <p className="text-gray-600 text-[16px] mt-3">
                  Acceptance of Terms By accessing and using the Service, you
                  acknowledge that you have read and understood, and agree to be
                  bound by these Terms and our Privacy Policy. If you are using
                  the Service on behalf of an organization, you are agreeing to
                  these Terms on behalf of that organization, and you represent
                  and warrant that you have the authority to bind the
                  organization to these Terms.
                </p>
                <p className="text-gray-600 text-[16px] font-semibold">
                  Information We Collect
                </p>
                <p className="text-gray-600 text-[16px] mt-3">
                  a. User Account: To use the Service, you must create an
                  account and provide accurate and complete information. You are
                  responsible for maintaining the confidentiality of your
                  account credentials, and you are solely responsible for any
                  activity that occurs under your account.
                </p>
                <p className="text-gray-600 text-[16px] mt-3">
                  b. API Usage: You understand and agree that the Service relies
                  on the APIs provided by social media platforms. Your use of
                  these APIs is subject to the terms and conditions of the
                  respective social media platforms, and you are solely
                  responsible for compliance with their terms and policies.
                </p>
                <p className="text-gray-600 text-[16px]">
                  Prohibited Activities You agree not to:
                </p>
                <p className="text-gray-600 text-[16px]">
                  a. Use the Service for any illegal or unauthorized purpose
                </p>
                <p className="text-gray-600 text-[16px]">
                  b. Violate any laws, regulations, or social media platform
                  policies
                </p>
                <p className="text-gray-600 text-[16px]">
                  c. Impersonate others, misrepresent your identity, or use the
                  Service on behalf of someone without their permission.
                </p>
                <p className="text-gray-600 text-[16px]">
                  d. Interfere with or disrupt the Service's functionality or
                  the servers and networks connected to the Service.
                </p>
                <p className="text-gray-600 text-[16px]">
                  e. Engage in any activity that could harm SP Analyst's
                  reputation or the reputation of any social media platform.
                </p>
                <p className="text-gray-600 text-[16px] font-semibold">
                  Intellectual Property
                </p>
                <p className="text-gray-600 text-[16px] mt-3">
                  a. Ownership: SP Analyst retains all rights, title, and
                  interest in and to the Service, including all intellectual
                  property rights associated with the Service.
                </p>
                <p className="text-gray-600 text-[16px] mt-3">
                  b. User Content: You retain ownership of any content you
                  submit to the Service. By using the Service, you grant SP
                  Analyst a non-exclusive, worldwide, royalty-free, and
                  transferable license to use, reproduce, distribute, and
                  display your content solely for the purpose of providing the
                  Service.
                </p>
                <p className="text-gray-600 text-[16px] font-semibold">
                  Payment and Subscription
                </p>
                <p className="text-gray-600 text-[16px] mt-3">
                  a. Some features of the Service may require payment, and you
                  agree to pay all applicable fees.
                </p>
                <p className="text-gray-600 text-[16px] mt-3">
                  b. Subscription plans, if applicable, will be billed on a
                  recurring basis, and you authorize SP Analyst to charge your
                  chosen payment method for the fees.
                </p>
                <p className="text-gray-600 text-[16px] mt-3">
                  c. You may cancel your subscription at any time, but no
                  refunds will be provided for any unused portion of your
                  subscription.
                </p>
                <p className="text-gray-600 text-[16px] font-semibold">
                  Termination
                </p>
                <p className="text-gray-600 text-[16px] mt-3">
                  a. SP Analyst may terminate or suspend your account and access
                  to the Service at its sole discretion, with or without cause,
                  and with or without notice.
                </p>
                <p className="text-gray-600 text-[16px] mt-3">
                  b. You may terminate your account by discontinuing your use of
                  the Service. All provisions of these Terms that by their
                  nature should survive termination shall survive termination,
                  including but not limited to ownership provisions, warranty
                  disclaimers, indemnity, and limitations of liability.
                </p>
                <p className="text-gray-600 text-[16px] font-semibold">
                  Privacy
                </p>
                <p className="text-gray-600 text-[16px] mt-3">
                  Your use of the Service is also governed by our Privacy
                  Policy, which can be found
                  <a
                    href="https://spanalyst.com/privacy-policy"
                    className=" text-blue-500"
                  >
                    {" "}
                    Here
                  </a>
                </p>
                <p className="text-gray-600 text-[16px] font-semibold">
                  Disclaimer of Warranties
                </p>
                <p className="text-gray-600 text-[16px] mt-3">
                  THE SERVICE IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT
                  WARRANTIES OF ANY KIND. SP ANALYST MAKES NO WARRANTIES
                  REGARDING THE SERVICE'S ACCURACY, RELIABILITY, OR FITNESS FOR
                  A PARTICULAR PURPOSE.
                </p>
                <p className="text-gray-600 text-[16px] font-semibold">
                  Limitation of Liability
                </p>
                <p className="text-gray-600 text-[16px] mt-3">
                  TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, SP ANALYST
                  SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
                  CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED
                  TO DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA, OR OTHER
                  INTANGIBLE LOSSES.
                </p>
                <p className="text-gray-600 text-[16px] font-semibold">
                  Governing Law and Jurisdiction
                </p>
                <p className="text-gray-600 text-[16px] mt-3">
                  These Terms shall be governed by and construed in accordance
                  with the laws of{" "}
                  <a
                    className=" text-blue-500"
                    href="https://www.sci.gov.in/jurisdiction/#:~:text=Its%20exclusive%20original%20jurisdiction%20extends,any%20question%20(whether%20of%20law"
                  >
                    India
                  </a>
                  . Any legal action or proceeding arising out of or relating to
                  these Terms shall be exclusively settled in the state or
                  federal courts located in India
                </p>
                <p className="text-gray-600 text-[16px] mt-3">
                  If you have any questions or concerns about these Terms,
                  please contact us at{" "}
                  <a
                    className=" text-blue-500"
                    href="mailto:advocacy@spanalyst.com"
                  >
                    advocacy@spanalyst.com
                  </a>
                </p>
                <p className="text-gray-600 text-[16px] mt-3">
                  By using the SP Analyst Service, you agree to abide by these
                  Terms. SP Analyst reserves the right to modify these Terms at
                  any time, and such modifications will become effective upon
                  posting on the Service. It is your responsibility to review
                  these Terms regularly to stay informed of any changes. Your
                  continued use of the Service after any such modifications
                  constitutes your acceptance of the revised Terms.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
      <Footer />
    </>
  );
};

export default TermsAndServices;

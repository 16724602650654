import React from "react";
import {
  Chart as ChartJs,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { uri } from "../../https";

ChartJs.register(CategoryScale, LinearScale, LineElement);
const PurchaseDetailsChart = () => {
  const [chart, setChart] = useState([]);

  const FetchPurchaseDetails = async () => {
    const url = `${uri}api/get_stripeorders_list`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));

    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        console.log("PURCHASE HISTORY", response.data.stripe_orders);
        setChart(response.data.stripe_orders);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    FetchPurchaseDetails();
  }, []);

  chart.map((x) => console.log("Price", x?.package_name));
  var data = {
    labels: chart.map((x) => x?.package_name),
    datasets: [
      {
        label: [` # ${chart.length} Purchases`],
        data: chart.map((x) => x?.price),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  var options = {
    maintainAspectRatio: false,
    scales: {},
    legend: {
      labels: {
        fontSize: 25,
      },
    },
  };
  return (
    <div>
      <Line data={data} options={options} height={400} />
    </div>
  );
};

export default PurchaseDetailsChart;

import React, { useState } from "react";
import Sidebar from "../Calender/Sidebar";
import { Link, NavLink } from "react-router-dom";

const SideMenuCommon = () => {
  const [sidebar_controller, setSideBarController] = useState(false);

  return (
    <div
      className={`${
        !sidebar_controller
          ? "w-[400px] hidden md:block"
          : "w-[400px] hidden md:block"
      } bg-[#fcfcfc] h-screen border-1 border-r-gray-200`}
    >
      <div className=" p-5 ">
        {/* <div
          onClick={() => setSideBarController(!sidebar_controller)}
          className={` h-[30px] w-[30px] absolute ${
            !sidebar_controller
              ? " left-[220px] md:left-[300px]"
              : " left-[80px] md:left-[80px] "
          }  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] border-1 border-white text-white rounded-full flex justify-center items-center cursor-pointer mt-[30px] duration-300`}
        >
          <i
            class={` ${
              !sidebar_controller
                ? "fa-solid fa-arrow-left"
                : "fa-solid fa-arrow-left rotate-180 "
            } duration-300 `}
          ></i>
        </div> */}
        <ul className="">
          <NavLink
            activeClassName="active"
            to="/profile-details"
            className=" flex justify-start items-center gap-5  p-4 rounded-md mb-4 cursor-pointer "
          >
            <i className="fa-solid fa-user text-[20px]"></i>
            <li
              className={` text-[15px] font-semibold ${
                !sidebar_controller ? "block" : "hidden"
              }`}
            >
              Profile
            </li>
          </NavLink>

          <NavLink
            activeClassName="active"
            to="/billing-details"
            className=" flex justify-start items-center gap-5  p-4 rounded-md mb-4 cursor-pointer "
          >
            <i className="fa-solid fa-file-invoice text-[20px]"></i>
            <li
              className={` text-[15px] font-semibold ${
                !sidebar_controller ? "block" : "hidden"
              }`}
            >
              Billing
            </li>
          </NavLink>

          <NavLink
            activeClassName="active"
            to="/connected-channels"
            className=" flex justify-start items-center gap-5  p-4 rounded-md mb-4 cursor-pointer "
          >
            <i class="fa-solid fa-network-wired text-[20px]"></i>
            <li
              className={` text-[15px] font-semibold ${
                !sidebar_controller ? "block" : "hidden"
              }`}
            >
              Connected channels
            </li>
          </NavLink>
        </ul>
      </div>
    </div>
  );
};

export default SideMenuCommon;

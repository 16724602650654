import { Player } from "@lottiefiles/react-lottie-player";
import React from "react";
import { Link } from "react-router-dom";

const Cancel = () => {
  return (
    <div>
      <Player
        src="https://lottie.host/88554553-8862-4e41-9c16-2f389f5e1e28/S7uYvkmtVu.json"
        className="player"
        loop
        autoplay
        className="h-[300px]"
      />
      <div className=" flex justify-center items-center">
        <Link to="/subscription">
          <button className="py-2 w-full p-3  bg-[#0884AA] rounded-md text-white font-semibold text-[16px] hover:bg-[#086885] transition-all hover:transition-all hover:shadow-xl  ">
            Back To Subscription
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Cancel;

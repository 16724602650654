import React, { useContext, useEffect, useState } from "react";

import TopBar from "../SideMenu/TopBar";
import SideMenuCommon from "../SideMenu/SideMenuCommon";
import { Link, useParams, useSearchParams } from "react-router-dom";
import FacebookModal from "./FacebookModal";
import GlobalContext from "../../../context/GlobalContext";
import axios from "axios";
import { uri } from "../../../https";
import InstagramModal from "./InstagramModal";
import FacebookPageAccessModal from "./FacebookPageAccessModal";
import LinkedInModal from "./LinkedInModal";
import { ToastContainer, toast } from "react-toastify";
import PintrestModal from "./PintrestModal";
import TumblrModal from "./TumblrModal";
import SubscriptionModal from "../../../SubscriptionModal";
import ConnectChannelModal from "../../ConnectChannelModal";

const ConnectChannels = () => {
  const {
    setDaysSelected,
    setShowEventModal,
    savedEvents,
    setSelectedEvent,
    setShowSelectedEventModal,
    daysSelected,
    dispatchCalEvent,
    selectedEvent,
    showSelectedFacebookModal,
    setShowSelectedFacebookModal,
    showSelectedInstagramModal,
    setShowSelectedInstagramModal,
    showFacebookPageModal,
    setShowFacebookPageModal,
    showSelectedLinkedInModal,
    setShowSelectedLinkedInModal,
    showSelectedPintrestModal,
    setShowSelectedPintrestModal,
    setShowSelectedTumblrModal,
    showSelectedTumblrModal,
    setShowSubscriptionModal,
    showSubscriptionModal,
    setConectChannelModal,
    connectChannelModal,
    Channel_Interaction_ModalFacebook,
    setChannelInteractionModalFacebook,
    Channel_Interaction_ModalInstagram,
    setChannelInteractionModalInstagram,
    Channel_Interaction_ModalPinterest,
    setChannelInteractionModalPinterest,
    Channel_Interaction_ModalLinkedIn,
    setChannelInteractionModalLinkedin,
    Channel_Interaction_ModalTumblr,
    setChannelInteractionModalTumblr,
  } = useContext(GlobalContext);
  const [searchParams, setSearchParams] = useSearchParams();
  console.log("Code", searchParams.get("code"));
  const [code, setCode] = useState(
    searchParams.get("code") ? searchParams.get("code") : ""
  );
  const [linkedInCode, setLinkedInCode] = useState("");
  const [loading, setLoading] = useState(false);

  const [userProfileData, setUserProfileData] = useState([]);
  const GetUserProfile = async () => {
    const url = `${uri}api/me`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        setUserProfileData(response?.data?.user_profile);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    GetUserProfile();
  }, []);

  const { clientid } = useParams();

  console.log("Profile data from connect channels", userProfileData.client_id);

  useEffect(() => {
    if (code !== "") {
      if (code.length == 430) {
        SubmitFacebookCode();
        setConectChannelModal(true);
      }
      if (code.length == 211) {
        SubmitLinkedInToken();
        setConectChannelModal(true);
      }
      if (code.length == 40) {
        SubmitPinterestToken();
        SubmitTumblrToken();
        setConectChannelModal(true);
      }
      console.log("code exists", code.length);
    } else {
      setConectChannelModal(false);
      console.log("code does not exists");
    }
  }, [code]);

  const SubmitFacebookCode = () => {
    console.log("USEFFECT CALLED");
    const url = `${uri}api/getgeneratedtoken`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;

    const data = {
      code: code,
    };
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    axios.post(url, data, config).then(
      (response) => {
        console.log(response.data);
        if (response.data.status === 200) {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          window.location.replace(response.data.redirect_url);
        }
        if (response.data.status == 400) {
          toast.error(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          window.location.replace(response.data.redirect_url);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const [facebook_page_access_token, setFacebookPageAccessToken] = useState([]);
  const FetchFacebookPageAccessToken = async () => {
    const url = `${uri}api/generatepageaccesstoken`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        setFacebookPageAccessToken(response?.data?.facebook_page_access_token);
        console.log("PageAccess", response?.data?.facebook_page_access_token);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const SubmitLinkedInToken = async () => {
    console.log("USEFFECT CALLED");
    const url = `${uri}api/generate_linkedin_token/${code}`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        console.log("LinkedIn Response", response?.data);
        if (response.data.status == 200) {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          window.location.replace(response.data.redirect_url);
        }
        if (response.data.status == 400) {
          toast.error(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          window.location.replace(response.data.redirect_url);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const SubmitPinterestToken = async () => {
    console.log("USEFFECT CALLED Pintrest");
    const url = `${uri}api/generate_pintrest_token`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;

    const data = {
      code: code,
    };
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    axios.post(url, data, config).then(
      (response) => {
        console.log(response.data);
        if (response.data.status === 200) {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          window.location.replace(response.data.redirect_url);
        }
        if (response.data.status == 400) {
          toast.error(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          window.location.replace(response.data.redirect_url);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const SubmitTumblrToken = async () => {
    console.log("USEFFECT CALLED Tumblr");
    const url = `${uri}api/generate_tumblr_token`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;

    const data = {
      code: code,
    };
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    axios.post(url, data, config).then(
      (response) => {
        console.log(response.data);
        if (response.data.status === 200) {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          window.location.replace(response.data.redirect_url);
        }
        if (response.data.status == 400) {
          toast.error(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          // window.location.replace(response.data.redirect_url);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  console.log("page access data", facebook_page_access_token);

  useEffect(() => {
    FetchFacebookPageAccessToken();
  }, []);

  const [facebookpageid, setFacebookPageId] = useState([]);
  const FetchFacebookPageLinked = async () => {
    const url = `${uri}api/get_facebook_pageid`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        setFacebookPageId(response?.data?.facebook_page_id);
        console.log("Page id from db", response.data.facebook_page_id.page_id);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const [longAccessToken, setLongAccessToken] = useState([]);
  const FetchFacebookLongAccessToken = async () => {
    const url = `${uri}api/get_facebook_long_access_token`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        setLongAccessToken(response?.data?.token.long_lived_access_token);
        console.log(
          "Long access token",
          response.data.token.long_lived_access_token
        );
      },
      (error) => {
        console.log(error);
      }
    );
  };
  const [tempChannelHandler, setTempChannelHandler] = useState("");

  const [requestRefreshToken, setRequestRefreshToken] = useState("");
  const RequestPinterestRefreshToken = async () => {
    const url = `${uri}api/pinterest_refresh_token`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    setLoading(true);
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        setRequestRefreshToken(response?.data?.pintrest_response);
        if (response.data.status == 200) {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setLoading(false);
        }
        if (response.data.status == 400) {
          toast.error(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setLoading(false);
        }
      },
      (error) => {
        console.log(error);
        toast.error("Something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      }
    );
  };

  const RequestTumblrRefreshToken = async () => {
    const url = `${uri}api/tumblr_refresh_token`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    setLoading(true);
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        setRequestRefreshToken(response?.data?.tumblr_response);
        if (response.data.status == 200) {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setLoading(false);
        }
        if (response.data.status == 400) {
          toast.error(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setLoading(false);
        }
      },
      (error) => {
        console.log(error);
        toast.error("Something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
      }
    );
  };

  const [is_subscription_active, setIsSubscriptionActive] = useState([]);
  const GetUserSubscription = async () => {
    const url = `${uri}api/check_subscription_is_active`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        if (response.data.status == 200) {
          setShowSubscriptionModal(false);
        }
        if (response.data.status == 400) {
          setShowSubscriptionModal(true);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    GetUserSubscription();
  }, []);

  return (
    <>
      {showSelectedFacebookModal && <FacebookModal />}
      {showSelectedInstagramModal && <InstagramModal />}
      {showSelectedLinkedInModal && <LinkedInModal />}
      {showSelectedPintrestModal && <PintrestModal />}
      {showSelectedTumblrModal && <TumblrModal />}
      {showSubscriptionModal && <SubscriptionModal />}
      {connectChannelModal && <ConnectChannelModal />}

      <div>
        {showFacebookPageModal && (
          <div>
            <FacebookPageAccessModal
              facebook_page_access_token={facebook_page_access_token}
            />
          </div>
        )}
      </div>

      <TopBar />
      <div className=" flex justify-start items-start ">
        {/* <SideMenuCommon /> */}

        <div className=" w-full h-screen bg-gray-100/10">
          <div className=" flex flex-col md:flex-row justify-center items-center md:justify-evenly  md:items-start mt-[50px]">
            <div>
              <h1 className=" text-[21px] font-semibold text-gray-700">
                Connect channels
              </h1>
            </div>
            <Link to={`/dashboard/clientId=${userProfileData?.client_id}`}>
              <button className=" hidden md:block w-full px-6 py-3  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all hover:scale-90">
                <i class="fa-solid fa-arrow-left-long"></i> Back
              </button>
            </Link>
          </div>

          <div className=" mt-[20px]">
            <div className="flex  flex-col justify-center items-center p-[20px] md:p-[0px]">
              <div className=" flex justify-center items-center md:hidden w-full  h-full border-1 border-gray-200 rounded-md p-5 mb-5">
                <div className=" flex flex-col justify-center items-center">
                  <div className=" mb-3">
                    <div className=" flex flex-row">
                      <img
                        src="https://res.cloudinary.com/dv5jjlsd7/image/upload/v1690963499/SP%20ANALYST/icons/Facebook_mt97xt.png"
                        className=" h-[50px] object-contain"
                      />
                      <div className=" absolute  right-[50px]">
                        <i
                          onClick={() => {
                            setChannelInteractionModalFacebook(
                              !Channel_Interaction_ModalFacebook
                            );
                          }}
                          class="fa-solid fa-ellipsis text-[24px] cursor-pointer"
                        ></i>
                      </div>
                    </div>
                  </div>

                  {Channel_Interaction_ModalFacebook && (
                    <div className=" flex flex-col justify-center items-center  w-[250px] p-3 bg-white shadow-md absolute  -mt-[20px] ml-[50px] ">
                      <div>
                        <button
                          onClick={() => setShowFacebookPageModal(true)}
                          className=" text-green-500 font-semibold  select-none "
                        >
                          Provide Access
                        </button>
                      </div>

                      <hr class="h-px my-2 bg-gray-500 border-1 border-gray-500 " />

                      <div>
                        <button
                          // onClick={() => setShowFacebookPageModal(true)}
                          className=" text-blue-500 font-semibold select-none cursor-not-allowed "
                        >
                          Null
                        </button>
                      </div>
                    </div>
                  )}
                  <div className=" mb-3">
                    <h1 className=" text-gray-800 font-semibold text-[20px]">
                      Facebook
                    </h1>
                  </div>
                  <button
                    onClick={() => setShowSelectedFacebookModal(true)}
                    className=" w-full px-6 py-3  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all hover:scale-90"
                  >
                    Connect
                  </button>
                </div>
              </div>
              <div className="  hidden  md:w-[800px] h-[80px] border-2 border-gray-200 p-3 mb-5 md:flex justify-between items-center rounded-md">
                <div className=" flex items-center justify-start gap-4">
                  <div>
                    <div>
                      <img
                        src="https://res.cloudinary.com/dv5jjlsd7/image/upload/v1690963499/SP%20ANALYST/icons/Facebook_mt97xt.png"
                        className=" h-[50px] object-contain"
                      />
                    </div>

                    <span className=" absolute -mt-[10px] ml-[35px]"></span>
                  </div>
                  <div>
                    <h1 className=" text-gray-800 font-semibold text-[20px]">
                      Facebook
                    </h1>
                  </div>
                </div>
                <div className=" flex flex-row justify-end items-center gap-[20px] mr-3">
                  <button
                    onClick={() => setShowSelectedFacebookModal(true)}
                    className=" w-full px-6 py-3  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all hover:scale-90"
                  >
                    Connect
                  </button>
                  <div>
                    <i
                      onClick={() => {
                        setChannelInteractionModalFacebook(
                          !Channel_Interaction_ModalFacebook
                        );
                        setChannelInteractionModalTumblr(false);
                        setChannelInteractionModalPinterest(false);
                        setChannelInteractionModalLinkedin(false);
                        setChannelInteractionModalInstagram(false);
                      }}
                      className="fa-solid fa-ellipsis-vertical text-[20px]  cursor-pointer "
                      // onClick={() => setShowFacebookPageModal(true)}
                    ></i>

                    <>
                      {Channel_Interaction_ModalFacebook && (
                        <div className=" flex flex-col justify-center items-center  w-[250px] p-3 bg-white shadow-md absolute  -mt-[20px] ml-[50px] ">
                          <div>
                            <button
                              onClick={() => setShowFacebookPageModal(true)}
                              className=" text-green-500 font-semibold  select-none "
                            >
                              Provide Access
                            </button>
                          </div>

                          <hr class="h-px my-2 bg-gray-500 border-1 border-gray-500 " />

                          <div>
                            <button
                              // onClick={() => setShowFacebookPageModal(true)}
                              className=" text-blue-500 font-semibold select-none cursor-not-allowed "
                            >
                              Null
                            </button>
                          </div>
                        </div>
                      )}
                    </>
                  </div>
                </div>
              </div>
              <div className=" flex justify-center items-center md:hidden w-full  h-full border-1 border-gray-200 rounded-md p-5 mb-5">
                <div className=" flex flex-col justify-center items-center">
                  <div className=" mb-3">
                    <div className=" flex flex-row">
                      <img
                        src="https://res.cloudinary.com/dv5jjlsd7/image/upload/v1690963500/SP%20ANALYST/icons/Instagram_wpgawc.png"
                        className=" h-[50px] object-contain"
                      />
                      <div className=" absolute  right-[50px]">
                        <i class="fa-solid fa-ellipsis text-[24px] cursor-pointer"></i>
                      </div>
                    </div>
                  </div>

                  <div className=" mb-3">
                    <h1 className=" text-gray-800 font-semibold text-[20px]">
                      Instagram
                    </h1>
                  </div>
                  <button
                    onClick={() => setShowSelectedInstagramModal(true)}
                    className=" w-full px-6 py-3  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all hover:scale-90"
                  >
                    Connect
                  </button>
                </div>
              </div>
              <div className="  hidden  md:w-[800px] h-[80px] border-2 border-gray-200 p-3 mb-5 md:flex justify-between items-center rounded-md">
                <div className=" flex items-center justify-start gap-4">
                  <div>
                    <div>
                      <img
                        src="https://res.cloudinary.com/dv5jjlsd7/image/upload/v1690963500/SP%20ANALYST/icons/Instagram_wpgawc.png"
                        className=" h-[50px] object-contain"
                      />
                    </div>

                    <span className=" absolute -mt-[10px] ml-[35px]"></span>
                  </div>
                  <div>
                    <h1 className=" text-gray-800 font-semibold text-[20px]">
                      Instagram
                    </h1>
                  </div>
                </div>
                <div className=" flex flex-row justify-end items-center gap-[20px] mr-3">
                  <button
                    onClick={() => setShowSelectedInstagramModal(true)}
                    className=" w-full px-6 py-3  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all hover:scale-90"
                  >
                    Connect
                  </button>
                  <div>
                    <i
                      onClick={() => {
                        setChannelInteractionModalInstagram(
                          !Channel_Interaction_ModalInstagram
                        );
                        setChannelInteractionModalFacebook(false);
                      }}
                      className="fa-solid fa-ellipsis-vertical text-[20px]  cursor-pointer"
                    ></i>
                    <>
                      {Channel_Interaction_ModalInstagram && (
                        <div className=" flex flex-col justify-center items-center  w-[250px] p-3 bg-white shadow-md absolute  -mt-[20px] ml-[50px] ">
                          {/* <hr class="h-px my-2 bg-gray-500 border-1 border-gray-500 " /> */}

                          <div>
                            <button
                              // onClick={() => setShowFacebookPageModal(true)}
                              className=" text-blue-500 font-semibold select-none  cursor-not-allowed"
                            >
                              Null
                            </button>
                          </div>
                        </div>
                      )}
                    </>
                  </div>
                </div>
              </div>
              <div className=" flex justify-center items-center md:hidden w-full  h-full border-1 border-gray-200 rounded-md p-5 mb-5">
                <div className=" flex flex-col justify-center items-center">
                  <div className=" mb-3">
                    <div className=" flex flex-row">
                      <img
                        src="https://res.cloudinary.com/dv5jjlsd7/image/upload/v1690963499/SP%20ANALYST/icons/LinkedIn_ewhtqe.png"
                        className=" h-[50px] object-contain"
                      />
                      <div className=" absolute  right-[50px]">
                        <i class="fa-solid fa-ellipsis text-[24px] cursor-pointer"></i>
                      </div>
                    </div>
                  </div>

                  <div className=" mb-3">
                    <h1 className=" text-gray-800 font-semibold text-[20px]">
                      Linkedin
                    </h1>
                  </div>
                  <button
                    onClick={() => setShowSelectedLinkedInModal(true)}
                    className=" w-full px-6 py-3  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all hover:scale-90"
                  >
                    Connect
                  </button>
                </div>
              </div>
              <div className="  hidden  md:w-[800px] h-[80px] border-2 border-gray-200 p-3 mb-5 md:flex justify-between items-center rounded-md">
                <div className=" flex items-center justify-start gap-4">
                  <div>
                    <div>
                      <img
                        src="https://res.cloudinary.com/dv5jjlsd7/image/upload/v1690963499/SP%20ANALYST/icons/LinkedIn_ewhtqe.png"
                        className=" h-[50px] object-contain"
                      />
                    </div>

                    <span className=" absolute -mt-[10px] ml-[35px]"></span>
                  </div>
                  <div>
                    <h1 className=" text-gray-800 font-semibold text-[20px]">
                      Linkedin
                    </h1>
                  </div>
                </div>
                <div className=" flex flex-row justify-end items-center gap-[20px] mr-3">
                  <button
                    onClick={() => setShowSelectedLinkedInModal(true)}
                    className=" w-full px-6 py-3  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all hover:scale-90"
                  >
                    Connect
                  </button>
                  <div>
                    <i
                      onClick={() => {
                        setChannelInteractionModalLinkedin(
                          !Channel_Interaction_ModalLinkedIn
                        );
                        setChannelInteractionModalInstagram(false);
                        setChannelInteractionModalFacebook(false);
                      }}
                      className="fa-solid fa-ellipsis-vertical text-[20px]  cursor-pointer"
                    ></i>
                    <>
                      {Channel_Interaction_ModalLinkedIn && (
                        <div className=" flex flex-col justify-center items-center  w-[250px] p-3 bg-white shadow-md absolute  -mt-[20px] ml-[50px] ">
                          {/* <hr class="h-px my-2 bg-gray-500 border-1 border-gray-500 " /> */}

                          <div>
                            <button
                              // onClick={() => setShowFacebookPageModal(true)}
                              className=" text-blue-500 font-semibold select-none cursor-not-allowed "
                            >
                              Null
                            </button>
                          </div>
                        </div>
                      )}
                    </>
                  </div>
                </div>
              </div>
              <div className=" flex justify-center items-center md:hidden w-full  h-full border-1 border-gray-200 rounded-md p-5 mb-5">
                <div className=" flex flex-col justify-center items-center">
                  <div className=" mb-3">
                    <div className=" flex flex-row">
                      <img
                        src="https://res.cloudinary.com/dv5jjlsd7/image/upload/v1690963499/SP%20ANALYST/icons/PT2OctDenoiserBeauty_002_copy_1_oit8qm.png"
                        className=" h-[50px] object-contain"
                      />
                      <div className=" absolute  right-[50px]">
                        <i class="fa-solid fa-ellipsis text-[24px] cursor-pointer"></i>
                      </div>
                    </div>
                  </div>

                  <div className=" mb-3">
                    <h1 className=" text-gray-800 font-semibold text-[20px]">
                      Pinterest
                    </h1>
                  </div>
                  <button
                    onClick={() => setShowSelectedPintrestModal(true)}
                    className=" w-full px-6 py-3  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all hover:scale-90"
                  >
                    Connect
                  </button>
                </div>
              </div>
              <div className="  hidden  md:w-[800px] h-[80px] border-2 border-gray-200 p-3 mb-5 md:flex justify-between items-center rounded-md">
                <div className=" flex items-center justify-start gap-4">
                  <div>
                    <div>
                      <img
                        src="https://res.cloudinary.com/dv5jjlsd7/image/upload/v1690963499/SP%20ANALYST/icons/PT2OctDenoiserBeauty_002_copy_1_oit8qm.png"
                        className=" h-[50px] object-contain"
                      />
                    </div>

                    <span className=" absolute -mt-[10px] ml-[35px]"></span>
                  </div>
                  <div>
                    <h1 className=" text-gray-800 font-semibold text-[20px]">
                      Pinterest
                    </h1>
                  </div>
                </div>
                <div className=" flex flex-row justify-end items-center gap-[20px] mr-3">
                  <button
                    onClick={() => setShowSelectedPintrestModal(true)}
                    className=" w-full px-6 py-3  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all hover:scale-90"
                  >
                    Connect
                  </button>
                  <div>
                    <i
                      onClick={() => {
                        setChannelInteractionModalPinterest(
                          !Channel_Interaction_ModalPinterest
                        );
                        setChannelInteractionModalLinkedin(false);
                        setChannelInteractionModalInstagram(false);
                        setChannelInteractionModalFacebook(false);
                      }}
                      className="fa-solid fa-ellipsis-vertical text-[20px]  cursor-pointer"
                    ></i>
                    <>
                      {Channel_Interaction_ModalPinterest && (
                        <div className=" flex flex-col justify-center items-center  w-[250px] p-3 bg-white shadow-md absolute  -mt-[20px] ml-[50px] ">
                          {/* <hr class="h-px my-2 bg-gray-500 border-1 border-gray-500 " /> */}

                          <div>
                            {loading ? (
                              <>
                                <svg
                                  aria-hidden="true"
                                  class="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                                  viewBox="0 0 100 101"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"
                                  />
                                </svg>
                              </>
                            ) : (
                              <>
                                <button
                                  onClick={() => RequestPinterestRefreshToken()}
                                  className=" text-blue-500 font-semibold select-none "
                                >
                                  Refresh
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  </div>
                </div>
              </div>
              <div className=" flex justify-center items-center md:hidden w-full  h-full border-1 border-gray-200 rounded-md p-5">
                <div className=" flex flex-col justify-center items-center">
                  <div className=" mb-3">
                    <div className=" flex flex-row">
                      <img
                        src="https://res.cloudinary.com/dv5jjlsd7/image/upload/v1690963499/SP%20ANALYST/icons/Tumblr2OctDenoiserBeauty_002_xvca2t.png"
                        className=" h-[50px] object-contain"
                      />
                      <div className=" absolute  right-[50px]">
                        <i class="fa-solid fa-ellipsis text-[24px] cursor-pointer"></i>
                      </div>
                    </div>
                  </div>

                  <div className=" mb-3">
                    <h1 className=" text-gray-800 font-semibold text-[20px]">
                      Tumblr
                    </h1>
                  </div>
                  <button
                    onClick={() => setShowSelectedTumblrModal(true)}
                    className=" w-full px-6 py-3  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all hover:scale-90"
                  >
                    Connect
                  </button>
                </div>
              </div>
              <div className="  hidden  md:w-[800px] h-[80px] border-2 border-gray-200 p-3 mb-5 md:flex justify-between items-center rounded-md">
                <div className=" flex flex-row items-center justify-start gap-4">
                  <div>
                    <div>
                      <img
                        src="https://res.cloudinary.com/dv5jjlsd7/image/upload/v1690963499/SP%20ANALYST/icons/Tumblr2OctDenoiserBeauty_002_xvca2t.png"
                        className=" h-[50px] object-contain"
                      />
                    </div>

                    <span className=" absolute -mt-[10px] ml-[35px]"></span>
                  </div>
                  <div>
                    <h1 className=" text-gray-800 font-semibold text-[20px]">
                      Tumblr
                    </h1>
                  </div>
                </div>
                <div className=" flex flex-row justify-end items-center gap-[20px] mr-3">
                  <button
                    onClick={() => setShowSelectedTumblrModal(true)}
                    className=" w-full px-6 py-3  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all hover:scale-90"
                  >
                    Connect
                  </button>
                  <div>
                    <i
                      onClick={() => {
                        setChannelInteractionModalTumblr(
                          !Channel_Interaction_ModalTumblr
                        );
                        setChannelInteractionModalPinterest(false);
                        setChannelInteractionModalLinkedin(false);
                        setChannelInteractionModalInstagram(false);
                        setChannelInteractionModalFacebook(false);
                      }}
                      className="fa-solid fa-ellipsis-vertical text-[20px]  cursor-pointer"
                    ></i>
                    <>
                      {Channel_Interaction_ModalTumblr && (
                        <div className=" flex flex-col justify-center items-center  w-[250px] p-3 bg-white shadow-md absolute  -mt-[20px] ml-[50px] ">
                          {/* <hr class="h-px my-2 bg-gray-500 border-1 border-gray-500 " /> */}

                          <div>
                            {loading ? (
                              <>
                                {" "}
                                <svg
                                  aria-hidden="true"
                                  class="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                                  viewBox="0 0 100 101"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"
                                  />
                                </svg>
                              </>
                            ) : (
                              <>
                                {" "}
                                <button
                                  onClick={() => RequestTumblrRefreshToken()}
                                  className=" text-blue-500 font-semibold select-none "
                                >
                                  Refresh
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
    </>
  );
};

export default ConnectChannels;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { uri } from "../../../https";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AdminTopBar = () => {
  const history = useNavigate();
  const [profileModal, setProfileModal] = useState(false);
  const [navbar_controller_dashboard, setNavbarControllerDashboard] =
    useState(false);

  const [userProfileData, setUserProfileData] = useState([]);
  const GetUserProfile = async () => {
    const url = `${uri}api/me`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        setUserProfileData(response?.data?.user_profile);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    GetUserProfile();
  }, []);

  const { clientid } = useParams();

  const Logout = async () => {
    const url = `${uri}api/logout`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        console.log(response.data);
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        localStorage.removeItem("analyst_token");
        history("/login");
        window.location.reload(true);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const [subscribed_plan, setSubscribedPlan] = useState([]);
  const GetSubscribedPackageDetails = async () => {
    const url = `${uri}api/get_subscribed_plan`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        setSubscribedPlan(response?.data?.subscribed_plan);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    GetSubscribedPackageDetails();
  }, []);

  return (
    <>
      <div className=" w-full flex flex-row justify-end items-center bg-white shadow-md px-[40px] ">
        <div
          onClick={() =>
            setNavbarControllerDashboard(!navbar_controller_dashboard)
          }
          className="flex justify-around mr-[10px] items-center gap-[10px] m-5  block  md:hidden"
        >
          <i
            class={`${
              !navbar_controller_dashboard
                ? "fa-solid fa-bars"
                : "fa-solid fa-x"
            } text-[24px] text-gray-700 duration-300`}
          ></i>
        </div>
      </div>
      {navbar_controller_dashboard && (
        <>
          <div className=" w-full bg-white px-3 p-3 dashboard_topbar absolute duration-300 block md:hidden">
            <ul className=" flex flex-col justify-start items-start gap-[20px]  md:gap-[50px] font-semibold text-gray-700  p-3 ml-[20px]">
              <NavLink
                to="/admin-dashboard"
                className="hover:text-[#5aa7bc] transition-all hover:transition-all border-b  border-b-gray-600 p-3 w-full flex-1 cursor-pointer"
              >
                <a>Dashboard</a>
              </NavLink>
              <NavLink
                to="/published"
                className="hover:text-[#5aa7bc] transition-all hover:transition-all border-b  border-b-gray-600 p-3 w-full flex-1 cursor-pointer"
              >
                <a>Posts</a>
              </NavLink>
              <NavLink
                to="/analytics"
                className="hover:text-[#5aa7bc] transition-all hover:transition-all border-b  border-b-gray-600 p-3 w-full flex-1 cursor-pointer"
              >
                <a>Users</a>
              </NavLink>
              <NavLink
                to="/connected-channels"
                className="hover:text-[#5aa7bc] transition-all hover:transition-all border-b  border-b-gray-600 p-3 w-full flex-1 cursor-pointer"
              >
                <a>Admin</a>
              </NavLink>
              <NavLink
                to="/profile-details"
                className="hover:text-[#5aa7bc] transition-all hover:transition-all border-b  border-b-gray-600 p-3 w-full flex-1 cursor-pointer"
              >
                <a>Purchases</a>
              </NavLink>
            </ul>

            <div className=" flex justify-center items-center">
              <button
                onClick={Logout}
                className=" mt-[20px] ml-[20px]  w-[170px] py-3  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all md:hover:scale-90 cursor-pointer"
              >
                Logout
              </button>
            </div>
          </div>
          {/* <div className=" w-full bg-white px-3 absolute duration-300 dashboard_topbar">
            <ul className=" flex flex-col justify-start items-start gap-[50px] font-semibold text-gray-700 cursor-pointer p-3 ml-[60px]">
              <NavLink activeClassName="active" to="/published">
                <li className=" w-full hover:bg-[#0180a1]/20 px-4 py-3 flex justify-center items-center  cursor-pointer rounded-md transition-all hover:transition-all">
                  <a className=" text-[16px] font-semibold  ">
                    <i className="fa-solid fa-upload text-[20px]"></i> Published
                  </a>
                </li>
              </NavLink>

              <NavLink
                activeClassName="active"
                to="/analytics"
                className=" hover:bg-[#0180a1]/20 px-4 py-3 flex justify-center items-center cursor-pointer rounded-md transition-all hover:transition-all"
              >
                <a className=" text-[16px] font-semibold ">
                  {" "}
                  <i className="fa-solid fa-arrow-trend-up text-[20px]"></i>{" "}
                  Analytics
                </a>
              </NavLink>

              <NavLink
                activeClassName="active"
                to="/connected-channels"
                className=" hover:bg-[#0180a1]/20 px-4 py-3  flex justify-center items-center cursor-pointer rounded-md transition-all hover:transition-all"
              >
                <a className=" text-[16px] font-semibold ">
                  {" "}
                  <i class="fa-solid fa-table-list text-[20px]"></i> Channels
                </a>
              </NavLink>
            </ul>
          </div> */}
        </>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
    </>
  );
};

export default AdminTopBar;

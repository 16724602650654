import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { uri } from "../../../https";
import axios from "axios";

const CalendarHeaderCommon = () => {
  const [userProfileData, setUserProfileData] = useState([]);
  const GetUserProfile = async () => {
    const url = `${uri}api/me`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        setUserProfileData(response?.data?.user_profile);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    GetUserProfile();
  }, []);
  return (
    <header className=" px-4 py-2 flex flex-col gap-[10px] md:gap-[0px] md:flex-row items-center  justify-between m-3">
      <div className=" flex justify-center md:justify-between md:items-center mt-[130px] md:mt-[0px]">
        <h1 className=" text-[20px] font-semibold text-gray-600">Agenda</h1>
      </div>
      <Link to={`/dashboard/clientId=${userProfileData?.client_id}`}>
        <button className=" hidden md:block w-full px-6 py-3  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all hover:scale-90">
          <i class="fa-solid fa-arrow-left-long"></i> Back
        </button>
      </Link>
    </header>
  );
};

export default CalendarHeaderCommon;

import * as yup from "yup";

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/;

export const loginPageSchema = yup.object().shape({
  email: yup.string().email("Please enter valid email").required("Required"),
  password: yup.string().min(6).required("Required"),
});

export const registerPageSchema = yup.object().shape({
  firstname: yup
    .string("Please Enter Your first name")
    .required("Please Enter Your first name"),
  lastname: yup
    .string("Please Enter Your last name")
    .required("Please Enter Your last name"),
  address_1: yup
    .string("Please Enter Your address")
    .required("Please Enter Your address"),
  address_2: yup
    .string("Please Enter Your address")
    .required("Please Enter Your address"),
  state_user: yup
    .string("Please Enter Your state")
    .required("Please Enter Your state"),
  city: yup
    .string("Please Enter Your city")
    .required("Please Enter Your city"),
  pin_code: yup
    .string("Please Enter Your pin code")
    .required("Please Enter Your pin code"),
  mobile_no: yup
    .string("Please Enter Your mobile no")
    .required("Please Enter Your mobile no"),
  email: yup
    .string()
    .email("Please enter valid email")
    .required("Please enter valid email"),
  password: yup
    .string()
    .min(6)
    .matches(passwordRules, { message: "Please create a stronger password" })
    .required("Required"),
});

export const forgotPasswordPageSchema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter valid email")
    .required("Please enter valid email"),
});

export const resetPasswordPageSchema = yup.object().shape({
  password: yup.string().min(6).matches(passwordRules).required("Required"),
  password_confirmation: yup
    .string()
    .min(6)
    .matches(passwordRules)
    .required("Required"),
});

export const pinterestboardPageSchema = yup.object().shape({
  name: yup.string("Enter board name").required("Enter board name"),
  description: yup
    .string("Enter board description")
    .required("Enter board description"),
  privacy: yup
    .string("Choose privacy settings")
    .required("Choose privacy settings"),
});

export const contactusPageSchema = yup.object().shape({
  name: yup.string("Enter your name").required("Enter your name"),
  email: yup
    .string("Enter your contact email")
    .required("Enter your contact email"),
  phoneno: yup
    .string("Enter your phone number")
    .required("Enter your phone number"),
  message: yup.string("Enter your message").required("Enter your message"),
});

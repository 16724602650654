import React from 'react'
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';

const Blogs = () => {
  return (
    <>
      <Navbar />
      <div className=" flex justify-start items-start">
        <div className=" w-full  h-full px-[10px] md:px-[0px]">
          <div className=" w-full h-[400px] bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white flex justify-center items-center ">
            <div className=" flex flex-col justify-center items-center">
              <h1 className=" text-[40px] md:text-[70px] font-semibold">
                Blogs
              </h1>
              {/* <h1 className=" text-[20px] md:text-[30px] font-semibold">
                V1.0 Internal Beta
              </h1> */}
              <div className=" flex flex-row  gap-[50px] mt-[10px]">
                <div>
                  <i className="fa-brands fa-facebook text-[25px] cursor-pointer"></i>
                </div>
                <div>
                  <i class="fa-brands fa-instagram text-[25px] cursor-pointer"></i>
                </div>
                <div>
                  <i class="fa-brands fa-x-twitter text-[25px] cursor-pointer"></i>
                </div>
                <div>
                  <i class="fa-brands fa-pinterest text-[25px] cursor-pointer"></i>
                </div>
                <div>
                  <i class="fa-brands fa-square-tumblr text-[25px] cursor-pointer"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Blogs
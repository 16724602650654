import React from "react";
import Navbar from "./Navbar/Navbar";

const ComingSoon = () => {
  return (
    <>
      <Navbar />
      <div className=" container mx-auto">
        <div className=" flex justify-center items-center  place-items-center">
          <h1 className=" text-[25px] md:text-[45px] font-semibold">
            Coming Soon
          </h1>
        </div>
      </div>
    </>
  );
};

export default ComingSoon;

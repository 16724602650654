import React, { useContext, useEffect, useState } from "react";
import GlobalContext from "../../../context/GlobalContext";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { uri } from "../../../https";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const InstagramModal = () => {
  const {
    setDaysSelected,
    setShowEventModal,
    savedEvents,
    setSelectedEvent,
    setShowSelectedEventModal,
    daysSelected,
    dispatchCalEvent,
    selectedEvent,
    setShowSelectedFacebookModal,
    setShowSelectedInstagramModal,
  } = useContext(GlobalContext);

  const [searchParams, setSearchParams] = useSearchParams();
  console.log("InstagramCode", searchParams.get("code"));
  const [code, setCode] = useState(
    searchParams.get("code") ? searchParams.get("code") : ""
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (code !== "") {
      // FetchInstagramPageAccess();
      console.log("code exists", code.length);
    } else {
      console.log("code does not exists");
    }
  }, [code]);

  const ConnectToInstagram = () => {
    const url =
      "https://www.facebook.com/v15.0/dialog/oauth?client_id=929196161691547&redirect_uri=http://localhost:3000/channels/connect&scope=email,pages_manage_cta,read_insights,publish_video,pages_manage_posts,read_insights,pages_messaging,pages_read_engagement,pages_show_list,publish_video,pages_show_list,ads_management,instagram_basic,instagram_manage_comments,instagram_content_publish&state=12345";
    window.location.replace(url);
  };

  const [instagramPageAccess, setInstagramPageAccess] = useState([]);
  const FetchInstagramPageAccess = async () => {
    setLoading(true)
    const url = `${uri}api/get_instagram_page_details`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        setInstagramPageAccess(response?.data?.instagram_page_access);
        console.log(
          "Instagram page access",
          response.data.instagram_page_access
        );
        if (response.data.status == 200) {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
           setLoading(false);
          window.location.replace(response.data.redirect_url);
        }
        if(response.data.status == 400){
            toast.error(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setLoading(false);
            window.location.replace(response.data.redirect_url);
        }

        // setShowSelectedInstagramModal(false)
      },
      (error) => {
        console.log(error);
        setLoading(false);
      }
    );
  };
  return (
    <div className=" h-full w-full bg-black/25  absolute left-0   flex justify-center items-center overflow-hidden">
      <div className="w-[500px] shadow-lg bg-white  relative -top-[120px] rounded-md ">
        <header className="bg-white px-4 py-2 flex justify-between items-center rounded-md">
          <h1 className=" text-gray-700 font-semibold">
            Authenticate with Facebook
          </h1>
          <div className=" gap-3">
            <button onClick={() => setShowSelectedInstagramModal(false)}>
              <span className="material-icons-outlined text-gray-400">
                close
              </span>
            </button>
          </div>
        </header>
        <div></div>
        <div className=" w-full bg-[#f5f5f5] h-full ">
          <div className=" flex justify-start flex-col items-start gap-3 p-5">
            <h1 className=" text-gray-700 font-semibold text-[15px]">
              Wait...why do I have to authenticate with Facebook?
            </h1>
            <p className=" text-gray-600 text-[14px]">
              Facebook requires Instagram Business or Creator accounts to be
              connected to a Facebook Page. For Buffer to work as intended, we
              need to authenticate through Facebook.
            </p>
          </div>
          <div>
            <p className=" text-gray-700 text-[15px] ml-3 p-2">
              Check out our{" "}
              <span className=" cursor-pointer text-[#015e75] font-semibold">
                Instagram connection guide
              </span>{" "}
              for more.
            </p>
          </div>
        </div>
        <footer className=" flex justify-end  border-t  p-3 gap-3">
          <button
            onClick={() => setShowSelectedFacebookModal(false)}
            className="  px-3 py-2 rounded text-gray-600 font-semibold hover:text-gray-800 transition-all hover:transition-all"
          >
            Cancel
          </button>
          {loading ? (
            <>
              <button
                onClick={FetchInstagramPageAccess}
                className="  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] hover:scale-90 transition-all hover:transition-all px-3 py-2 rounded text-white"
              >
                <svg
                  aria-hidden="true"
                  class="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              </button>
            </>
          ) : (
            <>
              <button
                onClick={FetchInstagramPageAccess}
                className="  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] hover:scale-90 transition-all hover:transition-all px-3 py-2 rounded text-white"
              >
                Authenticate with Facebook
              </button>
            </>
          )}
        </footer>{" "}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
    </div>
  );
};

export default InstagramModal;

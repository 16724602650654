import React, { Fragment, useEffect, useState } from "react";
import TopBar from "../SideMenu/TopBar";
import SideMenuCommon from "../SideMenu/SideMenuCommon";
import { uri } from "../../../https";
import axios from "axios";
import { Link } from "react-router-dom";
import { Player } from "@lottiefiles/react-lottie-player";
import FooterLoggedIn from "../../Footer/FooterLoggedIn";

const Profile = () => {
  const [userProfileData, setUserProfileData] = useState([]);
  const [loading, setLoading] = useState(false);
  const GetUserProfile = async () => {
    const url = `${uri}api/me`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    setLoading(true);
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        setUserProfileData(response?.data?.user_profile);
        setLoading(false);
      },
      (error) => {
        console.log(error);
        setLoading(false);
      }
    );
  };

  const [userInfoDetails, setUserInfoDetails] = useState([]);

  const GetUserProfileDetails = async () => {
    const url = `${uri}api/fetch_users_details`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    setLoading(true);
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/json",
        accept: "application/json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        setUserInfoDetails(response?.data?.user_details);
        setLoading(false);
      },
      (error) => {
        console.log(error);
        setLoading(false);
      }
    );
  };

  let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
  console.log("Token", tokendata?.token);

  const [userInfo, setUserInfo] = useState([]);
  const GetUser = async () => {
    const url = `${uri}api/fetch_users_info`;
    setLoading(true);
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        console.log(response.data);
        setUserInfo(response.data.user_info);
        setLoading(false);
      },
      (error) => {
        console.log(error);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    GetUserProfile();
    GetUserProfileDetails();
  }, []);

  useEffect(() => {
    GetUser();
  }, []);

  console.log("User Info", userInfo);

  return (
    <>
      {loading ? (
        <>
          <div className=" h-screen w-full fixed left-0 bg-white top-[0px] flex justify-center items-center  backdrop-blur-sm">
            <div className=" flex flex-col justify-center items-center">
              <Player
                src="https://lottie.host/1eb4f4a4-706d-498d-bf89-192423f0fd49/DOmyAJcAln.json"
                className="player"
                loop
                autoplay
                className="h-[200px]"
              />
              <p className=" text-[25px] text-gray-800 font-semibold">
                Please wait...
              </p>
            </div>
          </div>
        </>
      ) : (
        <>
          <Fragment>
            <TopBar />
            <div className=" flex justify-start items-start ">
              <SideMenuCommon />

              <div className=" w-full h-screen bg-gray-100/10 mb-3 overflow-scroll ">
                <div className=" flex justify-around gap-[200px] md:items-start items-center mt-[50px]">
                  <div>
                    <h1 className=" text-[21px] font-semibold text-gray-700">
                      Account Settings
                    </h1>
                  </div>
                  <div className=" hidden md:block"></div>
                </div>
                <div className=" flex flex-col justify-center items-center mt-4">
                  <div className=" flex flex-col justify-start md:items-start items-center mt-4">
                    <h2 className=" text-[18px] font-semibold text-gray-700 mb-3">
                      My Profile
                    </h2>
                    <div className=" w-full md:w-[800px] h-[120px] border-2 border-gray-200 p-3 flex justify-between items-center rounded-md">
                      <div className=" flex items-center justify-start gap-4">
                        <div>
                          <div className=" flex justify-center items-center text-white text-[20px] font-semibold h-[50px] w-[50px] bg-[#0180a1] rounded-full cursor-pointer ">
                            N
                          </div>
                        </div>
                        <div className=" flex flex-col  justify-start  items-start">
                          <h1 className=" text-gray-700 font-semibold">
                            {userProfileData?.name} {userProfileData?.lname}
                          </h1>
                          <span className=" text-gray-600 text-[15px] ">
                            {userProfileData?.email}
                          </span>
                          <span className=" text-gray-600 text-[13px] ">
                            {userInfo?.city} {userInfo?.state}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" flex flex-col md:justify-start md:items-start items-center mt-4">
                    <h2 className=" text-[18px] text-center font-semibold text-gray-700 mb-3">
                      Personal Information
                    </h2>

                    <div className=" w-full md:w-[800px] h-full border-2 border-gray-200 md:p-3 flex flex-col  rounded-md">
                      {userInfo?.address_line_1 ||
                      userInfo?.address_line_2 ||
                      userInfo?.state ||
                      userInfo?.mobile_no ||
                      userInfo?.city ||
                      userInfo?.pincode ? (
                        <>
                          {" "}
                          <Link to={`${userInfoDetails[0]?.client_id}/address`}>
                            <div className=" flex justify-end items-center cursor-pointer">
                              <i className="fa-solid fa-pen text-gray-700"></i>
                            </div>
                          </Link>
                        </>
                      ) : (
                        <>
                          
                            <div className=" flex justify-end items-center cursor-pointer">
                              <i className="fa-solid fa-pen text-gray-400"></i>
                            </div>
                         
                        </>
                      )}

                      <div className="  grid grid-cols-1 md:grid-cols-3  justify-between items-start  md:px-7 p-[40px] md:p-[0px]">
                        {userInfoDetails.map((userinfo, index) => {
                          return (
                            <>
                              <div className=" mb-3">
                                <h1 className=" text-gray-600 font-medium">
                                  First Name
                                </h1>
                                <h1 className=" text-gray-800 font-medium">
                                  {" "}
                                  {userinfo.name}
                                </h1>
                              </div>
                              <div className=" mb-3">
                                <h1 className=" text-gray-600 font-medium">
                                  First Name
                                </h1>
                                <h1 className=" text-gray-800 font-medium">
                                  {" "}
                                  {userinfo.lname}
                                </h1>
                              </div>
                              <div className=" mb-3">
                                <h1 className=" text-gray-600 font-medium">
                                  Email Address
                                </h1>
                                <h1 className=" text-gray-800 font-medium">
                                  {" "}
                                  {userinfo.email}
                                </h1>
                              </div>
                              <div className=" w-[200px] break-words mb-3">
                                <h1 className=" text-gray-600 font-medium">
                                  Address Line 1
                                </h1>
                                <h1 className=" text-gray-800 font-medium">
                                  {" "}
                                  {userInfo?.address_line_1}
                                </h1>
                              </div>
                              <div className=" w-[200px] break-words mb-3">
                                <h1 className=" text-gray-600 font-medium">
                                  Address Line 2
                                </h1>
                                <h1 className=" text-gray-800 font-medium">
                                  {" "}
                                  {userInfo?.address_line_2}
                                </h1>
                              </div>
                              <div className=" mb-3">
                                <h1 className=" text-gray-600 font-medium">
                                  State
                                </h1>
                                <h1 className=" text-gray-800 font-medium">
                                  {" "}
                                  {userInfo?.state}
                                </h1>
                              </div>
                              <div className=" w-[200px] break-words mb-3">
                                <h1 className=" text-gray-600 font-medium">
                                  Phone No
                                </h1>
                                <h1 className=" text-gray-800 font-medium">
                                  {" "}
                                  {userInfo?.mobile_no}
                                </h1>
                              </div>
                              <div className=" w-[200px] break-words mb-3">
                                <h1 className=" text-gray-600 font-medium">
                                  City
                                </h1>
                                <h1 className=" text-gray-800 font-medium">
                                  {" "}
                                  {userInfo?.city}
                                </h1>
                              </div>
                              <div className="break-words mb-3">
                                <h1 className=" text-gray-600 font-medium">
                                  Pin Code
                                </h1>
                                <h1 className=" text-gray-800 font-medium">
                                  {" "}
                                  {userInfo?.pincode}{" "}
                                </h1>
                              </div>
                            </>
                          );
                        })}
                      </div>
                      <div className=" flex flex-row justify-between items-start px-7 mt-3">
                        <div></div>
                      </div>
                    </div>
                  </div>
                  {/* <div className=" flex flex-col justify-start md:items-start items-center mt-4">
                    <h2 className=" text-[18px] font-semibold text-gray-700 mb-3">
                      Address
                    </h2>
                    <div className=" w-full md:w-[800px]  border-2 border-gray-200 p-3 flex flex-col  rounded-md">
                      <Link to={`${userInfoDetails[0]?.client_id}/address`}>
                        <div className=" flex justify-end items-center cursor-pointer">
                          <i className="fa-solid fa-pen text-gray-700"></i>
                        </div>
                      </Link>
                      <div className=" flex flex-col md:flex-row justify-between items-start md:px-7 p-[40px] md:p-[0px]">
                        
                        
                       
                      </div>
                      <div className=" flex flex-col md:flex-row justify-between items-start px-7 mt-3">
                       
                      
                        
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </Fragment>
        </>
      )}
      <FooterLoggedIn />
    </>
  );
};

export default Profile;

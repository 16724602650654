import { useFormik } from "formik";
import React, { useEffect } from "react";
import { loginPageSchema } from "../../validation";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { uri } from "../../https";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jwt_decode from "jwt-decode";

const Login = () => {
  const [googleUser, setGoogleUser] = useState([]);
  function handleCallbackResponse(response) {
    console.log("Encoded JWT ID Token", response.credential);
    var userObject = jwt_decode(response.credential);
    console.log(userObject);
    setGoogleUser(userObject);
  }

  console.log("Google User Details", googleUser.given_name);

  const StoreGoogleSignin = async () => {
    console.log("Google User Stored");

    const url = `${uri}api/google_signup`;
    const data = {
      name: googleUser.given_name,
      lname: googleUser.family_name,
      email: googleUser.email,
      password: googleUser.email,
      google_id: googleUser.sub,
    };

    console.log(data);

    await axios.post(url, data).then(
      (response) => {
        if (response.data.status === 200) {
          localStorage.setItem("analyst_token", JSON.stringify(response.data));

          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: true,
            theme: "light",
          });
          setGoogleUser("");
          window.location.replace(response.data.redirect_url);
          // history("/dashboard/client=/email=");
        }
      },
      (error) => {
        console.log(error.response.data);
        toast.error("Credients do not match", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: true,
          theme: "light",
        });
        setGoogleUser("");
      }
    );
  };

  useEffect(() => {
    if (googleUser.email) {
      // StoreGoogleSignin();
    }
  }, [googleUser]);

  useEffect(() => {
    // Global Google
    google.accounts.id.initialize({
      client_id:
        "700320995652-33jajhfel8pfkkkug39qs5ttil72jiqd.apps.googleusercontent.com",
      callback: handleCallbackResponse,
    });
    google.accounts.id.renderButton(document.getElementById("signInDiv"), {
      theme: "outline",
      size: "large",
    });
  }, []);

  const history = useNavigate();
  const onSubmit = async (values, actions) => {
    console.log("VALUES", values);
    console.log("ACTIONS", actions);

    const url = `${uri}api/login`;
    const data = {
      email: values.email,
      password: values.password,
    };

    await axios.post(url, data).then(
      (response) => {
        if (response.data.status === 200) {
          localStorage.setItem("analyst_token", JSON.stringify(response.data));
          actions.resetForm();
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: true,
            theme: "light",
          });
          window.location.replace(response.data.redirect_url);
          // history("/dashboard/client=/email=");
        }
      },
      (error) => {
        console.log(error.response.data);
        toast.error("Credients do not match", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: true,
          theme: "light",
        });
      }
    );
  };
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginPageSchema,
    onSubmit,
  });

  console.log(errors);

  const [passwordShown, setPasswordShown] = useState(false);
  // Password toggle handler
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  const [userProfileData, setUserProfileData] = useState([]);
  const GetUserProfile = async () => {
    const url = `${uri}api/me`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        setUserProfileData(response?.data.user_profile);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    GetUserProfile();
  }, []);

  const { clientid } = useParams();
  const { email } = useParams();

  console.log("Client Id from login", clientid);
  console.log("Client email from login", email);

  let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
  const user_token = tokendata?.token;
  const client_id = tokendata?.user?.client_id;
  console.log("Client id", client_id);

  useEffect(() => {
    if (user_token) {
      history(`/dashboard/clientId=${client_id}`);
    }
  }, [user_token]);

  return (
    <div className=" flex justify-start items-center place-items-start overflow-hidden">
      <div className="bg-[#3DC5E8] md:w-full md:h-screen hidden md:block">
        {/* <div className=" m-[80px] mt-[90px]">
          <h1 className=" bg-white text-gray-700 p-1 flex justify-center items-center rounded-full w-[140px] font-semibold">
            SP Analyst
          </h1>
          <div>
            <h1 className=" text-[34px] font-semibold text-gray-800 mt-4">
              Lorem Ipsum is dummy <br /> text by Spanalyst
            </h1>
          </div>
          <div className=" mt-4">
            <p className=" text-gray-800">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              <br />
              industry. Lorem Ipsum has been the industry's standard dummy{" "}
              <br /> text ever since the 1500s,
            </p>
          </div>
        </div> */}
        <img src="https://res.cloudinary.com/dv5jjlsd7/image/upload/v1695714309/SP%20ANALYST/Group_299_nqg8fq.png" />
      </div>
      <div className=" w-full md:w-[800px] h-screen  overflow-x-hidden overflow-y-hidden mt-[50px] md:mt-[0px]">
        <div className=" m-4 p-8 ">
          <div className=" flex justify-center items-center">
            <img
              src="analyst_logo.png"
              className=" relative h-[100px] md:h-[150px] "
            />
          </div>
          <div>
            <h1 className=" text-[20px] md:text-[23px] flex justify-center md:justify-start items-center  md:mt-[50px] font-semibold text-black">
              Log in
            </h1>
          </div>
          <form
            onSubmit={handleSubmit}
            className=" -ml-[0px] md:-ml-[0px]   md:px-0"
          >
            <div className=" mt-5">
              <label>Email address</label>
              <div className=" mt-2">
                <input
                  type="text"
                  name="email"
                  id="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className=" w-full py-3 outline-none bg-[#F0F0F0] rounded-md px-3 ring-1  ring-[#5aa7bc] focus:ring-[#015e75] "
                />
                {errors.email && touched.email && (
                  <span className="text-red-500 text-[16px]">
                    {errors.email}
                  </span>
                )}
              </div>
              <div className=" mt-4">
                <label>Password</label>
                <div className=" mt-2">
                  <input
                    type={passwordShown ? "text" : "password"}
                    name="password"
                    id="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className=" w-full py-3 outline-none bg-[#F0F0F0] rounded-md px-3 ring-1  ring-[#5aa7bc] focus:ring-[#015e75]"
                  />
                  {passwordShown ? (
                    <>
                      <i
                        onClick={togglePassword}
                        class="fa-solid fa-eye-slash cursor-pointer text-gray-600 text-[18px] absolute -ml-[40px]  md:-ml-[70px] mt-[17px]"
                      ></i>
                    </>
                  ) : (
                    <>
                      <i
                        onClick={togglePassword}
                        class="fa-solid fa-eye cursor-pointer text-gray-600 text-[18px] absolute -ml-[40px] md:-ml-[70px] mt-[17px]"
                      ></i>
                    </>
                  )}
                  {errors.password && touched.password && (
                    <span className="text-red-500 text-[16px]">
                      {errors.password}
                    </span>
                  )}
                </div>
              </div>
              {isSubmitting ? (
                <div className=" mt-5">
                  <button className=" w-full py-3  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all md:hover:scale-90">
                    <svg
                      aria-hidden="true"
                      class="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  </button>
                </div>
              ) : (
                <div className=" mt-5">
                  <button
                    type="submit"
                    className="w-full py-3  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all  md:hover:scale-90"
                  >
                    Login
                  </button>
                </div>
              )}
            </div>
          </form>
          <div className=" flex flex-col md:flex-row justify-center md:justify-between items-center mt-3 text-gray-700 gap-5 md:gap-2 mr-[10px] md:mr-[0px]">
            <Link to="/signup">
              <div className=" cursor-pointer flex flex-row gap-3 items-center">
                <i className="fa-solid fa-user"></i> <h1>Create an account</h1>
              </div>
            </Link>
            <Link to="/forgot-password">
              <div className=" cursor-pointer flex flex-row gap-3 items-center">
                <i className="fa-solid fa-lock-open"></i>{" "}
                <h1>Forgot password ?</h1>
              </div>
            </Link>
          </div>
          {/* <div class="relative flex py-5 w-[500px] items-center">
            <div class="flex-grow border-t border-gray-400"></div>
            <span class="flex-shrink mx-4 text-gray-700">Or</span>
            <div class="flex-grow border-t border-gray-400"></div>
          </div> */}
          {/* <div
            id="signInDiv"
            className=" w-full flex justify-center items-center"
          ></div> */}
          {/* <div className=" w-full  mb-[20px]">
            <button className=" w-[500px] py-3 bg-white text-black  border-gray-500 border-1 rounded-md font-semibold hover:bg-[#9aa0a1] transition-all hover:transition-all hover:scale-90">
              <img
                src="google.png"
                className=" h-[20px] absolute ml-[130px] md:ml-[130px]  mt-[2px] flex justify-center items-center  "
              />{" "}
              Signup with Google
            </button>
          </div> */}
          <div className=" flex flex-col md:flex-row justify-center items-center mt-[40px] md:mt-[100px] ">
            <div className=" flex flex-col md:flex-row justify-center items-center gap-5 text-gray-700 cursor-pointer ">
              <div>
                <h1>Terms and conditions</h1>
              </div>
              <div className=" h-[10px] w-[10px] rounded-full bg-[#3EC4E8] hidden md:block"></div>
              <div>
                <h1>Privacy Policy</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
    </div>
  );
};

export default Login;

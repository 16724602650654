// export const uri = "http://127.0.0.1:8000/";
export const uri = "https://api.spanalyst.com/public/";
export const app_version = "V1.0 Private Beta";
export const year = "2023";

// FACEBOOK

// DEVELOPMENT
// export const ConnectToFacebook = () => {
//   const url =
//     "https://www.facebook.com/v15.0/dialog/oauth?client_id=929196161691547&redirect_uri=http://localhost:3000/channels/connect&scope=email,pages_manage_cta,read_insights,publish_video,pages_manage_posts,read_insights,pages_messaging,pages_read_engagement,pages_show_list,publish_video,pages_show_list,ads_management,business_management,instagram_basic,instagram_manage_comments,instagram_content_publish,pages_read_user_content,pages_manage_engagement&state=12345";
//   window.location.replace(url);
// };

// DEVELOPMENT NEW
// export const ConnectToFacebook = () => {
//   const url =
//     "https://www.facebook.com/v15.0/dialog/oauth?client_id=705161074416447&redirect_uri=http://localhost:3000/channels/connect&scope=email,pages_manage_cta,read_insights,publish_video,pages_manage_posts,read_insights,pages_messaging,pages_read_engagement,pages_show_list,publish_video,pages_show_list,ads_management,business_management,instagram_basic,instagram_manage_comments,instagram_content_publish,pages_read_user_content,pages_manage_engagement&state=12345";
//   window.location.replace(url);
// };

// PRODUCTION
export const ConnectToFacebook = () => {
  const url =
    "https://www.facebook.com/v15.0/dialog/oauth?client_id=929196161691547&redirect_uri=https://spanalyst.com/channels/connect&scope=email,pages_manage_cta,read_insights,publish_video,pages_manage_posts,read_insights,pages_messaging,pages_read_engagement,pages_show_list,publish_video,pages_show_list,ads_management,business_management,instagram_basic,instagram_manage_comments,instagram_content_publish,pages_read_user_content,pages_manage_engagement&state=12345";
  window.location.replace(url);
};

// LINKEDIN
// DEVELOPMENT
// export const ConnectToLinkedIn = () => {
//   const url =
//     "https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86sdww211albka&redirect_uri=http://localhost:3000/channels/connect&state=foobar&scope=r_liteprofile%20r_emailaddress%20w_member_social%20";
//   window.location.replace(url);
// };
// PRODUCTION
export const ConnectToLinkedIn = () => {
  const url =
    "https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86sdww211albka&redirect_uri=https://spanalyst.com/channels/connect&state=foobar&scope=openid,profile,r_liteprofile,r_organization_social,rw_organization_admin,w_member_social,w_organization_social,r_organization_admin";
  window.location.replace(url);
};

// PINTEREST
// DEVELOPMENT
// export const ConnectToPintrest = () => {
//   const url =
//     "https://www.pinterest.com/oauth/?client_id=1488702&redirect_uri=http://localhost:3000/channels/connect&response_type=code&scope=boards:read,boards:write,pins:read,pins:write,user_accounts:read&state=12345";
//   window.location.replace(url);
// };

// PRODUCTION
export const ConnectToPintrest = () => {
  const url =
    "https://www.pinterest.com/oauth/?client_id=1488702&redirect_uri=https://spanalyst.com/channels/connect&response_type=code&scope=boards:read,boards:write,pins:read,pins:write,ads:read,ads:write,user_accounts:read&state=12345";
  window.location.replace(url);
};

// TUMBLR
// DEVELOPMENT
// export const ConnectToTumblr = () => {
//   const url =
//     "https://www.tumblr.com/oauth2/authorize?client_id=lkxUYjyUVzyz5fV1S8G5zA2QHPfj0DtqLEt5PuuOfMX7yDKB4P&response_type=code&scope=basic%20write%20offline_access&state=1131313&redirect_uri=http://localhost:3000/channels/connect";
//   window.location.replace(url);
// };
// PRODUCTION
export const ConnectToTumblr = () => {
  const url =
    "https://www.tumblr.com/oauth2/authorize?client_id=tkZuegfAhkvn0QDeHlwUdmKjLEJQakaAgDmkCXOXb8CNwMiUUR&response_type=code&scope=basic%20write%20offline_access&state=1131313&redirect_uri=https://spanalyst.com/channels/connect";
  window.location.replace(url);
};

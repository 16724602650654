import React, { useContext, useEffect, useState } from "react";
import GlobalContext from "./context/GlobalContext";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { uri } from "./https";
import { useScroll } from "framer-motion";

const ShareLocationModal = ({ user_country }) => {
  const {
    setShowEventModal,
    daysSelected,
    dispatchCalEvent,
    selectedEvent,
    setShowShareLocation,
    showShareLocation,
  } = useContext(GlobalContext);

  const [location_submitted_message, setLocationSubmittedMessage] =
    useState("");
  const handleSubmitLocation = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("country", user_country);

    const url = `${uri}api/store_users_location`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;

    let config = {
      headers: {
        Authorization: "Bearer " + token,
        accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    };
    axios.post(url, formData, config).then(
      (response) => {
        if (response.data.status === 200) {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setShowShareLocation(false);
          setLocationSubmittedMessage(response.data.message);
        }
        console.log(response.data);
      },
      (error) => {
        toast.error("Failed to create event try again later", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.log(error.response.data);
      }
    );

    // console.log("Calender event", calendarEvent);
  };

  const [stored_location, setStoredLocation] = useState([]);
  const GetUserStoredLocation = async () => {
    const url = `${uri}api/get_users_location`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        setStoredLocation(response?.data.users_location);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    GetUserStoredLocation();
  }, [location_submitted_message]);

  return (
    <div className=" h-screen w-full fixed left-0 top-[0px] bg-black/50 backdrop-blur-sm flex justify-center items-center  share_location">
      <div className=" flex flex-row gap-2 m-[80px] ">
        <div className=" bg-white rounded-md shadow-lg  w-full md:w-[600px] ">
          <header className="bg-[#f5f5f5]  px-4 py-2 flex justify-between items-center rounded-md  shadow-md">
            <h1 className=" font-semibold text-[16px] text-gray-800">
              Location Access Request
            </h1>
            <button onClick={() => setShowShareLocation(false)}>
              <span className="material-icons-outlined text-gray-400">
                close
              </span>
            </button>
          </header>
          <div className=" p-3">
            <div className=" w-full  bg-white  p-5 ">
              <div className=" flex justify-center items-center">
                <img
                  src="https://res.cloudinary.com/dv5jjlsd7/image/upload/v1693478184/SP%20ANALYST/Location_Access_uagoxe.png"
                  className=" h-[250px] object-contain"
                />
              </div>
              <div className=" flex justify-center items-center">
                <h1 className="font-semibold text-gray-800 text-[24px] capitalize">
                  Timezone Access Request
                </h1>
              </div>
              <div className=" flex justify-center items-center mt-[10px]">
                <p className=" font-medium text-gray-800 text-[17px] capitalize">
                  We Don't Have Your Current Timezone Please Allow The App
                  Location Access And Click On The Allow Button Below To Get
                  Your Location And TimeZone
                </p>
              </div>
            </div>
            <div className=" flex justify-center items-center gap-[20px] p-5">
              {user_country !== undefined ? (
                <>
                  <button
                    onClick={handleSubmitLocation}
                    className=" py-3 px-6  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all  md:hover:scale-90"
                  >
                    Allow
                  </button>
                </>
              ) : (
                <>
                  <button
                    // onClick={handleSubmitLocation}
                    className="py-2 w-[100px]  bg-[#0884AA] rounded-md text-white font-semibold text-[16px] hover:bg-[#086885] transition-all hover:transition-all hover:shadow-xl  "
                  >
                    Preparing
                  </button>
                </>
              )}

              <button
                onClick={() => setShowShareLocation(false)}
                className="py-2 w-[100px] bg-[#e24245] rounded-md text-white font-semibold text-[16px] hover:bg-[#b03030] transition-all hover:transition-all hover:shadow-xl  "
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
    </div>
  );
};

export default ShareLocationModal;

import React from 'react'

const PostInfo = () => {
  return (
    <>
      <a className='a-tag'>Hover over me!</a>
      <div className='div-tag'>Stuff shown on hover</div>
    </>
  );
}

export default PostInfo
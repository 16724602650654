import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { registerPageSchema } from "../../validation";
import { Link, useNavigate } from "react-router-dom";
import { uri } from "../../https";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SignupNew = () => {
  const [loading, setLoading] = useState(false);
  const history = useNavigate();
  const formList = [
    "FirstForm",
    "SecondForm",
    "ThirdForm",
    "FourthForm",
    "FifthForm",
  ];

  const formLength = formList.length;

  const [page, setPage] = useState(0);

  const handlePrev = () => {
    setPage(page === 0 ? formLength - 1 : page - 1);
  };
  const handleNext = () => {
    setPage(page === formLength - 1 ? 0 : page + 1);
  };

  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    password: "",
  };
  const [valuess, setValues] = useState(initialValues);

  const [passwordShown, setPasswordShown] = useState(false);
  // Password toggle handler
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  const onSubmit = async (values, actions) => {
    // console.log("VALUES", values);
    // console.log("ACTIONS", actions);
    let username = (values.firstname + values.lastname).toLowerCase();

    const url = `${uri}api/register`;
    const data = {
      name: values.firstname,
      username: username,
      lname: values.lastname,
      address_1: values.address_1,
      address_2: values.address_2,
      city: values.city,
      user_state: values.state_user,
      pin_code: values.pin_code,
      mobile_no: values.mobile_no,
      email: values.email,
      password: values.password,
    };

    // console.log("Submitted Response", data)

    await axios.post(url, data).then(
      (response) => {
        if (response.data.status === 200) {
          localStorage.setItem("analyst_token", JSON.stringify(response.data));
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          actions.resetForm();

          window.location.replace(response.data.redirect_url);
        } else {
          toast.error(response.data.message, {
            position: "bottom-right",
          });
        }
      },
      (error) => {
        console.log(error.response.data);
        toast.error(error.response.data.message, {
          position: "bottom-right",
        });
      }
    );
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      address_1: "",
      address_2: "",
      state_user: "",
      city: "",
      pin_code: "",
      mobile_no: "",
      email: "",
      password: "",
    },
    validationSchema: registerPageSchema,
    onSubmit,
  });

  console.log(errors);

  const handleForms = () => {
    switch (page) {
      case 0: {
        return (
          <div>
            <div className=" flex flex-col  gap-5 mt-[20px] px-[0px] md:px-[0px]">
              <div className=" flex  flex-col ">
                <label className=" mb-2">First Name</label>
                <input
                  type="text"
                  id="firstname"
                  name="firstname"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstname}
                  required
                  className="  w-full  py-3 outline-none ring-1  ring-[#5aa7bc] focus:ring-[#015e75] bg-[#F0F0F0] rounded-md px-3"
                />
                {errors.firstname && touched.firstname && (
                  <span className="text-red-500 text-[16px]">
                    {errors.firstname}
                  </span>
                )}
              </div>
              <div className=" flex  flex-col mt-1">
                <label className=" mb-2">Last Name</label>
                <input
                  type="text"
                  id="lastname"
                  name="lastname"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastname}
                  required
                  className=" w-full py-3 outline-none ring-1 ring-[#5aa7bc] focus:ring-[#015e75] bg-[#F0F0F0] rounded-md px-3"
                />
                {errors.lastname && touched.lastname && (
                  <span className="text-red-500 text-[16px]">
                    {errors.lastname}
                  </span>
                )}
              </div>
            </div>
          </div>
        );
      }
      case 1: {
        return (
          <form>
            <div>
              <div className=" flex flex-col  gap-2 mt-[20px]">
                <div className=" flex  flex-col ">
                  <label className="mb-2">Address 1</label>
                  <input
                    type="text"
                    id="address_1"
                    name="address_1"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address_1}
                    required
                    className=" w-full  py-3 ring-1 ring-[#5aa7bc] focus:ring-[#015e75] bg-[#F0F0F0] rounded-md px-3"
                  />
                  {errors.address_1 && touched.address_1 && (
                    <span className="text-red-500 text-[16px]">
                      {errors.address_1}
                    </span>
                  )}
                </div>
                <div className=" flex flex-col gap-3 mt-[10px]">
                  <div className=" flex  flex-col ">
                    <label className="mb-2">Address 2</label>
                    <input
                      type="text"
                      id="address_2"
                      name="address_2"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.address_2}
                      className=" w-full  py-3 outline-none ring-1 ring-[#5aa7bc] focus:ring-[#015e75] bg-[#F0F0F0] rounded-md px-3"
                    />
                    {errors.address_2 && touched.address_2 && (
                      <span className="text-red-500 text-[16px]">
                        {errors.address_2}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        );
      }
      case 2: {
        return (
          <form>
            <div>
              <div className=" flex flex-col  gap-2 mt-[20px]">
                <div className=" flex  flex-col ">
                  <label className="mb-2">State</label>
                  <input
                    type="text"
                    id="state_user"
                    name="state_user"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.state_user}
                    required
                    className=" w-full  py-3 ring-1 ring-[#5aa7bc] focus:ring-[#015e75] bg-[#F0F0F0] rounded-md px-3"
                  />
                  {errors.state_user && touched.state_user && (
                    <span className="text-red-500 text-[16px]">
                      {errors.state_user}
                    </span>
                  )}
                </div>
                <div className=" flex flex-col gap-3 mt-[10px]">
                  <div className=" flex  flex-col ">
                    <label className="mb-2">City</label>
                    <input
                      type="text"
                      id="city"
                      name="city"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.city}
                      className=" w-full  py-3 outline-none ring-1 ring-[#5aa7bc] focus:ring-[#015e75] bg-[#F0F0F0] rounded-md px-3"
                    />
                    {errors.city && touched.city && (
                      <span className="text-red-500 text-[16px]">
                        {errors.city}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        );
      }
      case 3: {
        return (
          <form>
            <div>
              <div className=" flex flex-col  gap-2 mt-[20px]">
                <div className=" flex  flex-col ">
                  <label className="mb-2">Pincode</label>
                  <input
                    type="text"
                    id="pin_code"
                    name="pin_code"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.pin_code}
                    required
                    className=" w-full  py-3 ring-1 ring-[#5aa7bc] focus:ring-[#015e75] bg-[#F0F0F0] rounded-md px-3"
                  />
                  {errors.pin_code && touched.pin_code && (
                    <span className="text-red-500 text-[16px]">
                      {errors.pin_code}
                    </span>
                  )}
                </div>
                <div className=" flex flex-col gap-3 mt-[10px]">
                  <div className=" flex  flex-col ">
                    <label className="mb-2">Mobile No</label>
                    <input
                      type="text"
                      id="mobile_no"
                      name="mobile_no"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.mobile_no}
                      className=" w-full  py-3 outline-none ring-1 ring-[#5aa7bc] focus:ring-[#015e75] bg-[#F0F0F0] rounded-md px-3"
                    />
                    {errors.mobile_no && touched.mobile_no && (
                      <span className="text-red-500 text-[16px]">
                        {errors.mobile_no}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        );
      }
      case 4: {
        return (
          <form>
            <div>
              <div className=" flex flex-col  gap-2 mt-[20px]">
                <div className=" flex  flex-col ">
                  <label className="mb-2">Email</label>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    required
                    className=" w-full  py-3 ring-1 ring-[#5aa7bc] focus:ring-[#015e75] bg-[#F0F0F0] rounded-md px-3"
                  />
                  {errors.email && touched.email && (
                    <span className="text-red-500 text-[16px]">
                      {errors.email}
                    </span>
                  )}
                </div>
                <div className=" flex flex-col gap-3 mt-[10px]">
                  <div className=" flex  flex-col ">
                    <label className="mb-2">Password</label>
                    <input
                      type={passwordShown ? "text" : "password"}
                      id="password"
                      name="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      className=" w-full  py-3 outline-none ring-1 ring-[#5aa7bc] focus:ring-[#015e75] bg-[#F0F0F0] rounded-md px-3"
                    />
                    {errors.password && touched.password && (
                      <span className="text-red-500 text-[16px]">
                        {errors.password}
                      </span>
                    )}
                    {passwordShown ? (
                      <>
                        <i
                          onClick={togglePassword}
                          class="fa-solid fa-eye-slash cursor-pointer text-gray-600 text-[18px] absolute ml-[250px]  md:ml-[350px] mt-[47px]"
                        ></i>
                      </>
                    ) : (
                      <>
                        <i
                          onClick={togglePassword}
                          class="fa-solid fa-eye cursor-pointer text-gray-600 text-[18px] absolute ml-[250px] md:ml-[350px] mt-[47px]"
                        ></i>
                      </>
                    )}
                  </div>
                  {/* <div className=" flex  flex-col mt-1">
                    <label className="mb-2">Confirm Password</label>
                    <input
                      type={passwordShown ? "text" : "password"}
                      id="password_confirmation"
                      name="password_confirmation"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password_confirmation}
                      className="  w-[500px] py-3 outline-none ring-1 ring-blue-400 focus:ring-blue-600 bg-[#F0F0F0] rounded-md px-3"
                    />
                    {errors.password_confirmation &&
                      touched.password_confirmation && (
                        <span className="text-red-500 text-[16px]">
                          {errors.password_confirmation}
                        </span>
                      )}
                  </div> */}
                </div>
              </div>
            </div>
          </form>
        );
      }

      default:
        return null;
    }
  };

  const setForm = (e) => {
    const name = e.target.innerText;
    switch (name) {
      case "Person Info": {
        return setPage(0);
      }
      case "Address": {
        return setPage(1);
      }
      case "StateUser": {
        return setPage(2);
      }
      case "PinCode": {
        return setPage(3);
      }
      case "Email": {
        return setPage(4);
      }
      default:
        setPage(0);
    }
  };

  let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
  const user_token = tokendata?.token;
  const client_id = tokendata?.user?.client_id;
  console.log("Client id", client_id);

  useEffect(() => {
    if (user_token) {
      history(`/dashboard/clientId=${client_id}`);
    }
  }, [user_token]);

  return (
    <div className=" flex justify-start items-center place-items-start over">
      <div className="bg-[#3DC5E8] md:w-full md:h-screen hidden md:block">
        {/* <div className=" m-[80px] mt-[90px]">
          <h1 className=" bg-white text-gray-700 p-1 flex justify-center items-center rounded-full w-[140px] font-semibold">
            SP Analyst
          </h1>
          <div>
            <h1 className=" text-[34px] font-semibold text-gray-800 mt-4">
              Lorem Ipsum is dummy <br /> text by Spanalyst
            </h1>
          </div>
          <div className=" mt-4">
            <p className=" text-gray-800">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              <br />
              industry. Lorem Ipsum has been the industry's standard dummy{" "}
              <br /> text ever since the 1500s,
            </p>
          </div>
        </div> */}
        <img src="https://res.cloudinary.com/dv5jjlsd7/image/upload/v1695720804/SP%20ANALYST/Group_300_gb2p4o.png" />
      </div>
      <div className=" w-full md:w-[800px] h-screen mt-[50px] md:mt-[0px] ">
        <div className=" m-4 p-8">
          <div className=" flex justify-center items-center">
            <img
              src="analyst_logo.png"
              className=" relative h-[100px] md:h-[150px]"
            />
          </div>
        </div>
        <div className=" m-4 p-6 -mt-[80px] flex justify-center flex-col items-center">
          <form onSubmit={handleSubmit}>
            <div className=" ">
              <h1 className=" text-[20px] md:text-[23px] flex  justify-center items-center md:justify-start  md:mt-[50px] font-semibold text-black md:mr-[80px]">
                Let's get your account set up
              </h1>
              <div className="">
                <ul className="flex justify-around md:gap-[40px] w-full items-center">
                  <li onClick={setForm}></li>

                  <li
                    onClick={setForm}
                    className={
                      page === 1
                        ? "bg-gradient-to-t bg-[#727cf5] w-1/6 text-[15px]"
                        : "bg: transparent text-[15px]"
                    }
                  ></li>
                  <li
                    onClick={setForm}
                    className={
                      page === 2
                        ? "bg-gradient-to-t bg-[#727cf5] w-1/6 text-[15px]"
                        : "bg: transparent text-[15px]"
                    }
                  ></li>
                  <li
                    onClick={setForm}
                    className={
                      page === 3
                        ? "bg-gradient-to-t bg-[#727cf5] w-1/6 text-[15px]"
                        : "bg: transparent text-[15px]"
                    }
                  ></li>
                  <li
                    onClick={setForm}
                    className={
                      page === 4
                        ? "bg-gradient-to-t bg-[#727cf5] w-1/6 text-[15px]"
                        : "bg: transparent text-[15px]"
                    }
                  ></li>
                </ul>
              </div>

              <div className="flex-1">{handleForms()}</div>

              <div className=" flex flex-col justify-center mt-5 gap-5">
                <div className=" w-full ">
                  {page === 0 ? (
                    <></>
                  ) : (
                    <>
                      <button
                        onClick={handlePrev}
                        disabled={page === 0}
                        className=" w-full py-3  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all md:hover:scale-90"
                      >
                        <i className="fa-solid fa-arrow-left"></i> Previous
                      </button>
                    </>
                  )}
                </div>

                {page === 1 ? (
                  <>
                    <div className=" w-full mt-[10px] mb-2 ">
                      <button
                        onClick={handleNext}
                        className="w-full py-3  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all md:hover:scale-90"
                      >
                        Next <i className="fa-solid fa-arrow-right"></i>
                      </button>
                    </div>
                  </>
                ) : page === 2 ? (
                  <>
                    <div className=" w-full mt-[10px] mb-2 ">
                      <button
                        onClick={handleNext}
                        className="w-full py-3  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all md:hover:scale-90"
                      >
                        Next <i className="fa-solid fa-arrow-right"></i>
                      </button>
                    </div>
                  </>
                ) : page === 3 ? (
                  <>
                    <div className=" w-full mt-[10px] mb-2 ">
                      <button
                        onClick={handleNext}
                        className="w-full py-3  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all md:hover:scale-90"
                      >
                        Next <i className="fa-solid fa-arrow-right"></i>
                      </button>
                    </div>
                  </>
                ) : page === 4 ? (
                  <>
                    <div className=" w-full ">
                      {isSubmitting ? (
                        <button className=" w-full py-3  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all md:hover:scale-90">
                          <svg
                            aria-hidden="true"
                            class="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="w-full py-3  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all md:hover:scale-90"
                        >
                          Create Account
                        </button>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className=" w-full -mt-[20px] mb-2 ">
                      <button
                        onClick={handleNext}
                        className="w-full py-3  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all md:hover:scale-90"
                      >
                        Next <i className="fa-solid fa-arrow-right"></i>
                      </button>
                    </div>
                  </>
                )}

                {/* {page === 1 ? (
                  <>
                    {loading ? (
                      <>
                        <button className=" w-full py-3  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all md:hover:scale-90">
                          <svg
                            aria-hidden="true"
                            class="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                        </button>
                      </>
                    ) : (
                      <>
                        {values.firstname &&
                        values.lastname &&
                        values.email &&
                        values.password ? (
                          <>
                            <div className=" w-full ">
                              {isSubmitting ? (
                                <button className=" w-full py-3  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all md:hover:scale-90">
                                  <svg
                                    aria-hidden="true"
                                    class="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                      fill="currentFill"
                                    />
                                  </svg>
                                </button>
                              ) : (
                                <button
                                  type="submit"
                                  className="w-full py-3  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all md:hover:scale-90"
                                >
                                  Create Account
                                </button>
                              )}
                            </div>
                          </>
                        ) : (
                          <div className=" w-full ">
                            <button className=" w-full py-3 bg-[#858b8d] text-white rounded-md font-semibold  transition-all hover:transition-all ">
                              Create Account
                            </button>
                          </div>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {values.firstname && values.lastname !== "" ? (
                      <>
                        <div className=" w-full -mt-[20px] mb-2 ">
                          <button
                            onClick={handleNext}
                            className="w-full py-3  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all md:hover:scale-90"
                          >
                            Next <i className="fa-solid fa-arrow-right"></i>
                          </button>
                        </div>
                      </>
                    ) : (
                      <div className=" w-full -mt-[20px] mb-2 ">
                        <button className=" w-full py-3 bg-[#858b8d] text-white rounded-md font-semibold transition-all hover:transition-all ">
                          Next <i className="fa-solid fa-arrow-right"></i>
                        </button>
                      </div>
                    )}
                  </>
                )} */}
              </div>
              <div className="">
                {/* <p className=" text-green-500">{message}</p> */}
              </div>

              <div>
                <div className=" mt-[10px] flex flex-col md:flex-row justify-between items-center gap-2  text-gray-700 md:gap-10">
                  <div className=" cursor-pointer">
                    <h1 className=" ">
                      I agree to
                      <span className=" text-[#3EC4E8] ml-[10px]">
                        Term of Service
                      </span>{" "}
                    </h1>
                  </div>
                  <Link to="/login">
                    <div className=" cursor-pointer flex flex-row gap-3 items-center">
                      <i className="fa-solid fa-user"></i>{" "}
                      <h1>Have an account?</h1>
                    </div>
                  </Link>
                </div>
              </div>
              {/* <div class="relative flex py-5 w-[500px] items-center">
                <div class="flex-grow border-t border-gray-400"></div>
                <span class="flex-shrink mx-4 text-gray-700">Or</span>
                <div class="flex-grow border-t border-gray-400"></div>
              </div> */}
              {/* <div className=" w-full mt-[20px]">
                <button className=" w-[500px] py-3 bg-white text-black  border-gray-500 border-1 rounded-md font-semibold hover:bg-[#9aa0a1] transition-all hover:transition-all hover:scale-90">
                  <img
                    src="google.png"
                    className=" h-[20px] absolute ml-[130px] md:ml-[130px]  mt-[2px] flex justify-center items-center  "
                  />{" "}
                  Signup with Google
                </button>
              </div> */}
            </div>
          </form>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
    </div>
  );
};

export default SignupNew;

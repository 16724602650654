import React, { useEffect, useState } from "react";
import { uri } from "../../../https";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { Line } from "react-chartjs-2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PorfileEditPersonal = () => {
  const { id } = useParams();

  const [userInfoDetails, setUserInfoDetails] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  const [name, setName] = useState("");
  const [lname, setlName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [mobileno, setMobileNo] = useState("");
  const GetUserProfileInfo = async () => {
    const url = `${uri}api/fetch_users_info`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        setUserInfoDetails(response?.data?.user_details);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const [updateProfileMessage, setUpdateProfileMessage] = useState("");
  const UpdateUserDetails = () => {
    const url = `${uri}api/update_user_details`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    const data = {
      username: username,
    };
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    axios.post(url, data, config).then(
      (response) => {
        console.log(response.data);

        setUpdateProfileMessage(response.data.message);
        toast(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        // setShowFacebookPageModal(false);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    GetUserProfileInfo();
  }, [updateProfileMessage]);

  return (
    <div className="container  mt-[100px] px-[200px]">
      <div className=" border-1 border-gray-600  p-5  rounded-md">
        <div className=" pl-3">
          <div className=" flex justify-center items-center text-white text-[33px] font-semibold h-[60px] w-[60px] bg-[#0180a1] rounded-full cursor-pointer ">
            N
          </div>
          <i className="fa-solid fa-pen relative left-[60px] -top-[15px] text-gray-700 cursor-pointer"></i>
        </div>
        <div className=" grid grid-cols-2 place-items-center gap-y-7 mb-10   gap-8 px-[60px]">
          <div className=" w-full">
            <h1 className=" text-gray-600 font-medium mb-1">First Name</h1>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className=" w-full rounded-md  bg-[#0180a1]/20  py-3 font-medium text-gray-800 border-1 border-gray-500 focus:border-none focus:ring-0 "
            />
          </div>
          <div className=" w-full">
            <h1 className=" text-gray-600 font-medium mb-1">Last Name</h1>
            <input
              type="text"
              value={lname}
              onChange={(e) => setlName(e.target.value)}
              className=" w-full rounded-md  bg-[#0180a1]/20  py-3 font-medium text-gray-800 border-1 border-gray-500 focus:border-none focus:ring-0  "
            />
          </div>
          <div className=" w-full">
            <h1 className=" text-gray-600 font-medium mb-1">User Name</h1>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="  w-full rounded-md  bg-[#0180a1]/20  py-3 font-medium text-gray-800 border-1 border-gray-500 focus:border-none focus:ring-0 "
            />
          </div>
          <div className=" w-full">
            <h1 className=" text-gray-600 font-medium mb-1">Email Address</h1>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="  w-full rounded-md  bg-[#0180a1]/20  py-3 font-medium text-gray-800 border-1 border-gray-500 focus:border-none focus:ring-0 "
            />
          </div>
          {/* <div className=" w-full">
            <h1 className=" text-gray-600 font-medium mb-1">Mobile No</h1>
            <input
              type="text"
              value={mobileno}
              onChange={(e) => setMobileNo(e.target.value)}
              className="  w-full rounded-md  bg-[#0180a1]/20  py-3 font-medium text-gray-800 border-1 border-gray-500 focus:border-none focus:ring-0 "
            />
          </div> */}
        </div>
      </div>
      <div className=" flex justify-end items-center gap-4 mt-5">
        <div>
          <Link to="/profile-details">
            <button className=" px-5 py-2 bg-red-500 shadow-md rounded-md text-white transition-all  hover:transition-all hover:bg-red-500/90">
              Cancel
            </button>
          </Link>
        </div>
        <div>
          <button
            onClick={UpdateUserDetails}
            className=" px-5 py-2 bg-[#0180a1] shadow-md rounded-md text-white transition-all  hover:transition-all hover:bg-[#0180a1]/90"
          >
            Save
          </button>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
    </div>
  );
};

export default PorfileEditPersonal;

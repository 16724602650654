import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { uri } from "../../../https";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TopBar = () => {
  const history = useNavigate();
  const [profileModal, setProfileModal] = useState(false);
  const [navbar_controller_dashboard, setNavbarControllerDashboard] =
    useState(false);

  const [userProfileData, setUserProfileData] = useState([]);
  const GetUserProfile = async () => {
    const url = `${uri}api/me`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        setUserProfileData(response?.data?.user_profile);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    GetUserProfile();
  }, []);

  const { clientid } = useParams();

  const Logout = async () => {
    const url = `${uri}api/logout`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        console.log(response.data);
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        localStorage.removeItem("analyst_token");
        history("/login");
        window.location.reload(true);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const [subscribed_plan, setSubscribedPlan] = useState([]);
  const GetSubscribedPackageDetails = async () => {
    const url = `${uri}api/get_subscribed_plan`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        setSubscribedPlan(response?.data?.subscribed_plan);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    GetSubscribedPackageDetails();
  }, []);

  return (
    <>
      <div className=" w-full flex flex-row justify-between items-center bg-white shadow-md px-[40px] ">
        <div className=" flex justify-around items-center ">
          {/* <Link to={`/dashboard/clientId=${userProfileData?.client_id}`}>
            <div>
              <img
                src="https://res.cloudinary.com/dv5jjlsd7/image/upload/v1688033750/analyst_logo_efwgjv.png"
                className=" h-[75px] mt-[10px] md:h-[80px] object-contain"
              />
            </div>
          </Link> */}
          <div className=" mt-[10px]">
            <Link to={`/dashboard/clientId=${userProfileData?.client_id}`}>
              <img
                src="https://res.cloudinary.com/dv5jjlsd7/image/upload/v1688033750/analyst_logo_efwgjv.png"
                className=" h-[80px] object-contain cursor-pointer"
              />
            </Link>
          </div>
          <ul className=" md:flex justify-around items-center gap-[3px] m-5 hidden md:block">
            <NavLink activeClassName="active" to="/published">
              <li className=" hover:bg-[#0180a1]/20 px-4 py-3 flex justify-center items-center  cursor-pointer rounded-md transition-all hover:transition-all">
                <a className=" text-[16px] font-semibold  ">
                  <i className="fa-solid fa-upload text-[20px]"></i> Published
                </a>
              </li>
            </NavLink>

            <NavLink
              activeClassName="active"
              to="/analytics"
              className=" hover:bg-[#0180a1]/20 px-4 py-3 flex justify-center items-center cursor-pointer rounded-md transition-all hover:transition-all"
            >
              <a className=" text-[16px] font-semibold ">
                {" "}
                <i className="fa-solid fa-arrow-trend-up text-[20px]"></i>{" "}
                Analytics
              </a>
            </NavLink>

            <NavLink
              activeClassName="active"
              to="/connected-channels"
              className=" hover:bg-[#0180a1]/20 px-4 py-3  flex justify-center items-center cursor-pointer rounded-md transition-all hover:transition-all"
            >
              <a className=" text-[16px] font-semibold ">
                {" "}
                <i class="fa-solid fa-table-list text-[20px]"></i> Channels
              </a>
            </NavLink>
          </ul>
        </div>
        <div className="md:flex justify-around items-center gap-[10px] m-5 hidden md:block">
          <ul className=" flex justify-around items-center gap-[20px] m-5">
            <NavLink activeClassName="active" to="/subscription">
              <li
                className={` text-[16px] font-semibold px-6 py-2 text-[#0180a1] hover:text-[#2f9dbc] transition-all hover:transition-all cursor-pointer subscription_box `}
              >
                <i className="fa-solid fa-bolt  "></i> Upgrade
              </li>
            </NavLink>
            <li>
              <a className=" text-[16px] text-gray-500 cursor-pointer">
                {" "}
                {userProfileData?.email}
                {/* naveen.k@sparklerpro.com */}
              </a>
            </li>
          </ul>

          <div
            onClick={() => setProfileModal(!profileModal)}
            className=" flex justify-center items-center text-white text-[18px] font-semibold h-[40px] w-[40px] bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] rounded-full cursor-pointer "
          >
            {userProfileData?.name?.slice(0, 1)}
          </div>
        </div>
        <div
          onClick={() =>
            setNavbarControllerDashboard(!navbar_controller_dashboard)
          }
          className="flex justify-around mr-[10px] items-center gap-[10px] m-5  block  md:hidden"
        >
          <i
            class={`${
              !navbar_controller_dashboard
                ? "fa-solid fa-bars"
                : "fa-solid fa-x"
            } text-[24px] text-gray-700 duration-300`}
          ></i>
        </div>
        {profileModal && (
          <div className=" hidden md:block md:w-[350px] h-[290px] bg-white absolute right-[20px] top-[90px] shadow-md rounded-md border-1 border-gray-500">
            <div className=" flex justify-start items-start p-5 gap-4 hover:bg-[#0180a1]/10 transition-all hover:transition-all cursor-pointer">
              <div>
                <div className=" flex justify-center items-center text-white text-[18px] font-semibold h-[40px] w-[40px] bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] rounded-full cursor-pointer ">
                  {userProfileData?.name?.slice(0, 1)}
                </div>
              </div>
              <div className=" flex flex-col">
                <h1 className=" font-semibold text-gray-700">
                  {" "}
                  {userProfileData?.name + " " + userProfileData?.lname}
                </h1>
                <span className="text-[14px] text-gray-600">
                  {userProfileData?.email}
                </span>
                <span className="text-[14px] mt-1 bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white  hover:bg-[#0180a1]/10  font-semibold flex items-center justify-center p-2  rounded-md">
                  {/* {userProfileData?.client_id} */}
                  {subscribed_plan?.plan_name ? (
                    <>{subscribed_plan?.plan_name} Plan</>
                  ) : (
                    <>No Active Plan Found</>
                  )}
                </span>
              </div>
            </div>
            <NavLink to="/profile-details">
              <div className=" flex  justify-start items-center gap-3 p-5 -mt-[10px] hover:bg-[#0180a1]/10 transition-all hover:transition-all cursor-pointer ">
                <i className="fa-solid fa-user"></i>
                <h1 className=" font-medium text-gray-700">Profile</h1>
              </div>
            </NavLink>

            <NavLink to="/billing-details">
              <div className="  flex  justify-start items-center gap-3 p-5  -mt-[10px] hover:bg-[#0180a1]/10 transition-all hover:transition-all cursor-pointer ">
                <i className="fa-solid fa-file-invoice"></i>
                <h1 className=" font-medium text-gray-700">Billing</h1>
              </div>
            </NavLink>

            <div
              onClick={Logout}
              className=" flex  justify-start items-center gap-3 p-5 -mt-[10px] hover:bg-[#0180a1]/10 transition-all hover:transition-all cursor-pointer "
            >
              <i className="fa-solid fa-arrow-right-from-bracket"></i>
              <h1 className=" font-medium text-gray-700">Logout</h1>
            </div>
          </div>
        )}
      </div>
      {navbar_controller_dashboard && (
        <>
          <div className=" w-full bg-white px-3 p-3 dashboard_topbar absolute duration-300 block md:hidden">
            <ul className=" flex flex-col justify-start items-start gap-[20px]  md:gap-[50px] font-semibold text-gray-700  p-3 ml-[20px]">
              <NavLink
                to="/published"
                className="hover:text-[#5aa7bc] transition-all hover:transition-all border-b  border-b-gray-600 p-3 w-full flex-1 cursor-pointer"
              >
                <a>Published</a>
              </NavLink>
              <NavLink
                to="/analytics"
                className="hover:text-[#5aa7bc] transition-all hover:transition-all border-b  border-b-gray-600 p-3 w-full flex-1 cursor-pointer"
              >
                <a>Analytics</a>
              </NavLink>
              <NavLink
                to="/connected-channels"
                className="hover:text-[#5aa7bc] transition-all hover:transition-all border-b  border-b-gray-600 p-3 w-full flex-1 cursor-pointer"
              >
                <a>Channels</a>
              </NavLink>
              <NavLink
                to="/profile-details"
                className="hover:text-[#5aa7bc] transition-all hover:transition-all border-b  border-b-gray-600 p-3 w-full flex-1 cursor-pointer"
              >
                <a>Profile</a>
              </NavLink>
              <NavLink
                to="/billing-details"
                className="hover:text-[#5aa7bc] transition-all hover:transition-all border-b  border-b-gray-600 p-3 w-full flex-1 cursor-pointer"
              >
                <a>Billing</a>
              </NavLink>
              <NavLink
                to="/subscription"
                className="hover:text-[#5aa7bc] transition-all hover:transition-all border-b  border-b-gray-600 p-3 w-full flex-1 cursor-pointer"
              >
                <a>Upgrade</a>
              </NavLink>
            </ul>

            <div className=" flex justify-center items-center">
              <button
                onClick={Logout}
                className=" mt-[20px] ml-[20px]  w-[170px] py-3  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all md:hover:scale-90 cursor-pointer"
              >
                Logout
              </button>
            </div>
          </div>
          {/* <div className=" w-full bg-white px-3 absolute duration-300 dashboard_topbar">
            <ul className=" flex flex-col justify-start items-start gap-[50px] font-semibold text-gray-700 cursor-pointer p-3 ml-[60px]">
              <NavLink activeClassName="active" to="/published">
                <li className=" w-full hover:bg-[#0180a1]/20 px-4 py-3 flex justify-center items-center  cursor-pointer rounded-md transition-all hover:transition-all">
                  <a className=" text-[16px] font-semibold  ">
                    <i className="fa-solid fa-upload text-[20px]"></i> Published
                  </a>
                </li>
              </NavLink>

              <NavLink
                activeClassName="active"
                to="/analytics"
                className=" hover:bg-[#0180a1]/20 px-4 py-3 flex justify-center items-center cursor-pointer rounded-md transition-all hover:transition-all"
              >
                <a className=" text-[16px] font-semibold ">
                  {" "}
                  <i className="fa-solid fa-arrow-trend-up text-[20px]"></i>{" "}
                  Analytics
                </a>
              </NavLink>

              <NavLink
                activeClassName="active"
                to="/connected-channels"
                className=" hover:bg-[#0180a1]/20 px-4 py-3  flex justify-center items-center cursor-pointer rounded-md transition-all hover:transition-all"
              >
                <a className=" text-[16px] font-semibold ">
                  {" "}
                  <i class="fa-solid fa-table-list text-[20px]"></i> Channels
                </a>
              </NavLink>
            </ul>
          </div> */}
        </>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
    </>
  );
};

export default TopBar;

import React, { Fragment, useContext, useEffect, useState } from "react";
import TopBar from "../SideMenu/TopBar";
import { uri } from "../../../https";
import axios from "axios";
import GlobalContext from "../../../context/GlobalContext";
import SubscribeModal from "./SubscribeModal";
import PromoCodeModal from "./PromoCodeModal";
import { ToastContainer } from "react-toastify";
import { Player } from "@lottiefiles/react-lottie-player";
import FooterLoggedIn from "../../Footer/FooterLoggedIn";

const Subscription = () => {
  const {
    setShowEventModal,
    daysSelected,
    dispatchCalEvent,
    selectedEvent,
    setShowShareLocation,
    showShareLocation,
    setShowSubscribeModal,
    showSubscribeModal,
    setShowPromoCodeModal,
    showPromoCodeModal,
  } = useContext(GlobalContext);
  const [monthlyPlan, setMonthlyPlan] = useState(true);
  const [yearlyPlan, setYearlyPlan] = useState(false);
  const [loading, setLoading] = useState(false);

  const [stripe_packages_monthly, setStripePackagesMonthly] = useState([]);
  const [stripe_packages_yearly, setStripePackagesYearly] = useState([]);
  const GetStripePackages = async () => {
    const url = `${uri}api/get_stripe_packages`;
    setLoading(true);
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        setStripePackagesMonthly(response?.data?.stripe_packages_monthly);
        setStripePackagesYearly(response?.data?.stripe_packages_yearly);
        setLoading(false);
      },
      (error) => {
        console.log(error);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    GetStripePackages();
  }, []);

  console.log("Stripe Packages", stripe_packages_monthly);

  const [subscription_data, setSubscriptionData] = useState("");
  const handleSubmit = (plan) => {
    setSubscriptionData(plan);
    setShowSubscribeModal(true);
  };

  const basic_plan = 50;
  const standard_plan = 100;
  const popular_plan = 200;
  const premium_plan = 300;

  const [subscribed_data, setSubscribedData] = useState([]);
  const GetSubscribedData = async () => {
    const url = `${uri}api/get_subscription_data`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));

    const token = tokendata?.token;

    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        if (response.data.status == 200) {
          setSubscribedData(response?.data.stripe_sub);
        }
        if (response.data.status == 400) {
          //  setShowSubscriptionModal(true);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useState(() => {
    GetSubscribedData();
  }, []);

  return (
    <>
      {loading ? (
        <>
          <div className=" h-screen w-full fixed left-0 bg-white top-[0px] flex justify-center items-center  backdrop-blur-sm">
            <div className=" flex flex-col justify-center items-center">
              <Player
                src="https://lottie.host/1eb4f4a4-706d-498d-bf89-192423f0fd49/DOmyAJcAln.json"
                className="player"
                loop
                autoplay
                className="h-[200px]"
              />
              <p className=" text-[25px] text-gray-800 font-semibold">
                Please wait...
              </p>
            </div>
          </div>
        </>
      ) : (
        <>
          <Fragment>
            <TopBar />
            {showSubscribeModal && (
              <SubscribeModal subscription_data={subscription_data} />
            )}
            {showPromoCodeModal && (
              <PromoCodeModal subscription_data={subscription_data} />
            )}
            <div className=" container mx-auto  px-[40px] md:px-[100px]  mb-5">
              <div className=" flex flex-col justify-center md:flex-row md:justify-between items-center">
                <div className=" mt-[30px]">
                  <h1 className=" text-[21px] md:text-[23px] text-gray-700 font-semibold">
                    SP Analyst Plans
                  </h1>
                </div>
                <div className=" mt-[30px]  flex  gap-5">
                  <button
                    onClick={() => {
                      setMonthlyPlan(true);
                      setYearlyPlan(false);
                    }}
                    className={
                      monthlyPlan
                        ? "w-[130px] py-3  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all hover:scale-90"
                        : "w-[130px] py-3  bg-white shadow-md text-gray-800 rounded-md font-semibold hover:bg-[#2491af8a] hover:text-white transition-all hover:transition-all hover:scale-90"
                    }
                  >
                    Monthly
                  </button>
                  <button
                    onClick={() => {
                      setYearlyPlan(true);
                      setMonthlyPlan(false);
                    }}
                    className={
                      yearlyPlan
                        ? "w-[130px] py-3  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all hover:scale-90"
                        : "w-[130px] py-3  bg-white shadow-md text-gray-800 rounded-md font-semibold hover:bg-[#2491af8a] hover:text-white transition-all hover:transition-all hover:scale-90"
                    }
                  >
                    Yearly
                  </button>
                </div>
              </div>
              {monthlyPlan && (
                <div className="grid grid-rows-1 md:grid-cols-4 place-items-center gap-5 mt-5">
                  {stripe_packages_monthly.map((packages, index) => {
                    return (
                      <div
                        className=" w-full  bg-white shadow-md hover:shadow-xl cursor-pointer transition-all hover:transition-all border-1 border-gray-300 rounded-md p-5"
                        key={index}
                      >
                        <div className=" flex justify-center items-center">
                          <h1 className=" text-[20px] font-semibold text-gray-700">
                            {packages.package_name}
                          </h1>
                        </div>
                        <div className=" flex justify-center items-center mt-2 mb-1">
                          <h1 className=" text-[18px] font-semibold text-gray-600 capitalize">
                            $ {packages.total_price}/{packages.tenure}
                          </h1>
                        </div>
                        <div>
                          <div className=" flex gap-3 mb-5 items-center  font-semibold text-gray-500 mt-3">
                            <span>*</span>
                            <span className="text-[15px]">
                              {packages.feature_1}{" "}
                            </span>
                          </div>
                          <div className=" flex gap-3 mb-5 items-center font-semibold text-gray-500 mt-3">
                            <span>*</span>
                            <span className="text-[15px]">
                              {packages.feature_2}
                            </span>
                          </div>
                          <div className=" flex gap-3 mb-5  items-center font-semibold text-gray-500 mt-3">
                            <span>*</span>
                            <span className="text-[15px]">
                              {packages.feature_3}
                            </span>
                          </div>
                          <div className=" flex gap-3 mb-5 items-center font-semibold text-gray-500 mt-3">
                            <span>*</span>
                            <span className="text-[15px]">
                              {packages.feature_4}
                            </span>
                          </div>
                          <div className=" flex gap-3 mb-5 items-center font-semibold text-gray-500 mt-3">
                            <span>*</span>
                            <span className="text-[15px]">
                              {packages.feature_5}
                            </span>
                          </div>
                          <div className=" flex  justify-center items-center">
                            {subscribed_data.find(
                              (x) => x.price_id === packages.stripe_price_id
                            ) ? (
                              <>
                                <button className="w-full py-3  bg-gradient-to-r from-[#01750b] via-[#5abc72] to-[#39e977] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all hover:scale-90">
                                  Subscribed
                                </button>
                              </>
                            ) : (
                              <>
                                {" "}
                                <button
                                  onClick={() => handleSubmit(packages)}
                                  className="w-full py-3  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all hover:scale-90"
                                >
                                  Subscribe
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {yearlyPlan && (
                <div className="grid grid-rows-1 md:grid-cols-4 place-items-center gap-5 mt-5">
                  {stripe_packages_yearly.map((packages, index) => {
                    return (
                      <div
                        className=" w-full  bg-white shadow-md hover:shadow-xl cursor-pointer transition-all hover:transition-all border-1 border-gray-300 rounded-md p-5"
                        key={index}
                      >
                        <div className=" flex justify-center items-center">
                          <h1 className=" text-[20px] font-semibold text-gray-700">
                            {packages.package_name}
                          </h1>
                        </div>
                        <div className=" flex justify-center items-center mt-2 mb-1">
                          <h1 className=" text-[18px] font-semibold text-gray-600 capitalize">
                            $ {packages.total_price}/{packages.tenure}
                          </h1>
                        </div>
                        <div>
                          <div className=" flex gap-3 mb-5 items-center  font-semibold text-gray-500 mt-3">
                            <span>*</span>
                            <span className="text-[15px]">
                              {packages.feature_1}{" "}
                            </span>
                          </div>
                          <div className=" flex gap-3 mb-5 items-center font-semibold text-gray-500 mt-3">
                            <span>*</span>
                            <span className="text-[15px]">
                              {packages.feature_2}
                            </span>
                          </div>
                          <div className=" flex gap-3 mb-5  items-center font-semibold text-gray-500 mt-3">
                            <span>*</span>
                            <span className="text-[15px]">
                              {packages.feature_3}
                            </span>
                          </div>
                          <div className=" flex gap-3 mb-5 items-center font-semibold text-gray-500 mt-3">
                            <span>*</span>
                            <span className="text-[15px]">
                              {packages.feature_4}
                            </span>
                          </div>
                          <div className=" flex gap-3 mb-5 items-center font-semibold text-gray-500 mt-3">
                            <span>*</span>
                            <span className="text-[15px]">
                              {packages.feature_5}
                            </span>
                          </div>
                          <div className=" flex  justify-center items-center">
                            <button
                              onClick={() => handleSubmit(packages)}
                              className="w-full py-3  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all hover:scale-90"
                            >
                              Subscribe
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
            {/* Same as */}
            <ToastContainer />
          </Fragment>
        </>
      )}
      <FooterLoggedIn />
    </>
  );
};

export default Subscription;

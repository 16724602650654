import React, { useReducer } from "react";
import GlobalContext from "./GlobalContext";
import { useState } from "react";
import dayjs from "dayjs";
import { useEffect } from "react";
import { uri } from "../https";
import axios from "axios";

export default function ContextWrapper(props) {
  const savedEventsReducer = (state, { type, payload }) => {
    switch (type) {
      case "push":
        return [...state, payload];

      case "update":
        return state.map((e) => (e.id === payload.id ? payload : e));
      case "delete":
        return state.filter((e) => e.id !== payload.id);
      default:
        throw new Error();
    }
  };
  const initEvents = () => {
    const storageEvents = localStorage.getItem("savedEvents");
    const parsedEvents = storageEvents ? JSON.parse(storageEvents) : [];
    return parsedEvents;
  };

  const [monthIndex, setmonthIndex] = useState(dayjs().month());
  const [smallCalendarMonth, setSmallCalendarMonth] = useState(null);
  const [showEventModal, setShowEventModal] = useState(false);
  const [showSelectedEventModal, setShowSelectedEventModal] = useState(false);
  const [showSelectedFacebookModal, setShowSelectedFacebookModal] =
    useState(false);
  const [showSelectedInstagramModal, setShowSelectedInstagramModal] =
    useState(false);
  const [showSelectedLinkedInModal, setShowSelectedLinkedInModal] =
    useState(false);
  const [showFacebookPageModal, setShowFacebookPageModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [daysSelected, setDaysSelected] = useState(dayjs());
  const [isBackwardDateSelected, setIsBackwardDateSelected] = useState(false);
  const [editcontentModal, setEditContentModal] = useState(false);
  const [editpublishedcontentModal, setEditPublishedContentModal] =
    useState(false);
  const [showSelectedPintrestModal, setShowSelectedPintrestModal] =
    useState(false);
  const [showSelectedTumblrModal, setShowSelectedTumblrModal] = useState(false);
  const [showSelectedPinterestBoardModal, setShowSelectedPinterestBoardModal] =
    useState(false);
  const [trigger_event, setTriggerEvent] = useState("");
  const [showShareLocation, setShowShareLocation] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const [showPromoCodeModal, setShowPromoCodeModal] = useState(false);
  const [connectChannelModal, setConectChannelModal] = useState(false);
    const [
      Channel_Interaction_ModalFacebook,
      setChannelInteractionModalFacebook,
    ] = useState(false);
    const [
      Channel_Interaction_ModalInstagram,
      setChannelInteractionModalInstagram,
    ] = useState(false);
    const [
      Channel_Interaction_ModalPinterest,
      setChannelInteractionModalPinterest,
    ] = useState(false);
    const [
      Channel_Interaction_ModalLinkedIn,
      setChannelInteractionModalLinkedin,
    ] = useState(false);
    const [Channel_Interaction_ModalTumblr, setChannelInteractionModalTumblr] =
      useState(false);

  const [savedEvents, dispatchCalEvent] = useReducer(
    savedEventsReducer,
    [],
    initEvents
  );

   const [loading, setLoading] = useState(false);

  const [users_posts, setUsersPosts] = useState([]);
  const GetUsersPosts = async () => {
    const url = `${uri}api/get_users_posts`;
    setLoading(true)
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        setUsersPosts(response?.data?.users_post);
          setLoading(false);
      },
      (error) => {
        console.log(error);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    GetUsersPosts();
  }, [savedEvents, trigger_event]);
  localStorage.getItem("savedEvents") == [] || null
    ? localStorage.setItem("savedEvents", JSON.stringify(users_posts))
    : localStorage.setItem("savedEvents", JSON.stringify(users_posts));

  // const data = JSON.stringify(users_posts);
  // console.log("UsersPost", data);

  console.log("Event saved", savedEvents);

  useEffect(() => {
    // localStorage.getItem("savedEvents") == [] || null
    //   ? localStorage.setItem("savedEvents", JSON.stringify(savedEvents))
    //   : localStorage.setItem("savedEvents", JSON.stringify(users_posts));
  }, [savedEvents]);

  useEffect(() => {
    if (smallCalendarMonth !== null) {
      setmonthIndex(smallCalendarMonth);
    }
  }, [smallCalendarMonth]);
  return (
    <GlobalContext.Provider
      value={{
        monthIndex,
        setmonthIndex,
        smallCalendarMonth,
        setSmallCalendarMonth,
        daysSelected,
        setDaysSelected,
        showEventModal,
        setShowEventModal,
        dispatchCalEvent,
        savedEvents,
        selectedEvent,
        setSelectedEvent,
        showSelectedEventModal,
        setShowSelectedEventModal,
        showSelectedFacebookModal,
        setShowSelectedFacebookModal,
        showSelectedInstagramModal,
        setShowSelectedInstagramModal,
        showFacebookPageModal,
        setShowFacebookPageModal,
        showSelectedLinkedInModal,
        setShowSelectedLinkedInModal,
        setIsBackwardDateSelected,
        isBackwardDateSelected,
        editcontentModal,
        setEditContentModal,
        showSelectedPintrestModal,
        setShowSelectedPintrestModal,
        setShowSelectedTumblrModal,
        showSelectedTumblrModal,
        setShowSelectedPinterestBoardModal,
        showSelectedPinterestBoardModal,
        setEditPublishedContentModal,
        editpublishedcontentModal,
        setTriggerEvent,
        trigger_event,
        setShowShareLocation,
        showShareLocation,
        setShowSubscriptionModal,
        showSubscriptionModal,
        setShowSubscribeModal,
        showSubscribeModal,
        setShowPromoCodeModal,
        showPromoCodeModal,
        setConectChannelModal,
        connectChannelModal,
        setLoading,
        loading,
        Channel_Interaction_ModalFacebook,
        setChannelInteractionModalFacebook,
        Channel_Interaction_ModalInstagram,
        setChannelInteractionModalInstagram,
        Channel_Interaction_ModalPinterest,
        setChannelInteractionModalPinterest,
        Channel_Interaction_ModalLinkedIn,
        setChannelInteractionModalLinkedin,
        Channel_Interaction_ModalTumblr,
        setChannelInteractionModalTumblr,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
}

import React, { Fragment, useContext, useEffect, useState } from "react";

import TopBar from "../SideMenu/TopBar";
import SideMenuAnalytics from "../SideMenu/SideMenuAnalytics";
import { app_version, uri, year } from "../../../https";
import axios from "axios";
import { Link, NavLink } from "react-router-dom";
import { Player } from "@lottiefiles/react-lottie-player";
import {
  Chart as ChartJs,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
} from "chart.js";
import { Line, Bar, Chart } from "react-chartjs-2";
import SideMenuMobile from "../SideMenu/SideMenuMobile";
import SubscriptionModal from "../../../SubscriptionModal";
import GlobalContext from "../../../context/GlobalContext";
import ChannelOverView from "./ChannelOverView";
import FooterLoggedIn from "../../Footer/FooterLoggedIn";

ChartJs.register(
  CategoryScale,
  LinearScale,
  LineElement,
  BarElement,
  PointElement
);

const Analytics = () => {
  const {
    setDaysSelected,
    setShowEventModal,
    savedEvents,
    setSelectedEvent,
    setShowSelectedEventModal,
    daysSelected,
    dispatchCalEvent,
    selectedEvent,
    showSelectedFacebookModal,
    setShowSelectedFacebookModal,
    showSelectedInstagramModal,
    setShowSelectedInstagramModal,
    showFacebookPageModal,
    setShowFacebookPageModal,
    showSelectedLinkedInModal,
    setShowSelectedLinkedInModal,
    showSelectedPintrestModal,
    setShowSelectedPintrestModal,
    setShowSelectedTumblrModal,
    showSelectedTumblrModal,
    setShowSubscriptionModal,
    showSubscriptionModal,
  } = useContext(GlobalContext);

  const [loading, setLoading] = useState(false);
  const [loading_data, setLoadingData] = useState(false);
  const [sidebar_controller, setSideBarController] = useState(false);
  const [mobile_sidebar_controller, setMobileSideBarController] =
    useState(false);

  const [metaPageDetails, setMetaPageDetails] = useState([]);
  const FetchMetaPages = async () => {
    const url = `${uri}api/get_meta_pagedetails`;
    setLoading(true);
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        setMetaPageDetails(response?.data?.meta_pagedetails);
        console.log("meta page details", response?.data?.meta_pagedetails);
        setLoading(false);
      },
      (error) => {
        console.log(error);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    FetchMetaPages();
  }, []);

  const [analytics_pagename, setAnalyticsPageName] = useState("");
  const [analytics_page_info, setAnalyticsPageInfo] = useState("");
  const [channel_overview, setChannelOverView] = useState([]);
  const [impressions_28, setImpressions_28] = useState("");
  const [update_analytics_message, setUpdateAnalyticsMessage] = useState("")

   const RefreshInstagramAnalytics = async () => {
     const url = `${uri}api/get_instagram_analytics`;
     let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
     setLoadingData(true);
     const token = tokendata?.token;

     let config = {
       headers: {
         Authorization: "Bearer " + token,
         "content-type": "application/vnd.api+json",
         accept: "application/vnd.api+json",
       },
     };
     await axios.get(url, config).then(
       (response) => {
         if (response.data.status == 200) {
           setLoadingData(false);
           setUpdateAnalyticsMessage(response.data.status)
         }
         if (response.data.status == 400) {
           setLoadingData(false);
           //  setShowSubscriptionModal(true);
         }
       },
       (error) => {
         console.log(error);
         setLoadingData(false);
       }
     );
   };

   const RefreshFacebookAnalytics = async () => {
     const url = `${uri}api/get_facebook_analytics`;
     let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
     setLoadingData(true);
     const token = tokendata?.token;

     let config = {
       headers: {
         Authorization: "Bearer " + token,
         "content-type": "application/vnd.api+json",
         accept: "application/vnd.api+json",
       },
     };
     await axios.get(url, config).then(
       (response) => {
         if (response.data.status == 200) {
           setLoadingData(false);
           setUpdateAnalyticsMessage(response.data.status);
         }
         if (response.data.status == 400) {
           setLoadingData(false);
           //  setShowSubscriptionModal(true);
         }
       },
       (error) => {
         console.log(error);
         setLoadingData(false);
       }
     );
   };

   const RefreshTumblrAnalytics = async () => {
     const url = `${uri}api/get_tumblr_analytics`;
     let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
     setLoadingData(true);
     const token = tokendata?.token;

     let config = {
       headers: {
         Authorization: "Bearer " + token,
         "content-type": "application/vnd.api+json",
         accept: "application/vnd.api+json",
       },
     };
     await axios.get(url, config).then(
       (response) => {
         if (response.data.status == 200) {
           setLoadingData(false);
           setUpdateAnalyticsMessage(response.data.status);
         }
         if (response.data.status == 400) {
           setLoadingData(false);
           //  setShowSubscriptionModal(true);
         }
       },
       (error) => {
         console.log(error);
         setLoadingData(false);
       }
     );
   };

   const RefreshPinterestAnalytics = async () => {
     const url = `${uri}api/get_pinterest_account_details`;
     let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
     setLoadingData(true);
     const token = tokendata?.token;

     let config = {
       headers: {
         Authorization: "Bearer " + token,
         "content-type": "application/vnd.api+json",
         accept: "application/vnd.api+json",
       },
     };
     await axios.get(url, config).then(
       (response) => {
         if (response.data.status == 200) {
           setLoadingData(false);
           setUpdateAnalyticsMessage(response.data.status);
         }
         if (response.data.status == 400) {
           setLoadingData(false);
           //  setShowSubscriptionModal(true);
         }
       },
       (error) => {
         console.log(error);
         setLoadingData(false);
       }
     );
   };

  const GetPageAnalyticsByPageId = async (pagedetails) => {
    setAnalyticsPageInfo(pagedetails);
    setAnalyticsPageName(pagedetails?.page_name);
    setLoadingData(true);
    console.log("Page details captured", pagedetails);
    const url = `${uri}api/get_facebook_channel_overview/${pagedetails.page_id}`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));

    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        setImpressions_28("");
        setChannelOverView(response?.data?.channel_overview);
        setLoadingData(false);

        console.log("channel_overview", response?.data?.channel_overview);
      },
      (error) => {
        console.log(error);
        setLoadingData(false);
      }
    );
  };

  console.log("Analytics page info", analytics_page_info.platform);

  //   const GetProfilePicture = () => {
  //     const url = `https://graph.facebook.com/v16.0/104975525718827/picture?access_token=EAANNGVck75sBAADf2EHUdVYMqfFVUER6a8nP2vGBeYB20ZAcblUZABMS9pNDKGcTI1QsRJMxkNlpLOdrepxaFP5nshgzEaGTDjW1brbz0ZCFxNNGYKiRvfmRWFBcMNB6N7HzIzBCG8qjxC59s8hiitzd7FpBLZAGRBfnRuW8OVVmckwH0pM5`;
  //     axios
  //       .get(url)
  //       .then((response) => console.log("Profile picture", response.data));
  //   };

  //   useEffect(() => {
  //     GetProfilePicture();
  //   }, [analytics_pagename]);

  var impressions28 = {
    labels: [
      "28 days Impressions",
      "Weekly Impressions",
      "28 days Users Engagement",
      "Weekly Users Engagement",
    ],
    datasets: [
      {
        label: [` ${channel_overview?.page_name} Matrics`],
        data: [
          channel_overview?.impressions_28,
          channel_overview?.impressions_weekly,
          channel_overview?.users_28,
          channel_overview?.users_weekly,
        ],
        backgroundColor: [
          "rgba(1, 94, 117, 1)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(1, 94, 117, 1)",
          "rgba(57, 198, 233, 1)",
          "rgba(57, 198, 233, 1)",
          "rgba(57, 198, 233, 1)",
          "rgba(57, 198, 233, 1)",
          "rgba(57, 198, 233, 1)",
        ],
        borderWidth: 2,
        borderRadius: 5,
        borderSkipped: false,
      },
    ],
  };

  var impressionsweekly = {
    labels: ["Impressions Weekly"],
    datasets: [
      {
        label: [`Impressions Weekly Matrics`],
        data: [channel_overview?.impressions_weekly],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 2,
        borderRadius: 5,
        borderSkipped: false,
      },
    ],
  };

  var users28 = {
    labels: ["Users 28 Days "],
    datasets: [
      {
        label: [`28 Days Matrics`],
        data: [channel_overview?.users_28],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 2,
        borderRadius: 5,
        borderSkipped: false,
      },
    ],
  };

  var usersweekly = {
    labels: ["Users Weekly "],
    datasets: [
      {
        label: [`Users Weekly Matrics`],
        data: [channel_overview?.users_weekly],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 2,
        borderRadius: 5,
        borderSkipped: false,
      },
    ],
  };

  var options = {
    maintainAspectRatio: false,
    scales: {},
    legend: {
      display: false,

      labels: {
        fontSize: 25,
      },
    },
  };

  const [is_subscription_active, setIsSubscriptionActive] = useState([]);
  const GetUserSubscription = async () => {
    const url = `${uri}api/check_subscription_is_active`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        if (response.data.status == 200) {
          setShowSubscriptionModal(false);
        }
        if (response.data.status == 400) {
          setShowSubscriptionModal(true);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    GetUserSubscription();
  }, []);

  const [pinterest_account_details, setPinterestAccountDetails] = useState([]);
  const GetPinterestAccountDetails = async () => {
    setLoadingData(true);
    const url = `${uri}api/get_pinterest_account_analytics_server`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        if (response.data.status == 200) {
          setLoadingData(false);
          setPinterestAccountDetails(response?.data?.pinterest_account_matrics);
        }
        if (response.data.status == 400) {
          setLoadingData(false);
        }
      },
      (error) => {
        console.log(error);
        setLoadingData(false);
      }
    );
  };

  useEffect(() => {
    GetPinterestAccountDetails();
  }, [update_analytics_message]);

  var pinterest_account_info = {
    labels: ["Boards", "Followers", "Monthly Views", "Pins", "Following"],
    datasets: [
      {
        label: [` ${channel_overview?.page_name} Matrics`],
        data: [
          pinterest_account_details?.board_count,
          pinterest_account_details?.followers_count,
          pinterest_account_details?.monthly_views,
          pinterest_account_details?.pin_count,
          pinterest_account_details?.following_count,
        ],
        backgroundColor: [
          "rgba(1, 94, 117, 1)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(1, 94, 117, 1)",
          "rgba(57, 198, 233, 1)",
          "rgba(57, 198, 233, 1)",
          "rgba(57, 198, 233, 1)",
          "rgba(57, 198, 233, 1)",
          "rgba(57, 198, 233, 1)",
        ],
        borderWidth: 2,
        borderRadius: 5,
        borderSkipped: false,
      },
    ],
  };

  const [pinterest_account_analytics, setPinterestAccountAnalytics] = useState(
    []
  );
  const GetPinterestAccountAnalytics = async () => {
    const url = `${uri}api/get_pinterest_account_analytics_server`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        if (response.data.status == 200) {
          setPinterestAccountAnalytics(
            response?.data?.pinterest_account_matrics
          );
        }
        if (response.data.status == 400) {
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    GetPinterestAccountAnalytics();
  }, [update_analytics_message]);

  var pinterest_analytics = {
    labels: [
      "Saves",
      "Engagements",
      "Impressions",
      "Pins Click",
      "Click Through",
    ],
    datasets: [
      {
        label: [` ${channel_overview?.page_name} Matrics`],
        data: [
          pinterest_account_details?.save,
          pinterest_account_details?.pin_engagement,
          pinterest_account_details?.pin_impression,
          pinterest_account_details?.pin_click,
          pinterest_account_details?.pin_click_through,

          // pinterest_account_analytics?.all?.summary_metrics.SAVE,
          // pinterest_account_analytics?.all?.summary_metrics.ENGAGEMENT,
          // pinterest_account_analytics?.all?.summary_metrics.IMPRESSION,
          // pinterest_account_analytics?.all?.summary_metrics.PIN_CLICK,
          // pinterest_account_analytics?.all?.summary_metrics.CLICKTHROUGH_RATE,
        ],
        backgroundColor: [
          "rgba(1, 94, 117, 1)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(1, 94, 117, 1)",
          "rgba(57, 198, 233, 1)",
          "rgba(57, 198, 233, 1)",
          "rgba(57, 198, 233, 1)",
          "rgba(57, 198, 233, 1)",
          "rgba(57, 198, 233, 1)",
        ],
        borderWidth: 2,
        borderRadius: 5,
        borderSkipped: false,
      },
    ],
  };

 

  return (
    <>
      {loading ? (
        <>
          <div className=" h-screen w-full fixed left-0 bg-white top-[0px] flex justify-center items-center  backdrop-blur-sm">
            <div className=" flex flex-col justify-center items-center">
              <Player
                src="https://lottie.host/1eb4f4a4-706d-498d-bf89-192423f0fd49/DOmyAJcAln.json"
                className="player"
                loop
                autoplay
                className="h-[200px]"
              />
              <p className=" text-[25px] text-gray-800 font-semibold">
                Please wait...
              </p>
            </div>
          </div>
        </>
      ) : (
        <>
          <Fragment>
            <TopBar />
            {showSubscriptionModal && <SubscriptionModal />}
            <div className=" flex justify-start items-start ">
              <div
                className={` ${
                  !sidebar_controller
                    ? "w-[400px] hidden md:block"
                    : "w-[400px] hidden md:block"
                }  bg-[#fcfcfc] h-screen border-1 border-r-gray-200`}
              >
                <div className=" p-5 h-screen ">
                  <ul className="">
                    {metaPageDetails?.map((metapages, index) => {
                      return (
                        <Fragment key={index}>
                          <ul
                            onClick={() => GetPageAnalyticsByPageId(metapages)}
                          >
                            <li className=" flex justify-start items-center gap-5 outline outline-1  outline-gray-200 hover:bg-[#0180a1]/10    transition-all hover:transition-all p-2 rounded-md mb-4 mt-3 cursor-pointer">
                              <div className=" h-[50px] w-[50px] rounded-full border-1 border-gray-300   flex justify-center items-center ">
                                <div
                                  className={
                                    (metapages?.platform === "facebook" &&
                                      " bg-[#3b5998] flex justify-center items-center text-white text-[18px] font-semibold h-[40px] w-[40px]  rounded-full cursor-pointer ") ||
                                    (metapages?.platform === "instagram" &&
                                      " bg-[#d62976]  flex justify-center items-center text-white text-[18px] font-semibold h-[40px] w-[40px]  rounded-full cursor-pointer") ||
                                    (metapages?.platform === "linkedin" &&
                                      " bg-[#0A66C2]  flex justify-center items-center text-white text-[18px] font-semibold h-[40px] w-[40px]  rounded-full cursor-pointer") ||
                                    (metapages.platform === "pinterest" &&
                                      " bg-[#E60023] flex justify-center items-center capitalize text-white text-[18px] font-semibold h-[45px] w-[45px]  rounded-full cursor-pointer ") ||
                                    (metapages.platform === "tumblr" &&
                                      " bg-[#34526f] flex justify-center items-center capitalize text-white text-[18px] font-semibold h-[45px] w-[45px]  rounded-full cursor-pointer ")
                                  }
                                >
                                  {metapages?.page_name?.slice(0, 1)}
                                  <span className=" absolute mt-[40px] ml-14">
                                    <i
                                      className={
                                        (metapages?.platform === "facebook" &&
                                          "  fa-brands fa-facebook text-[22px] text-[#3b5998] ") ||
                                        (metapages?.platform === "instagram" &&
                                          "   fa-brands fa-square-instagram  text-[22px] text-[#d62976]") ||
                                        (metapages?.platform === "linkedin" &&
                                          " fa-brands fa-linkedin text-[22px] text-[#0A66C2]  ") ||
                                        (metapages?.platform === "pinterest" &&
                                          " fa-brands fa-pinterest text-[22px] text-[#E60023]  ") ||
                                        (metapages?.platform === "tumblr" &&
                                          "fa-brands fa-square-tumblr text-[22px] text-[#34526f]  ")
                                      }
                                    ></i>
                                  </span>
                                </div>
                              </div>
                              <li
                                className={` text-[15px] font-semibold capitalize ${
                                  !sidebar_controller ? "block" : "hidden"
                                }`}
                              >
                                {metapages?.page_name}
                              </li>
                            </li>
                          </ul>
                        </Fragment>
                      );
                    })}
                  </ul>
                </div>
                {sidebar_controller ? (
                  <></>
                ) : (
                  <>
                    {" "}
                    {/* <div className=" flex flex-col justify-center  relative bottom-14 items-center">
                      <h1>
                        SP Analyst{" "}
                        <span className="text-[#015e75]">{app_version}</span>
                      </h1>
                      <h1 className=" text-[15px] mt-4">
                        <i class="fa-regular fa-copyright text-[#015e75]"></i>{" "}
                        SP Analyst {year} All Rights Reserved
                      </h1>
                    </div> */}
                  </>
                )}
              </div>
              <div>
                <div
                  className={`w-full ${
                    !mobile_sidebar_controller
                      ? " w-full overflow-auto scrollbar-hide "
                      : " w-[0px] "
                  } bg-[#fcfcfc] absolute h-[100px] border-1 border-r-gray-200 duration-300 block md:hidden`}
                >
                  <div
                    className={` flex flex-row items-center justify-between mobile_sidebar_scrollbar ${
                      !mobile_sidebar_controller ? " block" : " hidden "
                    } `}
                  >
                    {metaPageDetails?.map((metapages, index) => {
                      return (
                        <Fragment key={index}>
                          {metapages.status == "active" && (
                            <ul
                              onClick={() =>
                                GetPageAnalyticsByPageId(metapages)
                              }
                            >
                              <li className=" flex justify-start items-center gap-5 outline-gray-200 hover:bg-[#0180a1]/10    transition-all hover:transition-all p-2 rounded-md mb-4 mt-3 cursor-pointer">
                                <div className=" h-[50px] w-[50px] rounded-full border-1 border-gray-300   flex justify-center items-center ">
                                  <div
                                    className={
                                      (metapages?.platform === "facebook" &&
                                        "  flex justify-center items-center text-white uppercase text-[18px] font-semibold h-[40px] w-[40px]  rounded-full cursor-pointer ") ||
                                      (metapages?.platform === "instagram" &&
                                        "  flex justify-center items-center text-white uppercase text-[18px] font-semibold h-[40px] w-[40px]  rounded-full cursor-pointer") ||
                                      (metapages?.platform === "linkedin" &&
                                        " flex justify-center items-center text-white uppercase text-[18px] font-semibold h-[40px] w-[40px]  rounded-full cursor-pointer") ||
                                      (metapages?.platform === "pinterest" &&
                                        " flex justify-center items-center text-white uppercase text-[18px] font-semibold h-[40px] w-[40px]  rounded-full cursor-pointer") ||
                                      (metapages?.platform === "tumblr" &&
                                        "  flex justify-center items-center text-white uppercase text-[18px] font-semibold h-[40px] w-[40px]  rounded-full cursor-pointer")
                                    }
                                  >
                                    <p>
                                      {metapages?.platform == "facebook" ? (
                                        <>
                                          <i className=" fa-brands fa-facebook text-[25px] text-[#3b5998]  "></i>
                                        </>
                                      ) : metapages?.platform == "instagram" ? (
                                        <>
                                          <i className=" fa-brands fa-square-instagram  text-[25px] text-[#d62976] "></i>
                                        </>
                                      ) : metapages?.platform == "linkedin" ? (
                                        <>
                                          <i className="  fa-brands fa-linkedin text-[25px] text-[#0A66C2] "></i>
                                        </>
                                      ) : metapages?.platform == "pinterest" ? (
                                        <>
                                          <i className="  fa-brands fa-pinterest text-[25px] text-[#E60023] "></i>
                                        </>
                                      ) : metapages?.platform == "tumblr" ? (
                                        <>
                                          <i className=" fa-brands fa-square-tumblr text-[25px] text-[#34526f] "></i>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </p>
                                  </div>
                                </div>
                                <li
                                  className={` text-[14px] font-semibold capitalize ${
                                    !mobile_sidebar_controller
                                      ? "block"
                                      : "hidden"
                                  }`}
                                >
                                  {metapages?.page_name}
                                </li>
                              </li>
                            </ul>
                          )}
                        </Fragment>
                      );
                    })}
                  </div>
                </div>
                {/* <div
                  onClick={() =>
                    setMobileSideBarController(!mobile_sidebar_controller)
                  }
                  className={` h-[30px] w-[30px]    relative     block md:hidden   ${
                    !mobile_sidebar_controller ? " " : ""
                  }   bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] border-1 border-white text-white rounded-full flex justify-center items-center cursor-pointer mt-[30px] duration-300`}
                >
                  <i
                    class={` ${
                      !mobile_sidebar_controller
                        ? "fa-solid fa-arrow-left"
                        : "fa-solid fa-arrow-left rotate-180 "
                    } duration-300 mobile_sidebar_handle overflow-hidden   `}
                  ></i>
                </div> */}
              </div>

              <div className=" w-full mt-[90px] md:mt-[0px] overflow-scroll">
                {analytics_pagename ? (
                  <>
                    {channel_overview !== null ||
                    channel_overview?.page_name !== undefined ? (
                      <div className=" w-full h-screen bg-gray-100/10 mb-3  p-4">
                        <div className=" flex justify-between items-center  mt-[10px] mb-[10px]">
                          <div>
                            <h1 className=" flex justify-center md:justify-start items-center text-[18px] md:text-[21px] font-semibold text-gray-700">
                              {analytics_pagename}
                            </h1>
                          </div>
                          <div>
                            {analytics_page_info.platform == "instagram" ? (
                              <>
                                {loading_data ? (
                                  <>
                                    <button className="w-full py-3 px-6  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all md:hover:scale-90">
                                      <i
                                        className={`fa-solid fa-arrows-rotate text-white  ${
                                          loading ? "rotate-90" : ""
                                        }`}
                                      ></i>{" "}
                                      Refreshing
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <button
                                      onClick={RefreshInstagramAnalytics}
                                      className="w-full py-3 px-6  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all md:hover:scale-90"
                                    >
                                      <i className="fa-solid fa-arrows-rotate text-white"></i>{" "}
                                      Refresh
                                    </button>
                                  </>
                                )}
                              </>
                            ) : analytics_page_info.platform == "facebook" ? (
                              <>
                                {loading_data ? (
                                  <>
                                    <button className="w-full py-3 px-6  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all md:hover:scale-90">
                                      <i
                                        className={`fa-solid fa-arrows-rotate text-white  ${
                                          loading ? "rotate-90" : ""
                                        }`}
                                      ></i>{" "}
                                      Refreshing
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <button
                                      onClick={RefreshFacebookAnalytics}
                                      className="w-full py-3 px-6  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all md:hover:scale-90"
                                    >
                                      <i className="fa-solid fa-arrows-rotate text-white"></i>{" "}
                                      Refresh
                                    </button>
                                  </>
                                )}
                              </>
                            ) : analytics_page_info.platform == "tumblr" ? (
                              <>
                                {loading_data ? (
                                  <>
                                    <button className="w-full py-3 px-6  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all md:hover:scale-90">
                                      <i
                                        className={`fa-solid fa-arrows-rotate text-white  ${
                                          loading ? "rotate-90" : ""
                                        }`}
                                      ></i>{" "}
                                      Refreshing
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <button
                                      onClick={RefreshTumblrAnalytics}
                                      className="w-full py-3 px-6  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all md:hover:scale-90"
                                    >
                                      <i className="fa-solid fa-arrows-rotate text-white"></i>{" "}
                                      Refresh
                                    </button>
                                  </>
                                )}
                              </>
                            ) : analytics_page_info.platform == "pinterest" ? (
                              <>
                                {loading_data ? (
                                  <>
                                    <button className="w-full py-3 px-6  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all md:hover:scale-90">
                                      <i
                                        className={`fa-solid fa-arrows-rotate text-white  ${
                                          loading ? "rotate-90" : ""
                                        }`}
                                      ></i>{" "}
                                      Refreshing
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <button
                                      onClick={RefreshPinterestAnalytics}
                                      className="w-full py-3 px-6  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all md:hover:scale-90"
                                    >
                                      <i className="fa-solid fa-arrows-rotate text-white"></i>{" "}
                                      Refresh
                                    </button>
                                  </>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        {analytics_page_info.platform == "facebook" ? (
                          <>
                            <div className=" w-full  bg-white rounded-md border-1  border-gray-200 shadow-md mb-4">
                              <div className=" m-3 p-3 flex justify-center md:justify-start items-center">
                                <h1 className=" text-gray-600 font-semibold">
                                  Channel Engagment
                                </h1>
                              </div>
                              <div className=" m-3 p-3">
                                {loading_data ? (
                                  <>
                                    <div className=" flex flex-col justify-center items-center">
                                      <Player
                                        src="https://lottie.host/1eb4f4a4-706d-498d-bf89-192423f0fd49/DOmyAJcAln.json"
                                        className="player"
                                        loop
                                        autoplay
                                        className="h-[100px]"
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <Line
                                      data={impressions28}
                                      options={options}
                                      height={200}
                                    />
                                  </>
                                )}

                                <div className="grid grid-cols-1 md:grid-cols-3 place-items-center">
                                  <div className=" hidden md:block">
                                    <div></div>
                                  </div>
                                  {/* <div className=" block  md:hidden">
                              <div>
                                <Line
                                  data={impressions28}
                                  options={options}
                                  height={200}
                                  width={250}
                                />
                              </div>
                            </div>
                            <div className=" hidden md:block">
                              <div>
                                <Line
                                  data={impressionsweekly}
                                  options={options}
                                  height={200}
                                  width={300}
                                />
                              </div>
                            </div>
                            <div className=" block  md:hidden">
                              <div>
                                <Line
                                  data={impressionsweekly}
                                  options={options}
                                  height={200}
                                  width={250}
                                />
                              </div>
                            </div>
                            <div className=" hidden md:block">
                              <div>
                                <Line
                                  data={users28}
                                  options={options}
                                  height={200}
                                  width={300}
                                />
                              </div>
                            </div>
                            <div className=" block  md:hidden">
                              <div>
                                <Line
                                  data={users28}
                                  options={options}
                                  height={200}
                                  width={250}
                                />
                              </div>
                            </div>
                            <div className=" hidden md:block">
                              <div>
                                <Line
                                  data={usersweekly}
                                  options={options}
                                  height={200}
                                  width={300}
                                />
                              </div>
                            </div>
                            <div className=" block  md:hidden">
                              <div>
                                <Line
                                  data={usersweekly}
                                  options={options}
                                  height={200}
                                  width={200}
                                />
                              </div>
                            </div> */}
                                  {/* <div>
                  <div>
                    <Line
                      data={users28}
                      options={options}
                      height={200}
                      width={400}
                    />
                  </div>
                </div> */}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : analytics_page_info.platform == "instagram" ? (
                          <>
                            <div className=" w-full  bg-white rounded-md border-1  border-gray-200 shadow-md mb-4">
                              <div className=" m-3 p-3">
                                <h1 className=" text-gray-600 font-semibold">
                                  Channel Engagment
                                </h1>
                              </div>
                              <div className=" m-3 p-3">
                                {loading_data ? (
                                  <>
                                    <div className=" flex flex-col justify-center items-center">
                                      <Player
                                        src="https://lottie.host/1eb4f4a4-706d-498d-bf89-192423f0fd49/DOmyAJcAln.json"
                                        className="player"
                                        loop
                                        autoplay
                                        className="h-[100px]"
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <Line
                                      data={impressions28}
                                      options={options}
                                      height={200}
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                          </>
                        ) : analytics_page_info.platform == "tumblr" ? (
                          <>
                            <div className=" w-full  bg-white rounded-md border-1  border-gray-200 shadow-md mb-4">
                              <div className=" m-3 p-3">
                                <h1 className=" text-gray-600 font-semibold">
                                  Channel Engagment
                                </h1>
                              </div>
                              <div className=" m-3 p-3">
                                {loading_data ? (
                                  <>
                                    <div className=" flex flex-col justify-center items-center">
                                      <Player
                                        src="https://lottie.host/1eb4f4a4-706d-498d-bf89-192423f0fd49/DOmyAJcAln.json"
                                        className="player"
                                        loop
                                        autoplay
                                        className="h-[100px]"
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <Line
                                      data={impressions28}
                                      options={options}
                                      height={200}
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                          </>
                        ) : analytics_page_info.platform == "pinterest" ? (
                          <>
                            <div className=" w-full  bg-white rounded-md border-1  border-gray-200 shadow-md mb-4">
                              <div className=" m-3 p-3">
                                <h1 className=" text-gray-600 font-semibold">
                                  Channel Engagment
                                </h1>
                              </div>
                              <div className=" m-3 p-3">
                                {loading_data ? (
                                  <>
                                    <div className=" flex flex-col justify-center items-center">
                                      <Player
                                        src="https://lottie.host/1eb4f4a4-706d-498d-bf89-192423f0fd49/DOmyAJcAln.json"
                                        className="player"
                                        loop
                                        autoplay
                                        className="h-[100px]"
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <Line
                                      data={pinterest_account_info}
                                      options={options}
                                      height={200}
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}

                        <div className=" w-full    bg-white rounded-md border-1  border-gray-200  mb-4">
                          <div className=" m-3 p-3">
                            <h1 className=" text-gray-600 font-semibold flex justify-center md:justify-start items-center">
                              Channel Overview
                            </h1>
                          </div>
                          <div className="  m-3 p-3 h-[300px]">
                            {loading_data ? (
                              <>
                                <div className=" flex flex-col justify-center items-center">
                                  <Player
                                    src="https://lottie.host/1eb4f4a4-706d-498d-bf89-192423f0fd49/DOmyAJcAln.json"
                                    className="player"
                                    loop
                                    autoplay
                                    className="h-[100px]"
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                                {analytics_page_info.platform == "pinterest" ? (
                                  <>
                                    <Line
                                      data={pinterest_analytics}
                                      options={options}
                                      height={200}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <ChannelOverView
                                      dataone={channel_overview?.page_name}
                                      datatwo={
                                        channel_overview?.followers_count
                                      }
                                      datathree={channel_overview?.total_likes}
                                      datafour={
                                        channel_overview?.impressions_28
                                      }
                                    />
                                  </>
                                )}
                              </>
                            )}
                          </div>

                          {/* <div className=" m-3 p-3">
                      <div className=" flex flex-col md:flex-row justify-center  md:justify-between items-center gap-[30px] md:gap-[0px]">
                        <div className=" flex flex-row gap-3 items-center ">
                          <div>
                            <div className=" flex justify-center items-center text-white text-[18px] md:text-[22px] font-semibold h-[40px] w-[40px] md:h-[50px] md:w-[50px] bg-[#0180a1] rounded-full cursor-pointer ">
                              {channel_overview?.page_name?.slice(0, 1)}
                            </div>
                          </div>
                          <div>
                            <h1 className=" text-[16px] md:text-[18px] text-gray-700 font-semibold">
                              Channel Name
                            </h1>
                            <h1 className=" text-[16px] md:text-[17px] font-medium text-gray-500">
                              {" "}
                              {channel_overview?.page_name
                                ? channel_overview?.page_name
                                : "nill"}
                            </h1>
                          </div>
                        </div>
                        <div className=" flex flex-col justify-center items-center">
                          <h1 className=" text-[16px] md:text-[18px] text-gray-700 font-semibold">
                            Followers
                          </h1>
                          <h1 className=" text-[17px] text-gray-500 font-medium">
                            {channel_overview?.followers_count
                              ? channel_overview?.followers_count
                              : "nill"}
                          </h1>
                        </div>
                        <div className=" flex flex-col justify-center items-center">
                          <h1 className=" text-[16px] md:text-[18px] text-gray-700 font-semibold">
                            Likes
                          </h1>
                          <h1 className=" text-[16px] md:text-[17px] text-gray-500 font-medium">
                            {channel_overview?.total_likes
                              ? channel_overview?.total_likes
                              : "nill"}
                          </h1>
                        </div>
                        <div className=" flex flex-col justify-center items-center">
                          <h1 className=" text-[16px] md:text-[18px] text-gray-700 font-semibold">
                            Engagment (28 days)
                          </h1>
                          <h1 className=" text-[16px] md:text-[17px] text-gray-500 font-medium">
                            {channel_overview?.impressions_28
                              ? channel_overview?.impressions_28
                              : "nill"}
                          </h1>
                        </div>
                      </div>
                    </div> */}
                        </div>
                      </div>
                    ) : (
                      <div className=" h-screen flex items-center justify-center">
                        <div className=" flex  justify-center items-center  w-[600px]  h-screen">
                          <div>
                            <Player
                              src="https://lottie.host/f203792f-02bd-4d25-ba18-167f0f82f5c5/xVxXiELBDq.json"
                              className="player"
                              loop
                              autoplay
                              className="h-[300px]"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <div className=" h-screen flex items-center justify-center">
                    <div className=" flex  justify-center items-center  w-[600px] md:w-[1100px] h-screen">
                      <div>
                        <Player
                          src="https://lottie.host/f203792f-02bd-4d25-ba18-167f0f82f5c5/xVxXiELBDq.json"
                          className="player"
                          loop
                          autoplay
                          className="h-[300px]"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Fragment>
        </>
      )}
      <div className=" mt-[20px]">
        <FooterLoggedIn />
      </div>
    </>
  );
};

export default Analytics;

import React, { Fragment, useEffect, useState } from "react";
import ProfileDashboard from "./ProfileDashboard";
import TopBar from "../SideMenu/TopBar";
import { Link } from "react-router-dom";
import SideMenuCommon from "../SideMenu/SideMenuCommon";
import axios from "axios";
import { uri } from "../../../https";
import { ToastContainer, toast } from "react-toastify";
import { Player } from "@lottiefiles/react-lottie-player";
import FooterLoggedIn from "../../Footer/FooterLoggedIn";

const ConnectedChannels = () => {
  const [sidebar_controller, setSideBarController] = useState(false);
  const [loading, setLoading] = useState(false);
  const [channelsList, setChannelsList] = useState([]);
  const GetConnectedChannelsList = async () => {
    const url = `${uri}api/get_connected_pages_list`;
    setLoading(true);
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        setChannelsList(response?.data?.connected_pages_list);
        setLoading(false);
      },
      (error) => {
        console.log(error);
        setLoading(false);
      }
    );
  };

  const [tempChannelHandler, setTempChannelHandler] = useState("");
  const DisconnectChannelHandler = (channels) => {
    console.log("Disconnect channel called", channels);
    setTempChannelHandler(channels.page_id);
  };

  const [disconnectModal, setDisconnectModal] = useState(false);

  const [disconnectMessage, setDisconnectMessage] = useState("");
  const DisconnectSocialPlatforms = async (channels) => {
    console.log("Disconnect platform called", channels);
    const url = `${uri}api/disconnect_social_platform`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;

    const data = {
      platform: channels.platform,
    };
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    axios.post(url, data, config).then(
      (response) => {
        console.log(response.data);
        if (response.data.status === 200) {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setDisconnectMessage(response.data.message);
        }
      },
      (error) => {
        console.log(error);
        toast.error("Something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    );
  };

  const ReconnectSocialPlatforms = async (channels) => {
    console.log("Disconnect platform called", channels);
    const url = `${uri}api/reconnect_social_platform`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;

    const data = {
      platform: channels.platform,
    };
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    axios.post(url, data, config).then(
      (response) => {
        console.log(response.data);
        if (response.data.status === 200) {
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setDisconnectMessage(response.data.message);
        }
      },
      (error) => {
        console.log(error);
        toast.error("Something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    );
  };

  useEffect(() => {
    GetConnectedChannelsList();
  }, [disconnectMessage]);

  return (
    <>
      <>
        {loading ? (
          <>
            <div className=" h-screen w-full fixed left-0 bg-white top-[0px] flex justify-center items-center  backdrop-blur-sm">
              <div className=" flex flex-col justify-center items-center">
                <Player
                  src="https://lottie.host/1eb4f4a4-706d-498d-bf89-192423f0fd49/DOmyAJcAln.json"
                  className="player"
                  loop
                  autoplay
                  className="h-[200px]"
                />
                <p className=" text-[25px] text-gray-800 font-semibold">
                  Please wait...
                </p>
              </div>
            </div>
          </>
        ) : (
          <>
            <Fragment>
              <TopBar />
              <div className=" flex justify-start items-start ">
                <SideMenuCommon />

                <div className=" w-full h-screen bg-gray-100/10 px-[50px] md:px-[0px]">
                  <div className=" flex flex-col md:flex-row justify-center items-center md:justify-evenly md:gap-[150px]  md:items-start mt-[50px]">
                    <div>
                      <h1 className=" text-[21px] font-semibold text-gray-700">
                        Channels
                      </h1>
                    </div>
                    <Link to="/channels/connect">
                      <button className=" hidden md:block w-full px-3 py-2 bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md">
                        Connect channels
                      </button>
                    </Link>
                  </div>
                  <div className=" flex flex-col justify-center items-center  mt-[20px] md:mt-4">
                    {channelsList.map((channels, index) => {
                      return (
                        <div className=" w-full md:w-[700px] h-full md:h-[80px] border-2  border-gray-200  p-3 mb-5 flex flex-col md:flex-row justify-center md:justify-between items-center rounded-md px-[30px] md:px-[20px]">
                          <div className=" flex flex-col justify-center items-center md:flex-row md:items-start md:justify-start gap-4">
                            <div key={index}>
                              {/* fa-brands fa-square-tumblr text-[22px] text-[#34526f] */}
                              <div
                                className={
                                  (channels.platform === "facebook" &&
                                    " bg-[#3b5998] flex justify-center items-center capitalize text-white text-[18px] font-semibold h-[45px] w-[45px]  rounded-full cursor-pointer ") ||
                                  (channels.platform === "instagram" &&
                                    " bg-[#d62976] flex justify-center items-center capitalize text-white text-[18px] font-semibold h-[45px] w-[45px]  rounded-full cursor-pointer ") ||
                                  (channels.platform === "linkedin" &&
                                    " bg-[#0A66C2] flex justify-center items-center capitalize text-white text-[18px] font-semibold h-[45px] w-[45px]  rounded-full cursor-pointer ") ||
                                  (channels.platform === "pinterest" &&
                                    " bg-[#E60023] flex justify-center items-center capitalize text-white text-[18px] font-semibold h-[45px] w-[45px]  rounded-full cursor-pointer ") ||
                                  (channels.platform === "tumblr" &&
                                    " bg-[#34526f] flex justify-center items-center capitalize text-white text-[18px] font-semibold h-[45px] w-[45px]  rounded-full cursor-pointer ")
                                }
                              >
                                {channels.page_name.slice(0, 1)}
                              </div>
                              <span className=" absolute -mt-[10px] ml-[35px] mb-3 md:mb-0">
                                {(channels.platform === "facebook" && (
                                  <i className="fa-brands fa-facebook text-[#3b5998] text-[22px]"></i>
                                )) ||
                                  (channels.platform === "instagram" && (
                                    <i className="fa-brands fa-square-instagram text-[#d62976] text-[22px]"></i>
                                  )) ||
                                  (channels.platform === "linkedin" && (
                                    <i className="fa-brands fa-linkedin text-[#0A66C2] text-[22px]"></i>
                                  )) ||
                                  (channels.platform === "pinterest" && (
                                    <i className=" fa-brands fa-pinterest text-[22px] text-[#E60023] "></i>
                                  )) ||
                                  (channels.platform === "tumblr" && (
                                    <i className=" fa-brands fa-square-tumblr text-[22px] text-[#34526f] "></i>
                                  ))}
                              </span>
                            </div>
                            <div className=" flex flex-col  justify-center md:justify-start items-center md:items-start ">
                              <h1 className=" text-[15px] font-semibold text-gray-700 capitalize mb-3 md:mb-0">
                                {channels.page_name}
                              </h1>
                              <span className="text-[14px] text-gray-500 capitalize mb-3 md:mb-0">
                                {channels.platform} profile
                              </span>
                            </div>
                          </div>
                          <div
                            className=" flex items-center justify-start gap-5 mr-2 cursor-pointer"
                            onClick={() => {
                              DisconnectChannelHandler(channels);
                              setDisconnectModal(!disconnectModal);
                            }}
                          >
                            <div>
                              {channels.status == "active" ? (
                                <i className="fa-solid fa-circle-check text-[20px] text-green-500 cursor-not-allowed"></i>
                              ) : (
                                <i className="fa-regular fa-circle-xmark text-[20px] text-red-500 cursor-not-allowed"></i>
                              )}
                            </div>
                            <div className=" hidden md:block">
                              <i className="fa-solid fa-ellipsis-vertical text-[20px]  cursor-pointer"></i>
                            </div>
                            <div className=" block md:hidden absolute right-[70px] -mt-[230px]">
                              <i class="fa-solid fa-ellipsis text-[20px]  cursor-pointer"></i>
                            </div>
                            {disconnectModal && (
                              <>
                                {channels.page_id === tempChannelHandler && (
                                  <div className=" flex justify-center items-center p-3 w-[150px] h-[40px] bg-white shadow-md absolute  -mt-[140px] md:mt-[40px] ml-[50px] ">
                                    <div>
                                      {channels.status == "active" ? (
                                        <button
                                          onClick={() =>
                                            DisconnectSocialPlatforms(channels)
                                          }
                                          className=" text-red-500 font-semibold p-3 m-3 "
                                        >
                                          Disconnect
                                        </button>
                                      ) : (
                                        <button
                                          onClick={() =>
                                            ReconnectSocialPlatforms(channels)
                                          }
                                          className=" text-green-500 font-semibold p-3 m-3 "
                                        >
                                          Connect
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
              {/* Same as */}
              <ToastContainer />
            </Fragment>
          </>
        )}
      </>
      <div className=" mt-[180px] md:mt-[0px]">
        <FooterLoggedIn />
      </div>
    </>
  );
};

export default ConnectedChannels;

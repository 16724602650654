import React, { useContext, useEffect, useState } from "react";
import GlobalContext from "../../../context/GlobalContext";
import { useSearchParams } from "react-router-dom";
import { ConnectToFacebook } from "../../../https";
const FacebookModal = ({  }) => {
  const {
    setDaysSelected,
    setShowEventModal,
    savedEvents,
    setSelectedEvent,
    setShowSelectedEventModal,
    daysSelected,
    dispatchCalEvent,
    selectedEvent,
    setShowSelectedFacebookModal,
    Channel_Interaction_ModalFacebook,
    setChannelInteractionModalFacebook,
    Channel_Interaction_ModalInstagram,
    setChannelInteractionModalInstagram,
    Channel_Interaction_ModalPinterest,
    setChannelInteractionModalPinterest,
    Channel_Interaction_ModalLinkedIn,
    setChannelInteractionModalLinkedin,
    Channel_Interaction_ModalTumblr,
    setChannelInteractionModalTumblr,
  } = useContext(GlobalContext);

  const [result, setResult] = useState("");

  const closeWindow = () => {
    result.close();
    setResult(null);
  };

  return (
    <div className=" h-full w-full bg-black/25  absolute left-0   flex justify-center items-center access_modal">
      <div className="w-[500px] shadow-lg bg-white  relative -top-[120px] rounded-md  access_modal">
        <header className="bg-white px-4 py-2 flex justify-between items-center rounded-md">
          <h1 className=" text-gray-700 font-semibold">
            Select Facebook account type
          </h1>
          <div className=" gap-3">
            <button
              onClick={() => {
                setShowSelectedFacebookModal(false);
               
              }}
            >
              <span className="material-icons-outlined text-gray-400">
                close
              </span>
            </button>
          </div>
        </header>
        <div className=" w-full bg-[#f5f5f5] h-full ">
          <div className=" flex justify-start flex-col items-start gap-3 p-5">
            <h1 className=" text-gray-700 font-semibold text-[15px]">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry
            </h1>
            <p className=" text-gray-600 text-[14px]">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s,
            </p>
          </div>
          <div>
            <p className=" text-gray-700 text-[15px] ml-3 p-2">
              Check out our{" "}
              <span className=" cursor-pointer text-[#015e75] font-semibold">
                Facebook connection guide
              </span>{" "}
              for more.
            </p>
          </div>
        </div>
        <footer className=" flex justify-end  border-t  p-3 gap-3">
          <button
            onClick={() => setShowSelectedFacebookModal(false)}
            className="  px-3 py-2 rounded text-gray-600 font-semibold hover:text-gray-800 transition-all hover:transition-all"
          >
            Cancel
          </button>
          <button
            onClick={ConnectToFacebook}
            className="  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] hover:scale-90 transition-all hover:transition-all px-3 py-2 rounded text-white"
          >
            Start Connecting
          </button>
        </footer>{" "}
      </div>
    </div>
  );
};

export default FacebookModal;

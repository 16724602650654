import React, { useState } from "react";
import Navbar from "../../Navbar/Navbar";
import Footer from "../../Footer/Footer";

const Guides = () => {
  const [selected_option, setSelectedOption] = useState("");

  console.log("Selected option", selected_option);
  return (
    <>
      <Navbar />
      <div className=" flex justify-start items-start">
        <div className=" w-[350px]  h-screen shadow-md hidden md:block">
          <ul className=" p-3 m-3">
            <li className=" mb-[20px]">
              <a
                href="#intro"
                className=" text-[20px] font-semibold cursor-pointer text-gray-700"
              >
                Introduction
              </a>
            </li>
            <li className=" mb-[20px]">
              <a
                href="#faceebookguide"
                className=" text-[14px] font-semibold cursor-pointer text-gray-700"
              >
                Facebook Connection Guide
              </a>
            </li>
            <li className=" mb-[20px]">
              <a
                href="#faceebookguide"
                className=" text-[14px] font-semibold cursor-pointer text-gray-700"
              >
                Instagram Connection Guide
              </a>
            </li>
            <li className=" mb-[20px]">
              <a
                href="#faceebookguide"
                className=" text-[14px] font-semibold cursor-pointer text-gray-700"
              >
                Linkedin Connection Guide
              </a>
            </li>
            <li className=" mb-[20px]">
              <a
                href="#faceebookguide"
                className="text-[14px] font-semibold cursor-pointer text-gray-700"
              >
                Pinterest Connection Guide
              </a>
            </li>
            <li className=" mb-[20px]">
              <a
                href="#faceebookguide"
                className=" text-[14px] font-semibold cursor-pointer text-gray-700"
              >
                Tumblr Connection Guide
              </a>
            </li>
            <li className=" mb-[20px]">
              <a
                href="#intro"
                className=" text-[20px] font-semibold cursor-pointer text-gray-700"
              >
                App Features
              </a>
            </li>
            <li className=" mb-[20px]">
              <a
                href="#faceebookguide"
                className=" text-[14px] font-semibold cursor-pointer text-gray-700"
              >
                Facebook Features
              </a>
            </li>
            <li className=" mb-[20px]">
              <a
                href="#faceebookguide"
                className=" text-[14px] font-semibold cursor-pointer text-gray-700"
              >
                Instagram Features
              </a>
            </li>
            <li className=" mb-[20px]">
              <a
                href="#faceebookguide"
                className=" text-[14px] font-semibold cursor-pointer text-gray-700"
              >
                Linkedin Features
              </a>
            </li>
            <li className=" mb-[20px]">
              <a
                href="#faceebookguide"
                className=" text-[14px] font-semibold cursor-pointer text-gray-700"
              >
                Pinterest Features
              </a>
            </li>
            <li className=" mb-[20px]">
              <a
                href="#faceebookguide"
                className=" text-[14px] font-semibold cursor-pointer text-gray-700"
              >
                Tumblr Features
              </a>
            </li>
          </ul>
        </div>
        <div className=" w-full  h-full px-[10px] md:px-[0px]">
          <div className=" w-full h-[400px] bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white flex justify-center items-center ">
            <div className=" flex flex-col justify-center items-center">
              <h1 className=" text-[40px] md:text-[70px] font-semibold">
                Guides
              </h1>
              {/* <h1 className=" text-[20px] md:text-[30px] font-semibold">
                V1.0 Internal Beta
              </h1> */}
              <div className=" flex flex-row  gap-[50px] mt-[10px]">
                <div>
                  <i className="fa-brands fa-facebook text-[25px] cursor-pointer"></i>
                </div>
                <div>
                  <i class="fa-brands fa-instagram text-[25px] cursor-pointer"></i>
                </div>
                <div>
                  <i class="fa-brands fa-x-twitter text-[25px] cursor-pointer"></i>
                </div>
                <div>
                  <i class="fa-brands fa-pinterest text-[25px] cursor-pointer"></i>
                </div>
                <div>
                  <i class="fa-brands fa-square-tumblr text-[25px] cursor-pointer"></i>
                </div>
              </div>
            </div>
          </div>
          <div className=" mt-[40px] p-[0px] md:px-[10px]">
            <select
              className="disabled:bg-gray-300 block md:hidden  mt-2  py-3 outline-none bg-[#F0F0F0] rounded-md px-9 ring-1  ring-[#5aa7bc] focus:ring-[#015e75]"
              onChange={(e) => setSelectedOption(e.target.value)}
            >
              <option selected="selected">Select</option>
              <option value="introduction">Introduction</option>
              <option value="facebook">Facebook Connection Guide</option>
            </select>
            <div className=" mb-[40px]" id="intro">
              <h1 className=" text-[22px] font-semibold text-gray-800">
                Introduction
              </h1>
              <p className=" text-gray-700 font-medium text-justify mb-3 mt-[10px]">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum
              </p>
              <p className=" text-gray-700 font-medium text-justify mb-3">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum
              </p>
              <p className=" text-gray-700 font-medium text-justify mb-3">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum
              </p>
            </div>
            <div className=" hidden md:block mb-[40px]" id="faceebookguide">
              <h1 className=" text-[22px] font-semibold text-gray-800">
                Facebook Connection Guide
              </h1>
              <p className=" text-gray-700 font-medium text-justify mb-3 mt-[10px]">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum
              </p>
              <p className=" text-gray-700 font-medium text-justify mb-3">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum
              </p>
              <p className=" text-gray-700 font-medium text-justify mb-3">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum
              </p>
            </div>
            {selected_option == "facebook" ? (
              <>
                <div className=" block md:hidden mb-[40px]" id="faceebookguide">
                  <h1 className=" text-[22px] font-semibold text-gray-800">
                    Facebook Connection Guide
                  </h1>
                  <p className=" text-gray-700 font-medium text-justify mb-3 mt-[10px]">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum
                  </p>
                  <p className=" text-gray-700 font-medium text-justify mb-3">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum
                  </p>
                  <p className=" text-gray-700 font-medium text-justify mb-3">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release of Letraset sheets containing Lorem Ipsum
                    passages, and more recently with desktop publishing software
                    like Aldus PageMaker including versions of Lorem Ipsum
                  </p>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Guides;

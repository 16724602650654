import { Player } from "@lottiefiles/react-lottie-player";
import React from "react";

const ConnectChannelModal = () => {
  return (
    <div className=" h-screen w-full fixed left-0 bg-black/50 top-[0px] flex justify-center items-center  backdrop-blur-sm">
      <div className=" flex flex-col justify-center items-center">
        <Player
          src="https://lottie.host/1eb4f4a4-706d-498d-bf89-192423f0fd49/DOmyAJcAln.json"
          className="player"
          loop
          autoplay
          className="h-[200px]"
        />
        <p className=" text-[25px] text-white font-semibold">Please wait...</p>
      </div>
    </div>
  );
};

export default ConnectChannelModal;

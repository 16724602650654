import React, { useEffect, useState, useContext } from "react";
// import {
//   Inject,
//   ScheduleComponent,
//   Day,
//   Week,
//   Month,
//   Agenda,
//   WorkWeek,
//   ViewDirective,
//   ViewsDirective,
// } from "@syncfusion/ej2-react-schedule";
// import { useRef } from "react";
// import { extend } from "@syncfusion/ej2-base";
// import { HubConnectionBuilder } from "@microsoft/signalr";
// import { WebApiAdaptor, DataManager, UrlAdaptor } from "@syncfusion/ej2/data";
// import { ButtonComponent } from "@syncfusion/ej2-react-buttons";

import { getMonth } from "../../../util";
import CalenderHeader from "../Calender/CalenderHeader";
import Sidebar from "../Calender/Sidebar";
import Month from "../Calender/Month";
import GlobalContext from "../../../context/GlobalContext";
import EventsModal from "../Events/EventsModal";
import SmallCalender from "../Calender/SmallCalender";
import EventSelectedModal from "../Events/EventSelectedModal";
import { Link, useParams } from "react-router-dom";
import SideMenu from "../SideMenu/SideMenu";
import TopBar from "../SideMenu/TopBar";
import dayjs from "dayjs";
import BackwardDateWarningModal from "../Events/BackwardDateWarningModal";
import { uri } from "../../../https";
import axios from "axios";
import SideMenuMobile from "../SideMenu/SideMenuMobile";
import ShareLocationModal from "../../../ShareLocationModal";
import { toast } from "react-toastify";
import SubscriptionModal from "../../../SubscriptionModal";
import SubscriptionEndModal from "../../../SubscriptionEndModal";
import Footer from "../../Footer/Footer";
import FooterLoggedIn from "../../Footer/FooterLoggedIn";
import ProfileInformationModal from "../../ProfileInformationModal/ProfileInformationModal";
import { Player } from "@lottiefiles/react-lottie-player";

const Dashboard = () => {
  console.table(getMonth());
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);

  const [showProfileInfoModal, setShowProfileInfoModal] = useState(true);

  const {
    setDaysSelected,
    setShowEventModal,
    savedEvents,
    setSelectedEvent,
    setShowSelectedEventModal,
    daysSelected,
    dispatchCalEvent,
    selectedEvent,
    setIsBackwardDateSelected,
    isBackwardDateSelected,
    showShareLocation,
    setShowShareLocation,
    setShowSubscriptionModal,
    showSubscriptionModal,
  } = useContext(GlobalContext);

  // const dataManager = new DataManager({
  //   url: "http://127.0.0.1:8000/api/test-get",
  //   crudUrl: "http://127.0.0.1:8000/api/test-post",
  //   adaptor: new UrlAdaptor(),
  //   crossDomain: true,
  // });
  // const minValidation = (args) => {
  //   return args["value"].length >= 5;
  // };
  // const fieldsData = {
  //   id: "Id",
  //   subject: { name: "Subject", validation: { required: true } },
  //   location: { name: "Location", validation: { required: true } },

  //   startTime: { name: "StartTime", validation: { required: true } },
  //   endTime: { name: "EndTime", validation: { required: true } },
  // };
  // const eventSettings = { dataSource: dataManager, fields: fieldsData };

  // console.log(dataManager);

  const [currentMonth, setCurrentMonth] = useState(getMonth());
  const { monthIndex, showEventModal, showSelectedEventModal } =
    useContext(GlobalContext);

  useEffect(() => {
    setCurrentMonth(getMonth(monthIndex));
  }, [monthIndex]);

  var today = new Date();

  const current_date = dayjs(today).format("DD-MM-YYYY");
  const selected_date = daysSelected.format("DD-MM-YYYY");

  console.log("Current date from dashboard", current_date);
  console.log("selected date from dashboard", selected_date);

  const [userProfileData, setUserProfileData] = useState([]);
  const GetUserProfile = async () => {
    const url = `${uri}api/me`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        setUserProfileData(response?.data.user_profile);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    GetUserProfile();
  }, []);

  console.log("UserProfile", userProfileData);

  const successCallback = (geolocation) => {
    // console.log(geolocation);
  };

  const errorCallback = (error) => {
    console.log(error);
    setShowShareLocation(false);
  };

  const optionsObject = (options) => {
    console.log(options);
  };

  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);

  const [stored_location, setStoredLocation] = useState([]);
  const GetUserStoredLocation = async () => {
    const url = `${uri}api/get_users_location`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        setStoredLocation(response?.data.users_location);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  console.log("Stored location", stored_location);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      // console.log("Geo location", position);
      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
      if (stored_location == null) {
        setShowShareLocation(true);
      } else {
        setShowShareLocation(false);
      }
    });
    // axios
    //   .get(
    //     `https://nominatim.openstreetmap.org/reverse?format=geojson&lat=${latitude}&lon=${longitude}`
    //   )
    //   .then((response) => {
    //     console.log("Location",response.data);
    //   });
  }, [userProfileData, stored_location]);

  const [user_country, setUserCountry] = useState("");
  const GetUserLocation = async () => {
    const url = `https://api.wheretheiss.at/v1/coordinates/${latitude},${longitude}`;

    await axios.get(url).then(
      (response) => {
        console.log("Location", response.data.timezone_id);
        setUserCountry(response.data.timezone_id);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    GetUserLocation();
    GetUserStoredLocation();
  }, [longitude, latitude]);

  console.log("Lat", latitude, "Lon", longitude);

  navigator.geolocation.watchPosition(
    successCallback,
    errorCallback,
    optionsObject
  );

  useEffect(() => {
    if (user_country !== undefined) {
      // handleSubmitLocation();
    }
  }, []);

  console.log("Timezone", user_country);

  const [is_subscription_active, setIsSubscriptionActive] = useState([]);
  const GetUserSubscription = async () => {
    const url = `${uri}api/check_subscription_is_active`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        if (response.data.status == 200) {
          setShowSubscriptionModal(false);
        }
        if (response.data.status == 400) {
          setShowSubscriptionModal(true);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    GetUserSubscription();
  }, []);

  const [userInfo, setUserInfo] = useState([]);
  const GetUserInformation = async () => {
    const url = `${uri}api/get_user_information`;
    setLoading(true);
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        console.log(response.data);
        setUserInfo(response.data.user_information);
        setLoading(false);
      },
      (error) => {
        console.log(error);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    GetUserInformation();
  }, []);

  const [subscription_status, setSubscriptionStatus] = useState([])
   const CheckSubscriptionData = async () => {
     const url = `${uri}api/check_subscription`;
     setLoading(true);
     let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
     const token = tokendata?.token;
     let config = {
       headers: {
         Authorization: "Bearer " + token,
         "content-type": "application/vnd.api+json",
         accept: "application/vnd.api+json",
       },
     };
     await axios.get(url, config).then(
       (response) => {
         console.log(response.data);
         setSubscriptionStatus(response.data);
         setLoading(false);
       },
       (error) => {
         console.log(error);
         setLoading(false);
       }
     );
   };

     useEffect(() => {
       CheckSubscriptionData();
     }, []);

     console.log("Subscription_status", subscription_status);

  return (
    <>
      <>
        <TopBar />
        {showShareLocation && (
          <ShareLocationModal user_country={user_country} />
        )}
        {showSubscriptionModal && <SubscriptionModal />}

        {loading ? (
          <>
            <div className=" h-screen w-full fixed left-0 bg-white top-[0px] flex justify-center items-center  backdrop-blur-sm access_modal">
              <div className=" flex flex-col justify-center items-center">
                <Player
                  src="https://lottie.host/1eb4f4a4-706d-498d-bf89-192423f0fd49/DOmyAJcAln.json"
                  className="player"
                  loop
                  autoplay
                  className="h-[200px]"
                />
                <p className=" text-[25px] text-gray-800 font-semibold">
                  Please wait...
                </p>
              </div>
            </div>
          </>
        ) : (
          <>
            {/* {userInfo?.client_id == null ? (
              <>{showProfileInfoModal && <ProfileInformationModal />}</>
            ) : (
              <></>
            )} */}
          </>
        )}

        <div className=" flex justify-start items-start ">
          <SideMenu />
          <SideMenuMobile />

          {showEventModal && (
            <EventsModal
              selected_date={selected_date}
              current_date={current_date}
            />
          )}
          {showSelectedEventModal && <EventSelectedModal />}

          <div className=" w-full h-screen mt-[100px] md:mt-[0px] overflow-scroll">
            <div className=" h-screen flex flex-col">
              <CalenderHeader stored_location={stored_location} />
              <div className=" flex flex-1">
                <Month month={currentMonth} />
              </div>
            </div>
          </div>
        </div>
      </>
      <FooterLoggedIn />
    </>
  );
};

export default Dashboard;

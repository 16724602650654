import React, { useEffect, useState } from "react";
import Navbar from "../../Navbar/Navbar";
import { Link } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";
import Footer from "../../Footer/Footer";
import Carousel, { CarouselItem } from "../Carousel/Carousel";

const Slide = ({ number }) => (
  <div>
    <img src={` ${number}`} />
  </div>
);

const SlideText = ({ number }) => (
  <div className=" w-[300px] h-full">
    <h1 className=" text-gray-800 text-[65px]">
      Introducing World's cheapest <br /> yet finest <br /> Social Media Manager
    </h1>
    <h1 className=" text-gray-800 text-[65px]">
      Hire the perfect influencer for your brand!!
    </h1>
    <h1 className=" text-gray-800 text-[65px]">
      Introducing World's cheapest <br /> yet finest <br /> Social Media Manager
    </h1>
  </div>
);

// let nameone = '' ;

const LandingPage = () => {
  const names = [
    `Introducing World's cheapest yet finest Social Media Manager`,
    "Hire the perfect influencer for your brand!!",
    "Super charge your social media growth today!!!",
  ];

  const images = [
    `https://res.cloudinary.com/dv5jjlsd7/image/upload/v1695636774/SP%20ANALYST/Soc_med_illust6_2_m4d0au.png`,
    "https://res.cloudinary.com/dv5jjlsd7/image/upload/v1695636764/SP%20ANALYST/Soc_med_illust4_ctl95y.png",
  ];

  const [currentName, setCurrentName] = useState(names[0]);
  const [currentImage, setCurrentImage] = useState(images[0]);

  function setRandomName() {
    const index = Math.floor(Math.random() * names.length);
    let newName = names[index];
    if (newName == currentName) {
      setRandomName();
    } else {
      setCurrentName(newName);
    }
    return;
  }

  useEffect(() => {
    setTimeout(() => {
      setRandomName();
    }, 3000);
  }, [currentName]);

  function setRandomImage() {
    const index = Math.floor(Math.random() * images.length);
    let newImage = images[index];
    if (newImage == currentImage) {
      setRandomImage();
    } else {
      setCurrentImage(newImage);
    }
    return;
  }

  useEffect(() => {
    setTimeout(() => {
      setRandomImage();
    }, 3000);
  }, [currentImage]);

  return (
    <>
      <Navbar />
      <div className=" container mx-auto px-[30px] md:px-[80px]">
        <div className=" flex flex-col md:flex-row justify-between items-start mt-[40px] ">
          <div className=" w-full flex flex-col justify-center md:justify-start ">
            <div className="text-center md:text-start hidden md:block text-gray-800">
              <h1 className="  text-center md:text-start md:text-[60px] font-semibold text-gray-800">
                {currentName}
              </h1>
            </div>
            <div className="text-center md:text-start block md:hidden">
              <h1 className="  text-center md:text-start text-[36px] font-semibold text-gray-800">
                {currentName}
              </h1>
            </div>
            <div className=" mt-[25px]">
              <p className="  md:text-start text-justify text-gray-800 text-[16px] font-medium">
                Are you struggling to keep up with the ever-changing landscape{" "}
                <br className=" hidden md:block" /> of social media? Do you find
                it challenging to consistently create{" "}
                <br className=" hidden md:block" /> engaging content, respond to
                messages, and analyze your <br className=" hidden md:block" />{" "}
                performance? If so, you've come to the right place!
              </p>
            </div>

            <div className=" flex justify-center items-center md:justify-start  mt-[30px]">
              <Link to="/signup">
                <button className=" w-[170px] py-3  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all md:hover:scale-90">
                  Get Started
                </button>
              </Link>
            </div>
          </div>
          <div className=" w-full hidden md:block ">
            <img src={currentImage} className=" h-[400px] object-contain" />
          </div>
        </div>

        <div className=" flex justify-center items-center mt-[180px]">
          <h1 className=" text-[22px] md:text-[38px] text-center font-bold text-gray-800">
            Here's what our SP Analyst Services offer
          </h1>
        </div>
        <div className=" flex flex-col justify-center items-center mt-[50px] md:mt-[100px]">
          <div className=" flex flex-col md:flex-row justify-center md:justify-start md:items-center gap-[50px] md:gap-[60px]">
            <div className=" w-full flex  md:items-start md:justify-start justify-center items-center ">
              <img
                src="https://res.cloudinary.com/dv5jjlsd7/image/upload/v1695192644/SP%20ANALYST/Screenshot_2023-09-20_121956_cw9olp.png"
                className=" h-[200px] md:h-[400px] object-contain"
              />
            </div>
            <div className=" order-first md:order-none w-full flex flex-col justify-center items-center md:justify-start md:items-start ">
              <h1 className=" text-[18px] md:text-[31px] text-center font-semibold text-gray-800">
                Comprehensive Social Media Strategy
              </h1>
              <p className=" mt-[15px] text-justify font-medium ">
                We'll work closely with you to develop a tailored social media
                strategy that aligns with your brand's objectives and target
                audience. Our data-driven approach ensures that every action we
                take is aimed at maximizing your online presence and driving
                results.
              </p>
              <div className=" mt-[30px]">
                <Link to="/signup">
                  <button className=" w-[170px] py-3  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all md:hover:scale-90">
                    Get Started
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className=" flex flex-col md:flex-row justify-start items-center gap-[50px] md:gap-[60px] mt-[50px] md:mt-[100px] ">
            <div className=" w-full flex flex-col justify-center items-center md:justify-start md:items-start ">
              <h1 className=" text-[18px] md:text-[31px] text-center font-semibold text-gray-800">
                Regular Posting and Scheduling
              </h1>
              <p className=" mt-[15px]  text-justify  font-medium">
                Consistency is key in the world of social media. We'll handle
                all aspects of posting and scheduling, ensuring your content
                reaches your followers at the optimal times for maximum impact.
              </p>
              <div className=" mt-[30px]">
                <Link to="/signup">
                  <button className=" w-[170px] py-3  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all md:hover:scale-90">
                    Get Started
                  </button>
                </Link>
              </div>
            </div>
            <div className=" w-full  flex md:items-start md:justify-end justify-center items-center  ">
              <img
                src="https://res.cloudinary.com/dv5jjlsd7/image/upload/v1695636441/SP%20ANALYST/Screenshot_2023-09-25_153706_sgyzcw.png"
                className="h-[200px] md:h-[400px] object-contain shadow-xl"
              />
            </div>
          </div>
          <div className=" flex flex-col md:flex-row justify-start items-center gap-[50px] md:gap-[60px] mt-[50px] md:mt-[100px]">
            <div className=" w-full flex md:items-start md:justify-start justify-center items-center ">
              <img
                src="https://res.cloudinary.com/dv5jjlsd7/image/upload/v1695636001/SP%20ANALYST/Screenshot_2023-09-25_152926_o2r5xs.png"
                className="h-[200px] md:h-[400px] object-contain shadow-xl"
              />
            </div>
            <div className=" order-first md:order-none w-full  flex flex-col justify-center items-center md:justify-start md:items-start">
              <h1 className=" text-[18px] md:text-[31px] text-center font-semibold text-gray-800">
                Community Management
              </h1>
              <p className=" mt-[15px] text-justify font-medium ">
                Engaging with your audience is crucial for building strong
                relationships. We'll monitor comments, messages, and mentions,
                responding promptly to ensure your customers feel valued and
                heard.
              </p>
              <div className=" mt-[30px]">
                <Link to="/signup">
                  <button className=" w-[170px] py-3  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all md:hover:scale-90">
                    Get Started
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className=" flex flex-col md:flex-row justify-start items-center gap-[50px] md:gap-[60px] mt-[50px] md:mt-[100px]">
            <div className=" w-full flex flex-col justify-center items-center md:justify-start md:items-start">
              <h1 className=" text-[18px] md:text-[31px]  text-center font-semibold text-gray-800">
                Performance Tracking & Analytics
              </h1>
              <p className=" mt-[15px]  text-justify font-medium ">
                We don't just post content and forget about it – our team
                closely monitors the performance of your social media channels.
                We'll provide you with detailed reports and insights, allowing
                you to see how your brand is growing and adapting online.
              </p>
              <div className=" mt-[30px]">
                <Link to="/signup">
                  <button className=" w-[170px] py-3  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all md:hover:scale-90">
                    Get Started
                  </button>
                </Link>
              </div>
            </div>
            <div className=" w-full  flex md:items-start md:justify-end justify-center items-center   ">
              <img
                src="https://res.cloudinary.com/dv5jjlsd7/image/upload/v1695192486/SP%20ANALYST/Screenshot_2023-09-20_121713_njv9po.png"
                className=" h-[200px] md:h-[400px] object-contain shadow-xl"
              />
            </div>
          </div>
        </div>
        <div className=" w-full   mt-[50px] rounded-md p-[10px] pb-[20px]  md:p-[50px]">
          <div className=" flex flex-col md:flex-row justify-between items-center px-[20px] md:px-[60px] md:gap-[50px]">
            <div className=" mt-[30px] flex flex-col justify-center items-center">
              <button className=" w-full py-3 px-5 bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all md:hover:scale-90">
                Creation & Curation
              </button>
              <div className=" mt-[50px]">
                <img
                  src="https://res.cloudinary.com/dv5jjlsd7/image/upload/v1695799966/SP%20ANALYST/35202510_light_bulb_1_wz5ulj.png"
                  className=" h-[80px] object-contain"
                />
              </div>
              <div className="  w-full rounded-md p-3 mt-[20px] ">
                <p className=" text-center font-medium text-gray-800">
                  Regularly analyzing the performance of the content through
                  social media analytics is essential to fine-tune the strategy
                  and optimize results.
                </p>
              </div>
            </div>

            <div className=" mt-[30px] flex flex-col justify-center items-center">
              <button className=" w-full py-3 px-5 bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all md:hover:scale-90">
                Schedules & Frequency
              </button>
              <div className=" mt-[50px]">
                <img
                  src="https://res.cloudinary.com/dv5jjlsd7/image/upload/v1695799966/SP%20ANALYST/calendar_591575_1_n4w8hx.png"
                  className=" h-[80px] object-contain"
                />
              </div>
              <div className="  w-full rounded-md p-3 mt-[20px] ">
                <p className=" text-center font-medium text-gray-800">
                  Regularly communicate with your clients to ensure the social
                  media strategy aligns with their overall marketing objectives.
                </p>
              </div>
            </div>

            <div className=" mt-[30px] flex flex-col justify-center items-center">
              <button className=" w-full py-3 px-5 bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md font-semibold hover:bg-[#2491af] transition-all hover:transition-all md:hover:scale-90">
                Time Zones
              </button>
              <div className=" mt-[50px]">
                <img
                  src="https://res.cloudinary.com/dv5jjlsd7/image/upload/v1695192942/SP%20ANALYST/hour_4119025_1_kcqeye.png"
                  className=" h-[80px] object-contain"
                />
              </div>
              <div className="  w-full rounded-md p-3 mt-[20px] ">
                <p className=" text-center font-medium text-gray-800">
                  Schedule posts strategically to reach audiences in different
                  regions without bombarding them during off-hours.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="   md:px-[100px]">
          <div className=" w-full  bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] mt-[50px] rounded-2xl p-[30px] md:p-[50px]">
            <div className=" flex flex-col  md:flex-row justify-between items-center">
              <div>
                <h1 className="  text-center md:text-start text-[20px] md:text-[30px] font-semibold text-white">
                  Get started with SP Analyst
                </h1>
              </div>
              <div>
                <Link to="/login">
                  <button className=" w-full py-3  mt-[20px] md:mt-[0px] px-8 bg-white text-gray-800 rounded-md font-semibold  transition-all hover:transition-all md:hover:scale-90">
                    Login
                  </button>
                </Link>
              </div>
            </div>
            <div>
              <p className=" text-white mt-[20px] text-center md:text-left ">
                Let us handle your social media presence while you focus on
                growing your business. Our team of social media experts is ready
                to take your brand to new heights in the digital world.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default LandingPage;

import React, { Fragment, useEffect, useState } from "react";
import TopBar from "../SideMenu/TopBar";
import SideMenuCommon from "../SideMenu/SideMenuCommon";
import { uri } from "../../../https";
import axios from "axios";
import { Link } from "react-router-dom";
import { Player } from "@lottiefiles/react-lottie-player";
import FooterLoggedIn from "../../Footer/FooterLoggedIn";
import { ToastContainer, toast } from "react-toastify";

const Billing = () => {
  const [loading, setLoading] = useState(false);
  const [data_loading, setDataLoading] = useState(false);
  const [subscribed_plan, setSubscribedPlan] = useState([]);
  const GetSubscribedPackageDetails = async () => {
    const url = `${uri}api/get_subscribed_plan`;
    setLoading(true);
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        setSubscribedPlan(response?.data?.subscribed_plan);
        setLoading(false);
      },
      (error) => {
        console.log(error);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    GetSubscribedPackageDetails();
  }, []);

  const [userProfileData, setUserProfileData] = useState([]);
  const GetUserProfile = async () => {
    const url = `${uri}api/me`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    await axios.get(url, config).then(
      (response) => {
        setUserProfileData(response?.data?.user_profile);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    GetUserProfile();
  }, []);

  const [customerPurchaseHistory, setCustomerPurchaseHistory] = useState([]);
  const FetchCustomerOrder = () => {
    const url = `${uri}api/get_user_purchases`;
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));

    const token = tokendata?.token;
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    axios.get(url, config).then(
      (response) => {
        setCustomerPurchaseHistory(response?.data?.customer_purchase_history);
        console.log("packages names", response.data?.customer_purchase_history);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    FetchCustomerOrder();
  }, []);

  const handleDownload = (item) => {
    console.log("Download captured", item);
    window.open(item.invoice_pdf, "_blank");
  };

  const ResendMail = (mail_data) => {
    console.log("Data to send as mail", mail_data);
    const url = `${uri}api/resend_mail`;
    setDataLoading(true);
    let tokendata = JSON.parse(window.localStorage.getItem("analyst_token"));
    const token = tokendata?.token;
    const data = {
      invoice_id: mail_data.invoiceid,
    };
    let config = {
      headers: {
        Authorization: "Bearer " + token,
        "content-type": "application/vnd.api+json",
        accept: "application/vnd.api+json",
      },
    };
    axios.post(url, data, config).then(
      (response) => {
        if (response.data.status === 200) {
          toast.success(response.data.message, {
            position: "top-right",
          });
          setDataLoading(false);
        } else if (response.data.status === 400) {
          console.log("WARNING", response.data.message);
          toast.error(response.data.message, {
            position: "top-right",
          });
          setDataLoading(false);
        }
      },
      (error) => {
        console.log(error.response.data);
      
        setDataLoading(false);
      }
    );
  };

  return (
    <>
      {loading ? (
        <>
          <div className=" h-screen w-full fixed left-0 bg-white top-[0px] flex justify-center items-center  backdrop-blur-sm">
            <div className=" flex flex-col justify-center items-center">
              <Player
                src="https://lottie.host/1eb4f4a4-706d-498d-bf89-192423f0fd49/DOmyAJcAln.json"
                className="player"
                loop
                autoplay
                className="h-[200px]"
              />
              <p className=" text-[25px] text-gray-800 font-semibold">
                Please wait...
              </p>
            </div>
          </div>
        </>
      ) : (
        <>
          <Fragment>
            <TopBar />
            <div className=" flex justify-start items-start ">
              <SideMenuCommon />

              <div className=" w-full h-screen bg-gray-100/10 px-[50px] md:px-[0px]">
                <div className=" flex flex-col md:flex-row justify-start items-start md:justify-evenly md:gap-[150px]  md:items-start mt-[50px]">
                  <div>
                    <h1 className=" text-[21px] font-semibold text-gray-700">
                      Billing
                    </h1>
                  </div>
                  <Link
                    to={`/dashboard/clientId=${userProfileData?.client_id}`}
                  >
                    <button className=" hidden md:block  w-full px-3 py-2 bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md">
                      Back To Dashboard
                    </button>
                  </Link>
                </div>
                <div className=" flex flex-col justify-center items-center  mt-4">
                  <div className=" w-full md:w-[700px] h-[80px] border-2 border-gray-200 p-3 mb-5 flex justify-between items-center rounded-md px-[30px] md:px-[20px]">
                    <div className=" flex flex-row justify-start gap-[40px]">
                      <div>
                        <h1 className="font-semibold text-[30px] text-gray-700 capitalize">
                          {subscribed_plan.total_price} /{" "}
                          {subscribed_plan.tenure}{" "}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
                <h1 className=" text-[21px] font-semibold text-gray-700 px-[50px] flex justify-center items-center">
                  Purchase History
                </h1>
                <div className=" h-screen overflow-scroll">
                  <div className="  flex justify-center items-center ">
                    {customerPurchaseHistory.map((data, index) => {
                      return (
                        <div className=" bg-white w-[400px] h-[200px] flex flex-row  items-center shadow-md rounded-md">
                          <div className=" mt-[25px] p-[20px]">
                            <h1 className=" text-[20px] text-gray-600 uppercase font-semibold">
                              {data?.package_name}
                            </h1>
                            <span className=" block text-[19px] text-gray-600 font-semibold relative -top-[12px] mt-[20px] ">
                              ${data?.price}
                            </span>
                            <div className=" flex flex-row gap-[20px]">
                              <button
                                onClick={() => handleDownload(data)}
                                className=" px-6 py-2  cursor-pointer bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md"
                              >
                                <i className="fa-solid fa-eye"></i> View invoice
                              </button>
                              {data_loading ? (
                                <>
                                  <button
                                    // onClick={() => ResendMail(data)}
                                    className=" px-6 py-2  cursor-pointer bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md"
                                  >
                                    <svg
                                      aria-hidden="true"
                                      class="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300"
                                      viewBox="0 0 100 101"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                        fill="currentColor"
                                      />
                                      <path
                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                        fill="currentFill"
                                      />
                                    </svg>
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                    onClick={() => ResendMail(data)}
                                    className=" px-6 py-2  cursor-pointer bg-gradient-to-r from-[#015e75] via-[#5aa7bc] to-[#39c6e9] text-white rounded-md"
                                  >
                                    <i class="fa-solid fa-envelope"></i> Resend
                                    mail
                                  </button>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        </>
      )}
      <FooterLoggedIn />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
    </>
  );
};

export default Billing;
